import React from "react";

type MyProps = {
  hasError: any;
  children: any;
  removeErr: any;
};

class ErrorBoundary extends React.Component<MyProps> {
  state = {
    errorMessage: "",
  };

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  componentDidCatch() {}

  render() {
    if (this.state.errorMessage) {
      return <p>An unexpected error occurred: {this.state.errorMessage}</p>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
