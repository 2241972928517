import { userContext } from "context/userContext";
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

export const Unprotected = () => <Outlet />;

export const Protected = () => {
  const { isAuthenticated } = useContext(userContext);

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};
