import collapseIconBlack from "assets/icons/admin/colapse-row-black.svg";
import collapseIcon from "assets/icons/admin/colapse-row.svg";
import expandIconBlack from "assets/icons/admin/expand-row-black.svg";
import expandIcon from "assets/icons/admin/expand-row.svg";
import Button from "components/ui/Button/Button";
import { Icon } from "components/ui/Icon/Icon";
import { OrderViewDropdown } from "components/views/protected/OrdersSection/OrderViewDropdown";
import { themeContext } from "context/themeContext";
import { toastContext } from "context/toastContext";
import { useFetchApi } from "hooks/useFetchApi";
import { PURCHASE_STATUS } from "types/order.types";
import {
  AddressDto,
  AdminUserController_updatePurchaseStatus,
} from "types/schemas";
import { z } from "zod";
import { useContext, useState } from "react";
import s from "./OrdersView.module.scss";

interface OrdersViewProps {
  id: string;
  address?: z.infer<typeof AddressDto>;
  totalPrice: number;
  status: PURCHASE_STATUS;
  userId: string;
  variantName?: string;
  imageUrl?: string;
  note?: string;
  toggleExpandedView: (isExpanded: boolean) => void;
  resetState: () => void;
  updateAdminNote?: (note: string | undefined) => void;
}

const blankImageSvg = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.0013 0.666016H2.0013C1.64768 0.666016 1.30854 0.806491 1.05849 1.05654C0.808444 1.30659 0.667969 1.64573 0.667969 1.99935V25.9993C0.667969 26.353 0.808444 26.6921 1.05849 26.9422C1.30854 27.1922 1.64768 27.3327 2.0013 27.3327H26.0013C26.3549 27.3327 26.6941 27.1922 26.9441 26.9422C27.1942 26.6921 27.3346 26.353 27.3346 25.9993V1.99935C27.3346 1.64573 27.1942 1.30659 26.9441 1.05654C26.6941 0.806491 26.3549 0.666016 26.0013 0.666016ZM24.668 3.33268V14.786L22.948 13.0527C22.824 12.9277 22.6765 12.8285 22.5141 12.7608C22.3516 12.6931 22.1773 12.6583 22.0013 12.6583C21.8253 12.6583 21.651 12.6931 21.4885 12.7608C21.3261 12.8285 21.1786 12.9277 21.0546 13.0527L16.8413 17.2793L11.0813 9.27935C10.9751 9.11094 10.8326 8.96843 10.6642 8.8623C10.4958 8.75617 10.3057 8.6891 10.108 8.66602C9.91522 8.65434 9.72214 8.68266 9.54087 8.74921C9.3596 8.81575 9.19405 8.91907 9.05464 9.05268L3.33464 14.786V3.33268H24.668ZM3.33464 24.666V18.546L9.82797 12.0527L15.588 20.0527C15.6997 20.2112 15.8447 20.3434 16.0128 20.4401C16.1809 20.5369 16.3681 20.5957 16.5613 20.6127H16.668C16.8434 20.6137 17.0174 20.5801 17.1798 20.5137C17.3423 20.4474 17.49 20.3496 17.6146 20.226L22.0013 15.8793L24.668 18.546V24.666H3.33464Z"
      fill="#F6F6F6"
    />
  </svg>
);

// Displays the products info inside the Admin products section
export function OrdersView({
  id,
  address,
  totalPrice,
  status,
  userId,
  variantName,
  imageUrl,
  note,
  toggleExpandedView,
  resetState,
  updateAdminNote,
}: OrdersViewProps) {
  const [showStatusDropdown, setShowStatusDropdown] = useState<boolean>(false);
  const { theme } = useContext(themeContext);
  const { setToastErrorMessage, setToastSuccessMessage } =
    useContext(toastContext);
  const { fetchFromApiWithSchemaValidation } = useFetchApi();
  const [noteToSet, setNote] = useState(note);
  const [isEdit, setIsEdit] = useState(false);
  function showStatusMenu() {
    setShowStatusDropdown(!showStatusDropdown);
  }

  return (
    <>
      <div className={s["offer-wrapper"]}>
        <div className={s["img-container"]}>
          <div className={s["img-main"]}>
            {!imageUrl ? blankImageSvg : <img src={imageUrl} />}
          </div>
        </div>
        <div className={s["info-container"]}>
          <div className={s["desc-div"]}>
            <p className={s.name}>Product Information:</p>
            <p className={s.desciption}>Variant Name: {variantName}</p>
            <p className={s.desciption}>Price: {totalPrice}</p>

            {address && (
              <>
                <p className={s.name}>Delivery Information:</p>
                <p className={s.desciption}>
                  User: {address.firstName} {address.lastName}
                </p>
                <p className={s.desciption}>Street: {address.street}</p>
                <p className={s.desciption}>
                  House Number: {address.houseNumber}
                </p>
                {address.addressAddition && (
                  <p className={s.desciption}>
                    Address Addition: {address.addressAddition}
                  </p>
                )}
                <p className={s.desciption}>Zip: {address.zip}</p>
                <p className={s.desciption}>City: {address.city}</p>
                <p className={s.desciption}>
                  Country code: {address.countryCode}
                </p>
                {address.phone && (
                  <p className={s.desciption}>Phone: {address.phone}</p>
                )}
              </>
            )}
          </div>
          <div className={s["nav-div"]}>
            <p onClick={showStatusMenu} className={s["status-label"]}>
              Change Status
              {showStatusDropdown ? (
                <img
                  src={theme === "dark" ? collapseIcon : collapseIconBlack}
                />
              ) : (
                <img src={theme === "dark" ? expandIcon : expandIconBlack} />
              )}
              {showStatusDropdown && (
                <OrderViewDropdown
                  className={s["status-dropdown"]}
                  handleSubmit={async (statusToSet) => {
                    try {
                      if (statusToSet === status)
                        return setToastErrorMessage(`Already ${status}`);

                      toggleExpandedView(false);

                      await fetchFromApiWithSchemaValidation(
                        AdminUserController_updatePurchaseStatus,
                        {
                          auth: true,
                          parameters: {
                            userId,
                            id,
                            status: statusToSet,
                            adminNote: note,
                          },
                        }
                      );

                      setShowStatusDropdown(false);
                      resetState?.();
                    } catch (e) {
                      setToastErrorMessage((e as Error).message);
                    }
                  }}
                />
              )}
            </p>
          </div>
        </div>

        <div className={s["note-input-wrapper"]}>
          {isEdit ? (
            <>
              <textarea
                title="Note"
                name="name"
                cols={50}
                rows={5}
                value={noteToSet}
                onChange={(e) => setNote(e.target.value)}
              />
              <Button
                onClick={async (e) => {
                  e.preventDefault();
                  try {
                    await fetchFromApiWithSchemaValidation(
                      AdminUserController_updatePurchaseStatus,
                      {
                        auth: true,
                        parameters: {
                          userId,
                          id,
                          status: status,
                          adminNote: noteToSet,
                        },
                      }
                    );
                    setIsEdit(false);
                    updateAdminNote?.(noteToSet);
                    setToastSuccessMessage(
                      "Successfuly updated the admin note"
                    );
                    toggleExpandedView(false);
                  } catch {
                    setToastErrorMessage("Cannot update admin note");
                  }
                }}>
                Save Note
              </Button>
              <Button onClick={() => setIsEdit(false)}>Cancel</Button>
            </>
          ) : (
            <div className={s["setted-note"]}>
              <p className={s["note"]}>
                <b>Note:</b> {note}
              </p>
              <div
                className={s["icon-wrapper"]}
                onClick={() => setIsEdit(true)}>
                <Icon name="edit" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
