import crossIcon from "assets/icons/cross-icon-black.svg";
import crossIconWhite from "assets/icons/cross-icon-white.svg";
import c from "classnames";
import * as Dialog from "components/ui/Dialog/Dialog";
import { themeContext } from "context/themeContext";
import React, { useContext } from "react";
import s from "./ModalWrapper.module.scss";

interface IProps {
  children: React.ReactNode;
  title?: string;
  className?: string;
  variant?: "flat" | "contrasted-body";
  closeBtn?: boolean;
}

function ModalWrapper({
  variant = "contrasted-body",
  title,
  children,
  className,
  closeBtn,
}: IProps) {
  const { theme } = useContext(themeContext);

  return (
    <Dialog.Portal>
      <Dialog.Content>
        <div className={s["popup-wrapper"]} data-theme={theme}>
          <div className={c(s["content-wrapper"], className)}>
            <div
              className={c(
                s.header,
                variant === "contrasted-body" && s["with-divider"]
              )}>
              {title && (
                <Dialog.Title className={s.title}>{title}</Dialog.Title>
              )}
              {closeBtn && (
                <Dialog.Close className={s["close-btn"]}>
                  <img src={theme === "dark" ? crossIconWhite : crossIcon} />
                </Dialog.Close>
              )}
            </div>
            <div
              className={c(
                s.body,
                variant === "contrasted-body" && s.contrasted
              )}>
              {children}
            </div>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}

export { ModalWrapper };
