import c from "classnames";
import Button from "components/ui/Button/Button";
import * as Dialog from "components/ui/Dialog/Dialog";
import { Icon } from "components/ui/Icon/Icon";
import { InputField } from "components/ui/Input/Input";
import VariantBox from "components/ui/VariantBox/VariantBox";
import { toastContext } from "context/toastContext";
import { useFetchApi } from "hooks/useFetchApi";
import { useFormData } from "hooks/useFormData";
import {
  useSWRFetchAPI,
  useSWRFetchAPIWithSchemaValidation,
} from "hooks/useSWRFetchAPI";
import { KeyedMutator } from "swr";
import { CDAResponse, CONTENTFUL_CONTENT_TYPES } from "types/contentful.types";
import {
  AdminProductConfig,
  MissionControlConfig,
  PRODUCT_TYPE,
  VariantsDefaultState,
  defaultState as variantsDefaultState,
} from "types/product.types";
import {
  AdminProductController_getProductByID,
  AdminProductController_update,
} from "types/schemas";
import { HttpError } from "utils/requests";
import React, { useContext, useEffect, useState } from "react";
import { FormWrapper } from "../FormWrapper/FormWrapper";
import { defaultState, ProductFormDataState } from "./AdminProductForm";
import s from "./AdminProductUpdateForm.module.scss";

interface IAdminProductProps {
  adminProductConfig: AdminProductConfig[];
  missionControl: MissionControlConfig[];
  keyedMutator: KeyedMutator<unknown>;
  id?: string;
}

export const updateFormFields = {
  name: true,
  startDate: true,
  endDate: true,
  type: true,
  images: true,
  is_promo: true,
  promo_image: true,
  tiers: true,
  slug: true,
  variants: true,
  subType: true,
};

export function AdminProductUpdateForm({
  adminProductConfig,
  missionControl,
  keyedMutator,
  id,
}: IAdminProductProps) {
  const {
    data: SWRdata,
    isLoading,
    mutate,
  } = useSWRFetchAPIWithSchemaValidation(
    AdminProductController_getProductByID,
    {
      parameters: {
        id,
      },
      auth: true,
    }
  );

  const { data: contentfulData } = useSWRFetchAPI<
    CDAResponse<
      unknown,
      {
        fields: unknown;
      },
      unknown
    >
  >(
    `${process.env.REACT_APP_CDA_URL}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}/entries?access_token=${process.env.REACT_APP_CONTENTFUL_TOKEN}&content_type=${CONTENTFUL_CONTENT_TYPES.product}&fields.slug=${SWRdata?.slug}&include=2&select=fields.productType`,
    {
      method: "GET",
    },
    undefined,
    false
  );

  const { formData, handleChange, setFormData, handleDateChange } =
    useFormData(defaultState);
  const { setToastErrorMessage, setToastSuccessMessage } =
    useContext(toastContext);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { fetching, fetchFromApiWithSchemaValidation } = useFetchApi();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<string>("");

  const [variants, setVariants] = useState<VariantsDefaultState[]>([]);
  useEffect(() => {
    if (!isInitialized && SWRdata) {
      // Not part of the Form
      for (const field in SWRdata) {
        if (!updateFormFields[field]) delete SWRdata[field];
      }

      // a check for old products, that have no tiers field
      if (!SWRdata.tiers) SWRdata["tiers"] = [];

      const copyData: Partial<any> = SWRdata;
      // Parse Data to display it
      // const start = formatDate(copyData["startDate"]);
      // copyData["startDate"] = start;

      // const end = formatDate(copyData["endDate"]);
      // copyData["endDate"] = end;

      setVariants(SWRdata.variants);
      // Delete it here, because it gets complicated if done like the rest
      delete copyData["is_promo"];

      setFormData(copyData as ProductFormDataState);
      setIsInitialized(true);
    }

    //if (checkForBlankFields()) return setIsDisabled(true);
    setIsDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, isInitialized, SWRdata]);
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const newData = {
      ...formData,
      slug: SWRdata.slug,
    };

    // Convert the data to int
    newData["rule"] = +newData["rule"];
    newData["special_price"] = +(newData["special_price"] ?? 0);
    newData["price"] = +(newData["price"] ?? 0);

    // prepare tiers field for fetching
    const arr = newData.tiers.map((sbt) => sbt.value);
    newData.tiers = arr;

    try {
      const response = await fetchFromApiWithSchemaValidation(
        AdminProductController_update,
        {
          parameters: {
            id,
            ...newData,
            variants: variants.map((variant) => ({
              ...variant,
              quantity:
                formData.type === PRODUCT_TYPE.ITEM
                  ? variant.quantity
                  : undefined,
            })),
            useExistingContentfulEntry: true,
            subType: (
              contentfulData?.includes?.Entry[0]?.fields as { key: string }
            )?.key,
          },
          auth: true,
        }
      );
      setToastSuccessMessage("Offer Edited");
      // Update data and update view
      mutate();
      setShowSuccess(response.id);
      setToastSuccessMessage("");
      keyedMutator();
    } catch (e: unknown) {
      if (e instanceof HttpError) {
        if (e.code === 500) {
          setToastErrorMessage(
            "Some of our systems seem to be unreachable, please try again later"
          );
        } else {
          setToastErrorMessage(e.message);
        }

        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setToastErrorMessage((e as any).message);
    }
  }

  if (showSuccess)
    return (
      <div className={s["success-screen"]}>
        <Icon name="success" className={s.check} inverted />
        <p className={s.label}>Success!</p>
        <p className={s.msg}>
          You have successfully updated a <br />
          Product
        </p>
        <Dialog.Close>
          <Button type="submit" className={s.btn}>
            Back to Dashboard
          </Button>
        </Dialog.Close>
      </div>
    );

  return (
    <>
      {SWRdata && isInitialized && (
        <FormWrapper onSubmit={handleSubmit} className={s.form}>
          <div className={s.scrollable}>
            <InputField
              title="Name"
              name="name"
              value={formData.name ?? ""}
              onChange={handleChange}
              className={s.input}
              showInfo="Displayed as the product title e.g. in product previews and details pages in the shop"
            />
            <InputField
              title="Product Subtype"
              name="subType"
              disabled
              value={
                (contentfulData?.includes?.Entry[0]?.fields as { name: string })
                  ?.name
              }
              onChange={handleChange}
              showInfo="The subtype of the product"
            />
            <InputField
              title="Product Type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              disabled
            />
            <div className={s["input-wrapper"]}>
              <InputField
                type="date"
                title="Start Date"
                required
                description="Determines the time from which the product becomes visible in the shop"
                name="startDate"
                value={new Date(formData.startDate)}
                onChange={handleDateChange}
                className={c(s.input, {
                  [s["input-error"]]: formData.startDate >= formData.endDate,
                })}
              />
              {formData.startDate &&
              formData.endDate &&
              formData.startDate >= formData.endDate ? (
                <p className={s["err-msg"]}>
                  End date has to be after the start date.
                </p>
              ) : (
                <></>
              )}
              <InputField
                type="date"
                title="End Date"
                required
                name="endDate"
                description="Determines the time at which the product offer is terminated"
                value={new Date(formData.endDate)}
                onChange={handleDateChange}
                className={c(s.input, {
                  [s["input-error"]]: formData.startDate >= formData.endDate,
                })}
              />
            </div>
            <div className={c(s["label-wrapper"], s["variants-label"])}>
              <span>Variants*</span>
              <p>
                Manage the different listings for this product incl. stock and
                price
              </p>
            </div>
            {variants?.map((variant, key) => {
              if (!variant.toDelete) {
                return (
                  <VariantBox
                    adminProductConfig={adminProductConfig}
                    productType={formData.type}
                    variant={variant}
                    isCreate={!variant.id}
                    handleSaveVariant={(setVariant: VariantsDefaultState) => {
                      variants[key] = setVariant;
                      setVariants([...variants]);
                    }}
                    handleRemoveVariant={() => {
                      if (variant.id) {
                        variants[key].toDelete = true;
                        setVariants([...variants]);
                      } else {
                        setVariants(
                          variants.filter((_, index) => index !== key)
                        );
                      }
                    }}
                    subType={
                      !variant.id
                        ? (
                            contentfulData?.includes?.Entry[0]?.fields as {
                              key: string;
                            }
                          )?.key
                        : undefined
                    }
                    missionControl={!variant.id ? missionControl : undefined}
                    key={key}
                  />
                );
              }
            })}
            <Button
              type="button"
              disabled={isDisabled}
              className={`${s.btn} `}
              onClick={() => setVariants([...variants, variantsDefaultState])}>
              <Icon name="add" style={{ height: "1em" }} />
              <span>Add Variant</span>
            </Button>
          </div>
          <Button
            type="submit"
            loading={fetching}
            disabled={
              fetching || isDisabled || formData.startDate >= formData.endDate
            }
            className={s.btn}>
            Update
          </Button>
        </FormWrapper>
      )}
      {(!SWRdata || isLoading) && <p>Loading</p>}
    </>
  );
}
