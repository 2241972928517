import { createContext, useEffect, useState } from "react";

interface IAppCtx {
  toastErrorMessage?: string;
  toastSuccessMessage?: string;
  setToastSuccessMessage: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setToastErrorMessage: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  removeErr: () => void;
  removeMsg: () => void;
}

const TOAST_DISPLAY_DURATION = 5000;

export const toastContext = createContext<IAppCtx>({} as IAppCtx);

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toastErrorMessage, setToastErrorMessage] = useState<string>();
  const [toastSuccessMessage, setToastSuccessMessage] = useState<string>();

  useEffect(() => {
    if (toastErrorMessage) {
      const timer = setTimeout(
        () => setToastErrorMessage(""),
        TOAST_DISPLAY_DURATION
      );
      return () => {
        clearTimeout(timer);
      };
    }

    if (toastSuccessMessage) {
      const timer = setTimeout(
        () => setToastSuccessMessage(""),
        TOAST_DISPLAY_DURATION
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [toastErrorMessage, toastSuccessMessage]);

  function removeErr() {
    setToastErrorMessage("");
  }
  function removeMsg() {
    setToastSuccessMessage("");
  }

  return (
    <toastContext.Provider
      value={{
        toastErrorMessage,
        setToastErrorMessage,
        toastSuccessMessage,
        setToastSuccessMessage,
        removeErr,
        removeMsg,
      }}>
      {children}
    </toastContext.Provider>
  );
}
