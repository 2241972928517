import { Tag } from "components/ui/Tag/Tag";
import { BENEFIT_STATUS } from "types/benefits.types";
import { CHALLENGE_STATUS } from "types/challenge.types";
import { PRODUCT_STATUS } from "types/product.types";
import s from "./ExpandedProductRow.module.scss";

interface IStatusFormProps {
  handleSubmit: (
    status: PRODUCT_STATUS | CHALLENGE_STATUS | BENEFIT_STATUS
  ) => Promise<void>;
}

export function StatusDropDown({ handleSubmit }: IStatusFormProps) {
  return (
    <div className={s["status-menu"]}>
      <Tag
        icon="active"
        variant="success"
        onClick={() => handleSubmit(PRODUCT_STATUS.ACTIVE)}>
        ACTIVE
      </Tag>
      <Tag
        icon="paused"
        variant="warning"
        onClick={() => handleSubmit(PRODUCT_STATUS.DRAFT)}>
        PAUSED
      </Tag>
    </div>
  );
}
