import { themeContext } from "context/themeContext";
import { toastContext } from "context/toastContext";
import { useContext } from "react";
import s from "./App.module.scss";
import ErrorBoundary from "./components/ErrorHandler";
import { Header } from "./components/navigation/Header";
import { ToastMessage } from "./components/ui/ToastMessage/ToastMessage";
import { useOAuthRedirectListener } from "./hooks/useOAuthListener";
import { Router } from "./routes/Routes";
import ".";

function App() {
  const { toastErrorMessage, removeErr, toastSuccessMessage } =
    useContext(toastContext);
  const { theme } = useContext(themeContext);
  useOAuthRedirectListener();

  return (
    <div className={s.AppWrapper}>
      <Header />
      <main className={s.AppMain} data-theme={theme}>
        <ErrorBoundary hasError={toastErrorMessage} removeErr={removeErr}>
          {toastSuccessMessage && (
            <ToastMessage msg={toastSuccessMessage} type="success" />
          )}
          {toastErrorMessage && (
            <ToastMessage msg={toastErrorMessage} type="error" />
          )}
          <Router />
        </ErrorBoundary>
      </main>
    </div>
  );
}

export default App;
