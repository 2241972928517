interface IParams {
  onload?: any;
  onloadstart?: any;
  onerror?: any;
}

export function readCSV(file: Blob, handlers?: IParams) {
  const reader = new FileReader();

  // Register handlers
  for (const handler in handlers) {
    reader[handler] = (event: ProgressEvent<FileReader>) => {
      handlers[handler](event);
    };
  }

  reader.readAsText(file);
}

export function csvToArray(
  str: string,
  delimiter = ",",
  opts?: { transform: { col: number } }
) {
  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  let arr: any;

  // If there is another row except the header
  if (str.indexOf("\n") !== -1) {
    arr = rows.map((row) => {
      const values: any[] = row.split(delimiter);
      opts && opts.transform.col !== null
        ? (values[opts.transform.col] = +values[opts.transform.col])
        : "";
      return values;
    });
  }

  // return the array
  return arr ?? [];
}

// Transform Image to base64 Array
export async function imagesToBase64(files: Blob[], onLoadHandler: any) {
  const readers: any = [];

  for (let i = 0; i < files.length; i++) {
    const reader = new FileReader();
    reader.onload = onLoadHandler;
    readers.push(reader.readAsDataURL(files[i]));
  }

  return Promise.all(readers ?? []);
}
