import { ColumnDef } from "@tanstack/react-table";
import collapseIconBlack from "assets/icons/admin/colapse-row-black.svg";
import collapseIcon from "assets/icons/admin/colapse-row.svg";
import expandIconBlack from "assets/icons/admin/expand-row-black.svg";
import expandIcon from "assets/icons/admin/expand-row.svg";
import { Tag } from "components/ui/Tag/Tag";
import { Theme } from "context/themeContext";
import { PURCHASE_STATUS } from "types/order.types";
import { PRODUCT_TYPE } from "types/product.types";
import { UserPurchaseDto } from "types/schemas";
import { formatDate } from "utils/formatDate";
import { z } from "zod";

export function OrdersCols(
  theme: Theme
): ColumnDef<z.infer<typeof UserPurchaseDto>>[] {
  return [
    {
      // We don't need the column(it's hidden) - this way we attach
      // the entryUrl field from fetched data to row
      header: () => <p>Order Number</p>,
      cell: ({ row }) => <p>{row.getValue("id")}</p>,
      id: "id",
      accessorKey: "id",
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>User Name ↑</div>;
          } else {
            return <div>User Name ↓</div>;
          }
        }
        return "User Name";
      },
      accessorKey: "name",
      cell: ({ row }) => {
        const value = row.getValue("name") as string;
        return value;
      },
    },

    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Email ↑</div>;
          } else {
            return <div>Email ↓</div>;
          }
        }
        return "Email";
      },
      accessorKey: "email",
      cell: ({ row }) => {
        const value = row.getValue("email") as string;
        return value;
      },
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Product Type ↑</div>;
          } else {
            return <div>Product Type ↓</div>;
          }
        }
        return "Product Type";
      },
      accessorKey: "productType",
      cell: ({ row }) => {
        const value = row.getValue("productType") as PRODUCT_TYPE;

        return value;
      },
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Price ↑</div>;
          } else {
            return <div>Price ↓</div>;
          }
        }
        return "Price";
      },
      accessorKey: "totalPrice",
      cell: ({ row }) => {
        const value = row.getValue("totalPrice") as string;
        return value;
      },
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Order Date ↑</div>;
          } else {
            return <div>Order Date ↓</div>;
          }
        }
        return "Order Date";
      },
      accessorKey: "creation",
      cell: ({ row }) => {
        const value = row.getValue("creation") as number;
        const date = new Date(value);

        return formatDate(date);
      },
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Status ↓</div>;
          } else {
            return <div>Status ↑</div>;
          }
        }
        return "Status";
      },
      id: "status",
      accessorKey: "status",
      cell: ({ row }) => {
        const value = row.getValue("status") as PURCHASE_STATUS;
        if (value === PURCHASE_STATUS.FULFILLED)
          return (
            <Tag icon="success" variant="success">
              FINALIZED
            </Tag>
          );
        if (value === PURCHASE_STATUS.INITIAL)
          return (
            <Tag icon="paused" variant="default">
              INITIAL
            </Tag>
          );
        if (value === PURCHASE_STATUS.IN_PROGRESS)
          return (
            <Tag icon="upcoming" variant="info">
              IN PROGRESS
            </Tag>
          );
        return null;
      },
      sortingFn: (dataA, dataB) => {
        const a = dataA.getValue("status") as PURCHASE_STATUS;
        const b = dataB.getValue("status") as PURCHASE_STATUS;

        const aRow = a === PURCHASE_STATUS.FULFILLED ? 1 : 0;
        const bRow = b === PURCHASE_STATUS.FULFILLED ? 1 : 0;
        return aRow > bRow ? -1 : 1;
      },
    },

    {
      header: () => null,
      id: "expander",
      cell: ({ row }) => (
        <span {...row.getToggleExpandedHandler()} style={{ cursor: "pointer" }}>
          {!row.getIsExpanded() ? (
            <img src={theme === "dark" ? expandIcon : expandIconBlack} />
          ) : (
            <img src={theme === "dark" ? collapseIcon : collapseIconBlack} />
          )}
        </span>
      ),
    },
    // Type, Customer Name, Mail, Status, Order Date (Creation Date), Price
  ];
}
