import c from "classnames";
import Button from "components/ui/Button/Button";
import * as Dialog from "components/ui/Dialog/Dialog";
import { Icon } from "components/ui/Icon/Icon";
import { InputField } from "components/ui/Input/Input";
import { SelectField } from "components/ui/SelectField/SelectField";
import { StepIndicator } from "components/ui/StepIndicator/StepIndicator";
import VariantBox from "components/ui/VariantBox/VariantBox";
import { WizardControls } from "components/ui/WizardControls/WizardControls";
import { themeContext } from "context/themeContext";
import { toastContext } from "context/toastContext";
import { useFetchApi } from "hooks/useFetchApi";
import { useFormData } from "hooks/useFormData";
import { useWizard } from "hooks/useWizard";
import { KeyedMutator } from "swr";
import {
  AdminProductConfig,
  AttributeTypeToLocalizedString,
  MissionControlConfig,
  PRODUCT_TYPE,
  VariantsDefaultState,
  defaultState as variantsDefaultState,
} from "types/product.types";
import { AdminProductController_create } from "types/schemas";
import { formatDate } from "utils/formatDate";
import { HttpError } from "utils/requests";
import React, { useContext, useMemo, useState } from "react";
import { FormWrapper } from "../FormWrapper/FormWrapper";
import s from "./AdminProductForm.module.scss";

interface IAdminProductProps {
  adminProductConfig: AdminProductConfig[];
  missionControl: MissionControlConfig[];
  mutate: KeyedMutator<any>;
}

export interface ProductFormDataState {
  name: string;
  startDate: number;
  endDate: number;
  type: PRODUCT_TYPE;
  subType?: string;
  code?: number | null;
  tiers: any[];
  slug?: string;
  useExistingContentfulEntry: boolean;
}

export const defaultState: ProductFormDataState = {
  name: "",
  startDate: 0,
  endDate: 0,
  slug: undefined,
  type: PRODUCT_TYPE.EVENT,
  code: null,
  subType: "PorscheEvent",
  tiers: [],
  useExistingContentfulEntry: false,
};

const stepLabels = ["General Information", "Variants", "Summary"];

export function AdminProductForm(props: IAdminProductProps) {
  const { formData, handleChange, handleDateChange, setFormData } =
    useFormData(defaultState);
  const { setToastErrorMessage, setToastSuccessMessage } =
    useContext(toastContext);
  const { fetching, fetchFromApiWithSchemaValidation } = useFetchApi();
  const { currentStep, previous, next } = useWizard(stepLabels);
  const [variants, setVariants] = useState<VariantsDefaultState[]>([]);
  const [showSuccess, setShowSuccess] = useState<string>("");
  const { theme } = useContext(themeContext);
  const [isVariantFormOpen, setIsVariantFormOpen] = useState(false);

  const isFormStateValid = useMemo(() => {
    if (currentStep.position === 1) {
      return !!(
        formData.name &&
        formData.name !== "" &&
        formData.type &&
        formData.startDate &&
        formData.endDate &&
        formData.startDate < formData.endDate
      );
    }
    if (currentStep.position === 2) {
      return !!(
        formData.startDate &&
        formData.endDate &&
        formData.startDate < formData.endDate
      );
    }
    return true;
  }, [formData, currentStep]);

  async function handleSubmit(
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) {
    event.preventDefault();

    const newData = {
      ...formData,
      slug: formData.slug !== "" ? formData.slug : undefined,
    };

    try {
      const response = await fetchFromApiWithSchemaValidation(
        AdminProductController_create,
        {
          parameters: {
            ...newData,
            variants: variants.map((variant) => ({
              ...variant,
              quantity:
                formData.type === PRODUCT_TYPE.ITEM
                  ? variant.quantity
                  : undefined,
            })),
            useExistingContentfulEntry: false,
            slug: undefined,
          },
          auth: true,
        }
      );

      setShowSuccess(response.id);
      setToastSuccessMessage("");
      props.mutate();
    } catch (e: unknown) {
      if (e instanceof HttpError) {
        if (e.code === 500) {
          setToastErrorMessage(
            "Some of our systems seem to be unreachable, please try again later"
          );
        } else {
          setToastErrorMessage(e.message);
        }

        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setToastErrorMessage((e as any).message);
    }
  }

  if (showSuccess)
    return (
      <div className={s["success-screen"]}>
        <Icon name="success" className={s.check} inverted />
        <p className={s.label}>Success!</p>
        <p className={s.msg}>You have successfuly created a product</p>
        <Dialog.Close asChild>
          <Button type="submit" className={s.btn}>
            Back to Dashboard
          </Button>
        </Dialog.Close>
      </div>
    );

  return (
    <>
      <StepIndicator
        className={s["step-indicator"]}
        steps={stepLabels}
        currentStep={currentStep.position - 1}
      />

      {/* STEP 2 */}
      {currentStep.position === 2 && (
        <FormWrapper className={s.form1}>
          {variants.map((variant, key) => (
            <VariantBox
              adminProductConfig={props.adminProductConfig}
              missionControl={props.missionControl}
              productType={formData.type}
              subType={formData.subType}
              variant={variant}
              handleSaveVariant={(setVariant: VariantsDefaultState) => {
                variants[key] = setVariant;
                setVariants([...variants]);
              }}
              handleRemoveVariant={() => {
                setVariants(variants.filter((_, index) => index !== key));
              }}
              setIsVariantFormOpened={setIsVariantFormOpen}
              key={key}
            />
          ))}
          {!isVariantFormOpen && (
            <>
              <Button
                type="button"
                loading={fetching}
                disabled={fetching || !isFormStateValid}
                className={s.btn}
                onClick={() =>
                  setVariants([...variants, variantsDefaultState])
                }>
                Add variant
              </Button>
            </>
          )}
        </FormWrapper>
      )}

      {/* STEP 3 */}
      {currentStep.position === 3 && (
        <div className={s["summary-container"]}>
          <div className={s["summary-form"]}>
            <div className={s["info-grid"]}>
              <div className={s["key-value-box-1"]}>
                <div className={s["key-value-wrapper"]}>
                  <p className={s.key}>Item Name:</p>
                  <p className={s.value}>{formData.name}</p>
                </div>
                <div className={s["key-value-wrapper"]}>
                  <p className={s.key}>Item Type:</p>
                  <p className={s.value}>{formData.type}</p>
                </div>
                <div className={s["key-value-wrapper"]}>
                  <p className={s.key}>Start-End Date:</p>
                  <p className={s.value}>
                    {formatDate(new Date(formData.startDate))} -{" "}
                    {formatDate(new Date(formData.endDate))}
                  </p>
                </div>
              </div>

              <div className={s["key-value-box-2"]}>
                {variants.map((variant, index) => (
                  <>
                    <div className={s["key-value-wrapper"]}>
                      <p className={s.key}>Variant {index + 1}</p>
                      <p className={s.value}>Name: {variant.name}</p>
                    </div>
                    <div className={s["key-value-wrapper"]}>
                      <p className={s.key} />

                      <p className={s.value}>
                        {`${
                          AttributeTypeToLocalizedString[
                            props.missionControl.find(
                              (product) =>
                                product.productTypeKey === formData.subType
                            )?.attribute
                              .attributeName as keyof typeof AttributeTypeToLocalizedString
                          ]
                        }: ${variant.attribute}`}
                      </p>
                    </div>
                    {formData.type === PRODUCT_TYPE.ITEM && (
                      <div className={s["key-value-wrapper"]}>
                        <p className={s.key} />

                        <p className={s.value}>Quantity: {variant.quantity}</p>
                      </div>
                    )}
                    <div className={s["key-value-wrapper"]}>
                      <p className={s.key} />

                      <p className={s.value}>Price: {variant.price}</p>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* STEP 1 */}
      {currentStep.position === 1 && (
        <FormWrapper className={s.form1}>
          <InputField
            title="Name"
            name="name"
            required
            value={formData.name ? formData.name : ""}
            onChange={handleChange}
            className={s.input}
            variant={theme === "dark" ? "light" : "dark"}
          />
          <SelectField
            title="Product Type"
            name="type"
            placeholder="Select a product type"
            value={`${formData.type}|${formData.subType}`}
            onChange={(value) => {
              const [type, subType] = value.split("|");
              setFormData({ ...formData, type: type as PRODUCT_TYPE, subType });
            }}
            groupOptions={props.adminProductConfig}
          />
          <div className={s["date-wrapper"]}>
            <InputField
              description="Time from which the product is displayed in the shop."
              type="date"
              title="Start Date"
              required
              name="startDate"
              value={formData.startDate ? new Date(formData.startDate) : ""}
              onChange={handleDateChange}
              className={c(s.input, {
                [s["input-error"]]:
                  formData.startDate &&
                  formData.endDate &&
                  formData.startDate >= formData.endDate,
              })}
            />
            {formData.startDate !== undefined &&
              formData.endDate !== undefined &&
              formData.startDate >= formData.endDate && (
                <p className={s["err-msg"]}>
                  End date has to be after the start date.
                </p>
              )}
            <InputField
              type="date"
              title="End Date"
              required
              name="endDate"
              value={formData.endDate ? new Date(formData.endDate) : ""}
              onChange={handleDateChange}
              className={c(s.input, {
                [s["input-error"]]:
                  formData.endDate && formData.endDate <= formData.startDate,
              })}
              description="Time at which the product is removed from the shop."
            />
          </div>
        </FormWrapper>
      )}
      <WizardControls
        className={s.footer}
        currentStepPosition={currentStep.position}
        onBack={previous}
        onNext={next}
        onSubmit={handleSubmit}
        isFormStateValid={isFormStateValid}
        isSubmitting={fetching}
        numberOfSteps={stepLabels.length}
        submitLabel="Create Product"
      />
    </>
  );
}
