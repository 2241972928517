import { useMemo, useReducer } from "react";

export interface StepState {
  position: number;
  label: string;
  isDone: boolean;
  isActive: boolean;
  error?: string;
}

type WizardState = StepState[];

type WizardAction =
  | { type: "NEXT_STEP" }
  | { type: "PREVIOUS_STEP" }
  | { type: "JUMP_TO"; payload: number }
  | { type: "SET_ERROR"; payload: string };

function wizardReducer(state: WizardState, action: WizardAction): WizardState {
  switch (action.type) {
    case "NEXT_STEP": {
      const activeIndex = state.findIndex((stepState) => stepState.isActive);
      return state.map((step, index) => {
        if (index === activeIndex) {
          return { ...step, isDone: true, isActive: false };
        }
        if (index === activeIndex + 1) {
          return { ...step, isActive: true };
        }
        return step;
      });
    }
    case "PREVIOUS_STEP": {
      const activeIndex = state.findIndex((stepState) => stepState.isActive);
      return state.map((step, index) => {
        if (index === activeIndex) {
          return { ...step, isDone: false, isActive: false };
        }
        if (index === activeIndex - 1) {
          return { ...step, isActive: true };
        }
        return step;
      });
    }
    case "JUMP_TO":
      return state.map((step, index) => {
        if (index + 1 === action.payload) {
          return { ...step, isDone: true, isActive: true };
        }
        return { ...step, isActive: false };
      });
    case "SET_ERROR": {
      const activeIndex = state.findIndex((stepState) => stepState.isActive);
      return state.map((step, index) => {
        if (index === activeIndex) {
          return { ...step, error: action.payload };
        } else {
          return step;
        }
      });
    }
    default:
      return state;
  }
}

type UseWizard = (labels: string[]) => {
  state: WizardState;
  currentStep: StepState;
  next: () => void;
  previous: () => void;
  jumpTo: (step: number) => void;
  setError: (error: string) => void;
};

export const useWizard: UseWizard = (labels) => {
  const defaultState: WizardState = labels.map((label, index) => ({
    position: index + 1,
    label,
    isDone: false,
    isActive: index === 0,
  }));
  const [state, dispatch] = useReducer(wizardReducer, defaultState);

  const currentStep = useMemo(
    () => state.find((step) => step.isActive) as StepState,
    [state]
  );

  const nextStep = () => dispatch({ type: "NEXT_STEP" });
  const previousStep = () => dispatch({ type: "PREVIOUS_STEP" });
  const jumpTo = (step: number) => dispatch({ type: "JUMP_TO", payload: step });
  const setError = (error: string) =>
    dispatch({ type: "SET_ERROR", payload: error });

  return {
    state,
    currentStep,
    next: nextStep,
    previous: previousStep,
    jumpTo,
    setError,
  };
};
