import Button from "components/ui/Button/Button";
import * as Dialog from "components/ui/Dialog/Dialog";
import { Icon } from "components/ui/Icon/Icon";
import { InputField } from "components/ui/Input/Input";
import { StepIndicator } from "components/ui/StepIndicator/StepIndicator";
import { WizardControls } from "components/ui/WizardControls/WizardControls";
import { themeContext } from "context/themeContext";
import { toastContext } from "context/toastContext";
import { useFetchApi } from "hooks/useFetchApi";
import { useFormData } from "hooks/useFormData";
import { useWizard } from "hooks/useWizard";
import { KeyedMutator } from "swr";
import { BENEFIT_STATUS } from "types/benefits.types";
import { AdminBenefitController_create } from "types/schemas";
import { HttpError } from "utils/requests";
import React, { useContext, useMemo, useState } from "react";
import { S_TO_MS } from "../../../constants";
import { FormWrapper } from "../FormWrapper/FormWrapper";
import s from "./BenefitForm.module.scss";

interface BenefitsFormProps {
  mutate: KeyedMutator<any>;
}

export interface BenefitFormDataState {
  name: string;
  useExistingContentfulEntry: boolean;
  contentfulRef: string;
  status: BENEFIT_STATUS;
  timeActive: string;
}

export const defaultState: BenefitFormDataState = {
  name: "",
  useExistingContentfulEntry: false,
  contentfulRef: "",
  status: BENEFIT_STATUS.DRAFT,
  timeActive: "1",
};

const stepLabels = ["General Information", "Summary"];

export function BenefitForm({ mutate }: BenefitsFormProps) {
  const { formData, handleChange } = useFormData(defaultState);
  const { setToastErrorMessage } = useContext(toastContext);
  const { currentStep, previous, next } = useWizard(stepLabels);
  const { fetching, fetchFromApiWithSchemaValidation } = useFetchApi();

  const isFormStateValid = useMemo(() => {
    if (currentStep.position === 1) {
      return !!(formData.name && formData.name !== "" && formData.timeActive);
    }

    return true;
  }, [formData, currentStep]);

  const [showSuccess, setShowSuccess] = useState<string>("");
  const { theme } = useContext(themeContext);

  async function handleSubmit(
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) {
    event.preventDefault();

    const newData = {
      ...formData,
      contentfulRef: undefined,
    };

    try {
      const response = await fetchFromApiWithSchemaValidation(
        AdminBenefitController_create,
        {
          parameters: {
            ...newData,
            timeActive: +newData.timeActive * S_TO_MS,
            useExistingContentfulEntry: false,
          },
          auth: true,
        }
      );

      setShowSuccess(response.id);
      mutate();
    } catch (e: unknown) {
      if (e instanceof HttpError) {
        if (e.code === 500) {
          setToastErrorMessage(
            "Some of our systems seem to be unreachable, please try again later"
          );
        } else {
          setToastErrorMessage(e.message);
        }

        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setToastErrorMessage((e as any).message);
    }
  }

  if (showSuccess)
    return (
      <div className={s["success-screen"]}>
        <Icon name="success" className={s.check} inverted />
        <p className={s.label}>Success!</p>
        <p className={s.msg}>The Benefit was successfully created</p>
        <Dialog.Close>
          <Button type="submit" className={s.btn}>
            Back to Dashboard
          </Button>
        </Dialog.Close>
      </div>
    );

  return (
    <>
      <StepIndicator
        className={s["step-indicator"]}
        steps={stepLabels}
        currentStep={currentStep.position - 1}
      />

      {/* STEP 2 */}
      {currentStep.position === 2 && (
        <div className={s["summary-container"]}>
          <div className={s["summary-form"]}>
            <div className={s["info-grid"]}>
              <div className={s["key-value-box-1"]}>
                <div className={s["key-value-wrapper"]}>
                  <p className={s.key}>Benefit Name:</p>
                  <p className={s.value}>{formData.name}</p>
                </div>
                <div className={s["key-value-wrapper"]}>
                  <p className={s.key}>ContentfulRef:</p>
                  <p className={s.value}>{formData.contentfulRef}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* STEP 1 */}
      {currentStep.position === 1 && (
        <FormWrapper className={s.form1}>
          <InputField
            title="Name"
            name="name"
            required
            value={formData.name ? formData.name : ""}
            onChange={handleChange}
            className={s.input}
            variant={theme === "dark" ? "light" : "dark"}
          />
          <InputField
            title="Time Active (in seconds)"
            name="timeActive"
            type="number"
            required
            value={formData.timeActive}
            onChange={handleChange}
            className={s.input}
            variant={theme === "dark" ? "light" : "dark"}
            description=" Enter the time in seconds for how long the benefit should be available for the user in the app after claiming it. "
          />
        </FormWrapper>
      )}
      <WizardControls
        className={s.footer}
        currentStepPosition={currentStep.position}
        submitLabel="Create Benefit"
        onBack={previous}
        onNext={next}
        onSubmit={handleSubmit}
        isFormStateValid={isFormStateValid}
        isSubmitting={fetching}
        numberOfSteps={stepLabels.length}
      />
    </>
  );
}
