import { ColumnDef } from "@tanstack/react-table";
import { BadgeDto } from "types/schemas";
import { z } from "zod";

export type BadgesTableData = Pick<
  z.infer<typeof BadgeDto>,
  "cid" | "name" | "description" | "tokenId"
>;

export function BadgesCols(): ColumnDef<z.infer<typeof BadgeDto>>[] {
  return [
    {
      header: () => "",
      cell: ({ row }) => {
        const cid = row.getValue("cid") as string;
        return <img src={`${process.env.REACT_APP_IPFS_GATEWAY}${cid}`} />;
      },
      accessorKey: "cid",
      id: "cid",
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Name ↑</div>;
          } else {
            return <div>Name ↓</div>;
          }
        }
        return "Name";
      },
      accessorKey: "name",
      cell: ({ row }) => {
        const value = row.getValue("name") as string;
        return (
          <p>
            {value.slice(0, 50)} {value.length > 50 ? "..." : ""}
          </p>
        );
      },
    },
    {
      // We don't need the column(it's hidden) - this way we attach
      // the id field from fetched data to row
      header: () => "Description",
      cell: ({ row }) => {
        const value = row.getValue("description") as string;
        return <p>{value}</p>;
      },
      id: "description",
      accessorKey: "description",
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Token Id ↑</div>;
          } else {
            return <div>Token Id ↓</div>;
          }
        }
        return "Token Id";
      },
      accessorKey: "tokenId",
      cell: ({ row }) => <p> {row.getValue("tokenId")}</p>,
    },
  ];
}
