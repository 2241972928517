export enum CHALLENGE_STATUS {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

export enum CHALLENGE_TYPE {
  LOCATION = "LOCATION",
  APP = "APP",
  CAR = "CAR",
  PURCHASE = "PURCHASE",
}

export enum RULE_TYPE {
  CAR_OWNERSHIP = "CAR_OWNERSHIP", // e.g. Own a Porsche Turbo model
  CAR_MIN_OWNERSHIP_COUNT = "CAR_MIN_OWNERSHIP_COUNT", // e.g. Own two Porsches
  CAR_OWNERSHIP_IN_LAST_X_YEARS = "CAR_OWNERSHIP_IN_LAST_X_YEARS", // e.g. Own X Porsches in the last 3 years
  CAR_PURCHASE_STATUS = "CAR_PURCHASE_STATUS", // e.g. new/used
  CAR_XTH_OWNERSHIP_BIRTHDAY = "CAR_XTH_OWNERSHIP_BIRTHDAY",
  ACCESSORY_PURCHASE = "ACCESSORY_PURCHASE",

  // not in scope
  // MILEAGE = "MILEAGE", // we dont get mileage data for now
  // COMMUNITY = "COMMUNITY", // e.g. Join Porsche Club, Join GT Circle // no data for communities for now
  // BOUGHT_FROM = "BOUGHT_FROM", // e.g. bought from dealer, bought from 3rd party // we don't get data from Porsches that weren't bough from a dealer for now
}

export const RULE_CONFIG: Record<
  RULE_TYPE,
  {
    type?: "string" | "number";
    specificStringValues?: string[];
    maxLength?: number;
  }
> = {
  [RULE_TYPE.CAR_OWNERSHIP]: {
    type: "string",
    maxLength: 100,
  },
  [RULE_TYPE.CAR_MIN_OWNERSHIP_COUNT]: {
    type: "number",
  },
  [RULE_TYPE.CAR_OWNERSHIP_IN_LAST_X_YEARS]: {
    type: "number",
  },
  [RULE_TYPE.CAR_PURCHASE_STATUS]: {
    specificStringValues: ["new", "used"],
  },
  [RULE_TYPE.CAR_XTH_OWNERSHIP_BIRTHDAY]: {
    type: "number",
  },
  [RULE_TYPE.ACCESSORY_PURCHASE]: {
    specificStringValues: [""],
  },
};
