import { ColumnDef } from "@tanstack/react-table";
import collapseIconBlack from "assets/icons/admin/colapse-row-black.svg";
import collapseIcon from "assets/icons/admin/colapse-row.svg";
import expandIconBlack from "assets/icons/admin/expand-row-black.svg";
import expandIcon from "assets/icons/admin/expand-row.svg";
import { Tag } from "components/ui/Tag/Tag";
import { Theme } from "context/themeContext";
import { BENEFIT_STATUS } from "types/benefits.types";
import { BenefitDto } from "types/schemas";
import { z } from "zod";
import { S_TO_MS } from "../../../constants";

export function BenefitsCols(
  theme: Theme
): ColumnDef<z.infer<typeof BenefitDto>>[] {
  return [
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Name ↑</div>;
          } else {
            return <div>Name ↓</div>;
          }
        }
        return "Name";
      },
      accessorKey: "name",
      cell: ({ row }) => {
        const value = row.getValue("name") as string;
        return `${value.slice(0, 50)} ${value.length > 50 ? "..." : ""}`;
      },
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>CMS State ↑</div>;
          } else {
            return <div>CMS State ↓</div>;
          }
        }
        return "CMS State";
      },
      accessorKey: "entryIsPublished",
      cell: ({ row }) => (
        <p>
          {(row.getValue("entryIsPublished") as boolean)
            ? "Published"
            : "Draft"}
        </p>
      ),
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Time Active ↑</div>;
          } else {
            return <div>Time Active ↓</div>;
          }
        }
        return "Time Active";
      },
      accessorKey: "timeActive",
      cell: ({ row }) => {
        const timeActive = row.getValue("timeActive") as string;
        return <p> {+timeActive / S_TO_MS}</p>;
      },
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Status ↓</div>;
          } else {
            return <div>Status ↑</div>;
          }
        }
        return "Status";
      },
      id: "status",
      accessorKey: "status",
      cell: ({ row }) => {
        const value = row.getValue("status") as BENEFIT_STATUS;
        if (value === BENEFIT_STATUS.ACTIVE)
          return (
            <Tag variant="success" icon="active">
              ACTIVE
            </Tag>
          );
        if (value === BENEFIT_STATUS.DRAFT)
          return (
            <Tag variant="warning" icon="paused">
              PAUSED
            </Tag>
          );
        return null;
      },
      sortingFn: (dataA, dataB) => {
        const a = dataA.getValue("status") as BENEFIT_STATUS;
        const b = dataB.getValue("status") as BENEFIT_STATUS;

        const aRow = a === BENEFIT_STATUS.ACTIVE ? 1 : 0;
        const bRow = b === BENEFIT_STATUS.ACTIVE ? 1 : 0;
        return aRow > bRow ? -1 : 1;
      },
    },
    {
      header: () => null,
      id: "expander",
      cell: ({ row }) => (
        <span {...row.getToggleExpandedHandler()} style={{ cursor: "pointer" }}>
          {!row.getIsExpanded() ? (
            <img src={theme === "dark" ? expandIcon : expandIconBlack} />
          ) : (
            <img src={theme === "dark" ? collapseIcon : collapseIconBlack} />
          )}
        </span>
      ),
    },

    {
      // We don't need the column(it's hidden) - this way we attach
      // the entryUrl field from fetched data to row
      header: () => null,
      cell: () => null,
      id: "entryUrl",
      accessorKey: "entryUrl",
    },
    {
      // We don't need the column(it's hidden) - this way we attach
      // the entryUrl field from fetched data to row
      header: () => null,
      cell: () => null,
      id: "id",
      accessorKey: "id",
    },
  ];
}
