import c from "classnames";
import { RulesForm } from "components/forms/RulesForm/RulesForm";
import Button from "components/ui/Button/Button";
import * as Dialog from "components/ui/Dialog/Dialog";
import { Icon } from "components/ui/Icon/Icon";
import { InputField } from "components/ui/Input/Input";
import { SelectField } from "components/ui/SelectField/SelectField";
import { StepIndicator } from "components/ui/StepIndicator/StepIndicator";
import { WizardControls } from "components/ui/WizardControls/WizardControls";
import { themeContext } from "context/themeContext";
import { toastContext } from "context/toastContext";
import { useFetchApi } from "hooks/useFetchApi";
import { useFormData } from "hooks/useFormData";
import { useWizard } from "hooks/useWizard";
import { KeyedMutator } from "swr";
import {
  CHALLENGE_STATUS,
  CHALLENGE_TYPE,
  RULE_CONFIG,
  RULE_TYPE,
} from "types/challenge.types";
import { AdminChallengeController_create } from "types/schemas";
import { formatDate } from "utils/formatDate";
import { isChallengeWithRules } from "utils/isChallengeWithRules";
import { HttpError } from "utils/requests";
import React, { ChangeEvent, useContext, useMemo, useState } from "react";
import { FormWrapper } from "../FormWrapper/FormWrapper";
import s from "./ChallengeForm.module.scss";

interface ChallengeFormProps {
  badgesData: {
    name: string;
    id: string;
  }[];
  mutate: KeyedMutator<any>;
}

export interface ChallengeFormDataState {
  name: string;
  useExistingContentfulEntry: boolean;
  slug: string;
  status: CHALLENGE_STATUS;
  typeOfChallenge: CHALLENGE_TYPE;
  badgeId: string;
  rules?: { [key in RULE_TYPE]: string | number };
  tokens?: number;
  startDate: number;
  endDate?: number;
}

export const defaultState: ChallengeFormDataState = {
  name: "",
  useExistingContentfulEntry: false,
  slug: "",
  status: CHALLENGE_STATUS.DRAFT,
  typeOfChallenge: CHALLENGE_TYPE.LOCATION,
  badgeId: "",
  rules: undefined,
  tokens: undefined,
  startDate: 0,
  endDate: undefined,
};

const stepLabels = ["General Information", "Rules", "Summary"];

export function ChallengeForm({ mutate, badgesData }: ChallengeFormProps) {
  const [rules, setRules] = useState<{ key: string; value: string }[]>([]);
  const { formData, handleChange, handleDateChange, setFormData } =
    useFormData(defaultState);
  const { setToastErrorMessage } = useContext(toastContext);
  const { fetching, fetchFromApiWithSchemaValidation } = useFetchApi();
  const { currentStep, previous, next } = useWizard(stepLabels);
  const [showSuccess, setShowSuccess] = useState<string>("");
  const { theme } = useContext(themeContext);

  const isFormStateValid = useMemo(() => {
    if (currentStep.position === 1) {
      let isDateCorrect;

      if (formData.endDate !== undefined) {
        isDateCorrect = formData.startDate < formData.endDate;
      } else {
        isDateCorrect = formData.startDate;
      }

      return !!(
        formData.name &&
        formData.typeOfChallenge &&
        formData.badgeId &&
        isDateCorrect
      );
    }
    if (currentStep.position === 2) {
      return (
        !(
          (rules.length === 0 && isChallengeWithRules(formData.typeOfChallenge)) // challenge with rules must have rules
        ) &&
        !(
          (
            rules.some((rule) => rule.key === RULE_TYPE.ACCESSORY_PURCHASE) &&
            rules.length > 1
          ) // accessory purchase rule type can not be combined with other rules
        ) &&
        !(new Set(rules.flatMap((r) => r.key)).size !== rules.length) && // rule types can only be included once per challenge
        !rules.some((rule) => {
          if (!rule.key) {
            return true;
          }
          if (RULE_CONFIG[rule.key]?.specificStringValues) {
            return !RULE_CONFIG[rule.key].specificStringValues.includes(
              rule.value
            );
          }
          if (
            RULE_CONFIG[rule.key]?.type === "string" &&
            RULE_CONFIG[rule.key]?.maxLength
          ) {
            return (
              rule.value.length <= 0 ||
              rule.value.length > RULE_CONFIG[rule.key].maxLength
            );
          }
          return rule.value === "";
        })
      );
    }
    return true;
  }, [formData, rules, currentStep]);

  async function handleSubmit(
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) {
    event.preventDefault();

    const newData = {
      ...formData,
      slug: formData.slug !== "" ? formData.slug : undefined,
    };

    let parsedRules;
    if (rules?.length > 0 && isChallengeWithRules(formData.typeOfChallenge)) {
      parsedRules = rules.reduce((obj, item) => {
        if (item.key)
          obj[item.key] =
            RULE_CONFIG[item.key]?.type === "number" ? +item.value : item.value;
        return obj;
      }, {});
    }

    try {
      const response = await fetchFromApiWithSchemaValidation(
        AdminChallengeController_create,
        {
          parameters: {
            ...newData,
            tokens: newData.tokens ? +newData.tokens : undefined,
            rules: parsedRules,
            useExistingContentfulEntry: false,
            slug: undefined,
          },
          auth: true,
        }
      );

      setShowSuccess(response.id);
      mutate();
    } catch (e: unknown) {
      if (e instanceof HttpError) {
        if (e.code === 500) {
          setToastErrorMessage(
            "Some of our systems seem to be unreachable, please try again later"
          );
        } else {
          setToastErrorMessage(e.message);
        }

        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setToastErrorMessage((e as any).message);
    }
  }

  if (showSuccess)
    return (
      <>
        <div className={s["success-screen"]}>
          <Icon name="success" className={s.check} inverted />
          <p className={s.label}>Success!</p>
          <p className={s.msg}>You have successfuly created a Challenge</p>
          <Dialog.Close>
            <Button type="submit" className={s["btn"]}>
              Back to Dashboard
            </Button>
          </Dialog.Close>
        </div>
      </>
    );

  return (
    <>
      <StepIndicator
        className={s["step-indicator"]}
        steps={stepLabels}
        currentStep={currentStep.position - 1}
      />
      {/* STEP 2 */}
      {currentStep.position === 2 && (
        <FormWrapper className={s.form1}>
          {isChallengeWithRules(formData.typeOfChallenge) ? (
            <RulesForm
              options={[
                { label: "Car type/model", value: RULE_TYPE.CAR_OWNERSHIP },
                {
                  label: "Amount of cars",
                  value: RULE_TYPE.CAR_MIN_OWNERSHIP_COUNT,
                },
                {
                  label: "Ownership in last X years",
                  value: RULE_TYPE.CAR_OWNERSHIP_IN_LAST_X_YEARS,
                },
                {
                  label: "Car purchase status (used/new)",
                  value: RULE_TYPE.CAR_PURCHASE_STATUS,
                },
                {
                  label: "Car ownership birthday",
                  value: RULE_TYPE.CAR_XTH_OWNERSHIP_BIRTHDAY,
                },
                {
                  label: "Accessory purchase",
                  value: RULE_TYPE.ACCESSORY_PURCHASE,
                },
              ]}
              rules={rules}
              setRules={setRules}
            />
          ) : (
            <p>No rules for this type of challenge</p>
          )}
        </FormWrapper>
      )}

      {/* STEP 3 */}
      {currentStep.position === 3 && (
        <div className={s["summary-container"]}>
          <div className={s["summary-form"]}>
            <div className={s["info-grid"]}>
              <div className={s["key-value-box-1"]}>
                <div className={s["key-value-wrapper"]}>
                  <p className={s.key}>Challenge Name:</p>
                  <p className={s.value}>{formData.name}</p>
                </div>

                <div className={s["key-value-wrapper"]}>
                  <p className={s.key}>Challenge Type:</p>
                  <p className={s.value}>{formData.typeOfChallenge}</p>
                </div>
                <div className={s["key-value-wrapper"]}>
                  <p className={s.key}>Start-End Date:</p>
                  <p className={s.value}>
                    {formatDate(new Date(formData.startDate))} -{" "}
                    {formData.endDate
                      ? formatDate(new Date(formData.endDate))
                      : ""}
                  </p>
                </div>
                <div className={s["key-value-wrapper"]}>
                  <p className={s.key}>(Earned) Tokens: </p>
                  <p className={s.value}>
                    {formData.tokens ? `${formData.tokens} PT` : ""}
                  </p>
                </div>
              </div>
              <div className={s["key-value-box-2"]}>
                {rules.map((rule, index) => (
                  <>
                    <div className={s["key-value-wrapper"]}>
                      <p className={s.key}>Rule {index + 1}</p>
                      <p className={s.value}>Name: {rule.key}</p>
                    </div>
                    <div className={s["key-value-wrapper"]}>
                      <p className={s.key} />

                      <p className={s.value}>Value: {rule.value}</p>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* STEP 1 */}
      {currentStep.position === 1 && (
        <FormWrapper className={s.form1}>
          <InputField
            title="Challenge Name"
            name="name"
            value={formData.name ? formData.name : ""}
            onChange={handleChange}
            className={s.input}
            variant={theme === "dark" ? "light" : "dark"}
            required
          />
          <SelectField
            title="Challenge Type"
            description="Select the type of the challenge"
            name="typeOfChallenge"
            required
            value={formData.typeOfChallenge ? formData.typeOfChallenge : ""}
            onChange={(value) =>
              handleChange({
                target: { value, name: "typeOfChallenge" },
              } as React.ChangeEvent<HTMLSelectElement>)
            }
            placeholder="Select a challenge type..."
            options={[
              { label: "Location", value: CHALLENGE_TYPE.LOCATION },
              { label: "App", value: CHALLENGE_TYPE.APP },
              { label: "Car", value: CHALLENGE_TYPE.CAR },
              { label: "Purchase", value: CHALLENGE_TYPE.PURCHASE },
            ]}
          />
          <InputField
            type="date"
            title="Start Date"
            description="Time from which the challenge is available in the app."
            name="startDate"
            required
            value={formData.startDate ? new Date(formData.startDate) : ""}
            onChange={handleDateChange}
            className={c(s.input, {
              [s["input-error"]]:
                formData.startDate &&
                formData.endDate &&
                formData.startDate >= formData.endDate,
            })}
          />
          {formData.startDate &&
          formData.endDate &&
          formData.startDate >= formData.endDate ? (
            <p className={s["err-msg"]}>
              End date has to be after the start date.
            </p>
          ) : (
            <></>
          )}
          <InputField
            type="date"
            title="End Date"
            name="endDate"
            description="Time at which the challenge is no longer available in the app."
            withClear
            value={formData.endDate ? new Date(formData.endDate) : ""}
            onChange={(
              e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              if (e.target.value) {
                handleDateChange(e);
              } else {
                setFormData({ ...formData, endDate: undefined });
              }
            }}
            className={c(s.input, {
              [s["input-error"]]:
                formData.startDate &&
                formData.endDate &&
                formData.startDate >= formData.endDate,
            })}
            showInfo="This is a tooltip"
          />
          <SelectField
            title="Badge"
            placeholder="Select a badge..."
            description="Select the badge users can earn by completing this challenge."
            name="badgeId"
            required
            value={formData.badgeId ? formData.badgeId : ""}
            onChange={(value) =>
              handleChange({
                target: { value, name: "badgeId" },
              } as React.ChangeEvent<HTMLSelectElement>)
            }
            options={badgesData.map((badge) => ({
              label: badge.name,
              value: badge.id,
            }))}
          />
          <InputField
            title="Porsche Token Reward"
            type="number"
            name="tokens"
            description="If desired, enter the amount of Porsche Tokens that users will receive as a reward for the challenge."
            icon="porscheToken"
            value={formData.tokens ? formData.tokens : ""}
            onChange={handleChange}
            className={s.input}
          />
        </FormWrapper>
      )}
      <WizardControls
        className={s.footer}
        submitLabel="Create Challenge"
        currentStepPosition={currentStep.position}
        onBack={previous}
        onNext={next}
        onSubmit={handleSubmit}
        isFormStateValid={isFormStateValid}
        isSubmitting={fetching}
        numberOfSteps={stepLabels.length}
      />
    </>
  );
}
