import useLocalStorage from "use-local-storage";
import { createContext, useEffect } from "react";

export type Theme = "dark" | "light";
interface IThemeCtx {
  theme?: Theme;
  switchTheme?: (newTheme?: Theme) => void;
}

export const themeContext = createContext<IThemeCtx>({} as IThemeCtx);

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useLocalStorage<Theme>("theme", "light");
  const switchTheme = (newTheme?: Theme) => {
    document.documentElement.dataset.theme =
      newTheme ?? theme === "dark" ? "light" : "dark";
    setTheme(newTheme ?? theme === "dark" ? "light" : "dark");
  };

  useEffect(() => {
    document.documentElement.dataset.theme = theme;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <themeContext.Provider value={{ theme, switchTheme }}>
      {children}
    </themeContext.Provider>
  );
}
