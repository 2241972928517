export const CONTENTFUL_CONTENT_TYPES = {
  product: "product",
  productVariant: "productVariant",
  challenge: "challenge",
  benefit: "benefit",
  productVariantAttribute: "productVariantAttribute",
  shopConfig: "shopConfig",
  productType: "productType",
};

export type CDAResponse<T = unknown, E = unknown, A = unknown> = {
  includes?: {
    Entry: E[];
    Asset: A[];
  };
  items: {
    fields: T;
  }[];
};

export type ContentfulProductFields = {
  slug: string;
  description: string;
  name: string;
  variants: {
    sys: {
      id: string;
    };
  }[];
};

export type ContentfulProductEntry = {
  sys: {
    id: string;
  };
  fields: {
    images: {
      sys: {
        id: string;
      };
    }[];
    dbRef: string;
  };
};

export type ContentfulProductAsset = {
  sys: {
    id: string;
  };
  fields: {
    file: {
      url: string;
    };
  };
};

export type ContentfulProductDataResponse = CDAResponse<
  ContentfulProductFields,
  ContentfulProductEntry,
  ContentfulProductAsset
>;
