import arrowBlack from "assets/icons/admin/colapse-row-black.svg";
import arrow from "assets/icons/admin/colapse-row.svg";
import { themeContext } from "context/themeContext";
import { PURCHASE_STATUS } from "types/order.types";
import { useContext, useState } from "react";
import s from "./FilterDropdown.module.scss";

interface IFilterDropdown {
  changeFilter: (id: PURCHASE_STATUS) => void;
  options: { id: PURCHASE_STATUS; name: string }[];
  currentFilterNames?: string;
}

const FilterDropdown = ({
  changeFilter,
  options,
  currentFilterNames,
}: IFilterDropdown) => {
  const { theme } = useContext(themeContext);

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const sortingHandler = (id: PURCHASE_STATUS) => {
    changeFilter(id);
    setShowDropdown(false);
  };

  return (
    <div className={s.sort}>
      <div
        className={s["current-filter"]}
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}>
        {currentFilterNames === "" ? "None" : currentFilterNames}
        <img
          src={theme === "dark" ? arrow : arrowBlack}
          width={12}
          height={12}
          style={{
            transform: showDropdown ? "rotate(0deg)" : "rotate(180deg)",
          }}
        />
      </div>
      {showDropdown && (
        <div className={s.dropdown}>
          {options.map((option, i) => (
            <div
              key={i}
              className={s.option}
              onClick={() => sortingHandler(option.id)}>
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
