import calendarIcon from "assets/icons/calendar-icon.svg";
import eyeIcon from "assets/icons/eye-icon.svg";
import infoIcon from "assets/icons/info.svg";
import searchIcon from "assets/icons/search-icon.svg";
import { themeContext } from "context/themeContext";
import { useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import c from "classnames";
import { Icon, IconName } from "components/ui/Icon/Icon";
import ReactTooltip from "react-tooltip";
import s from "./Input.module.scss";

interface InputProps {
  label?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: any;
  type?:
    | "name"
    | "text"
    | "number"
    | "password"
    | "email"
    | "file"
    | "date"
    | "select"
    | "checkbox"
    | "search";
  value?: any;
  required?: boolean;
  placeholder?: string;
  accept?: string;
  name?: string;
  description?: string;
  errorState?: boolean;
  errorMessage?: string;
  ref?: any;
  onViewPwd?: any;
  className?: string;
  showInfo?: string;
  variant?: string;
  disabled?: boolean;
  title?: string;
  icon?: IconName;
  // Admin
  multiple?: boolean;
  withClear?: boolean;
}

const CustomDateInput = (props: {
  value?: any;
  onClick?: any;
  className?: string;
}) => (
  <div className={s["date-input-wrapper"]}>
    <input
      className={c(s["date-input"], props.className)}
      onClick={props.onClick}
      value={props.value}
    />
    <img
      src={calendarIcon}
      className={s["calendar-icon"]}
      onClick={props.onClick}
    />
  </div>
);

export const InputField = ({
  type,
  variant = "light",
  disabled = false,
  onChange,
  onClick,
  required,
  title,
  onViewPwd,
  className,
  value,
  icon,
  label,
  description,
  errorMessage,
  errorState,
  withClear,
  multiple,
  ...props
}: InputProps) => {
  const { theme } = useContext(themeContext);
  // Admin
  const [startDate, setStartDate] = useState<Date>();
  const [wasSet, setWasSet] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const clearFileInput: React.MouseEventHandler<HTMLInputElement> = (e) => {
    (e.target as HTMLInputElement).value = "";
  };

  useEffect(() => {
    if (type && type === "date" && value && !wasSet) {
      setStartDate(new Date(value));
      setWasSet(true);
    }
  }, [value]);

  useEffect(() => {
    if (type === "date") {
      onChange({
        target: inputRef.current,
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [startDate]);

  useEffect(() => {
    if (type === "number" && inputRef.current) {
      const handleWheel = (event) => {
        event.preventDefault();
      };
      const input = inputRef.current;

      input.addEventListener("wheel", handleWheel, {
        passive: false,
      });

      return () => {
        input.removeEventListener("wheel", handleWheel);
      };
    }
  });

  if (type && type === "date") {
    return (
      <div className={s["date-wrapper"]}>
        <div className={s["label-wrapper"]}>
          <label>
            {title}
            {required && "*"}
          </label>{" "}
          {props.showInfo && (
            <>
              <div className={s["tooltip-text"]} data-tip={`${props.showInfo}`}>
                <img src={infoIcon} />
              </div>
              <ReactTooltip
                className={s.tooltip}
                arrowColor={theme === "dark" ? "white" : "black"}
                place="right"
              />
            </>
          )}
        </div>
        {description && <p className={s.description}>{description}</p>}
        {/* The input that get's displayed */}
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
          }}
          customInput={
            <CustomDateInput
              className={className}
              value={value}
              onClick={onClick}
            />
          }
          dateFormat="d MMM yyyy HH:mm"
          isClearable={withClear}
          wrapperClassName={s.datePicker}
          showYearDropdown
          yearDropdownItemNumber={15}
          scrollableYearDropdown
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15} // Set the time intervals for minutes
          timeCaption="Time"
        />
        {/* The actual input that get's tracked and it's hidden */}
        <input
          className={s.hidden}
          name={props.name}
          value={startDate?.toString() ?? ""}
          ref={inputRef}
        />
      </div>
    );
  }

  if (type === "file") {
    return (
      <div className={s["input-with-label"]}>
        {title && (
          <div className={s["label-wrapper"]}>
            <label>
              {title}
              {required && "*"}
            </label>
            {props.showInfo && (
              <>
                <div
                  className={s["tooltip-text"]}
                  data-tip={`${props.showInfo}`}>
                  <img src={infoIcon} />
                </div>
                <ReactTooltip
                  className={s.tooltip}
                  arrowColor={theme === "dark" ? "white" : "black"}
                  place="right"
                />
              </>
            )}
          </div>
        )}
        {description && <p className={s.description}>{description}</p>}
        <div
          className={c(s["input-wrapper"], {
            [s.dark]: theme === "dark",
            [s.disabled]: disabled,
          })}>
          <input
            className={c(s.input, {
              [s.dark]: theme === "dark",
            })}
            type={type || "text"}
            onChange={onChange}
            {...props}
            placeholder={label}
            multiple={multiple !== undefined ? multiple : true}
            onClick={clearFileInput}
          />
        </div>
      </div>
    );
  }

  if (type === "checkbox") {
    return (
      <div className={c(s["input-with-label"], s["checkbox-wrapper"])}>
        <input
          type="checkbox"
          className={c(s.input, s.checkbox, {
            [s.dark]: theme === "dark",
          })}
          onChange={onChange}
          {...props}
          checked={value}
          placeholder={label}
        />
        {title && (
          <>
            <div className={s["label-wrapper"]}>
              <label>
                {title}
                {required && "*"}
              </label>
              {props.showInfo && (
                <>
                  <div
                    className={s["tooltip-text"]}
                    data-tip={`${props.showInfo}`}>
                    <img src={infoIcon} />
                  </div>
                  <ReactTooltip
                    className={s.tooltip}
                    arrowColor={theme === "dark" ? "white" : "black"}
                    place="right"
                  />
                </>
              )}
            </div>
            {description && <p className={s.description}>{description}</p>}
          </>
        )}
      </div>
    );
  }

  return (
    <div className={s["input-with-label"]}>
      {title && (
        <div className={s["label-wrapper"]}>
          <label>
            {title}
            {required && "*"}
          </label>
          {props.showInfo && (
            <>
              <div className={s["tooltip-text"]} data-tip={`${props.showInfo}`}>
                <img src={infoIcon} />
              </div>
              <ReactTooltip
                className={s.tooltip}
                arrowColor={theme === "dark" ? "white" : "black"}
                place="right"
              />
            </>
          )}
        </div>
      )}
      {description && <p className={s.description}>{description}</p>}
      <div
        className={c(s["input-wrapper"], {
          [s.dark]: theme === "dark",
          [s.disabled]: disabled,
        })}>
        {type === "search" && (
          <img className={s["user-icon"]} src={searchIcon} />
        )}
        {type !== "search" && icon && <Icon name={icon} className={s.icon} />}
        <input
          className={c(s.input, {
            [s.dark]: theme === "dark",
            [s["error-state"]]: errorState,
          })}
          type={type || "text"}
          onChange={onChange}
          value={value}
          ref={type === "number" ? inputRef : undefined}
          disabled={disabled}
          {...props}
          placeholder={label}
          multiple={multiple !== undefined ? multiple : true}
        />
        {(type === "password" || type === "text") && (
          <img className={s["eye-icon"]} src={eyeIcon} onClick={onViewPwd} />
        )}
      </div>
      {errorState && <p className={s.error}>{errorMessage}</p>}
    </div>
  );
};
