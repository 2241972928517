import { ColumnDef } from "@tanstack/react-table";
import collapseIconBlack from "assets/icons/admin/colapse-row-black.svg";
import collapseIcon from "assets/icons/admin/colapse-row.svg";
import expandIconBlack from "assets/icons/admin/expand-row-black.svg";
import expandIcon from "assets/icons/admin/expand-row.svg";
import { Tag } from "components/ui/Tag/Tag";
import { Theme } from "context/themeContext";
import { CHALLENGE_STATUS } from "types/challenge.types";
import { ChallengeDto } from "types/schemas";
import { formatDate } from "utils/formatDate";
import { z } from "zod";

export function ChallengesCols(
  theme: Theme
): ColumnDef<z.infer<typeof ChallengeDto>>[] {
  return [
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Name ↑</div>;
          } else {
            return <div>Name ↓</div>;
          }
        }
        return "Name";
      },
      accessorKey: "name",
      cell: ({ row }) => {
        const value = row.getValue("name") as string;
        return `${value.slice(0, 50)} ${value.length > 50 ? "..." : ""}`;
      },
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Slug ↑</div>;
          } else {
            return <div>Slug ↓</div>;
          }
        }
        return "Slug";
      },
      accessorKey: "slug",
      cell: ({ row }) => {
        const value = row.getValue("slug") as string;
        return `${value.slice(0, 50)} ${value.length > 50 ? "..." : ""}`;
      },
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>CMS State ↑</div>;
          } else {
            return <div>CMS State ↓</div>;
          }
        }
        return "CMS State";
      },
      accessorKey: "entryIsPublished",
      cell: ({ row }) => (
        <p> {row.getValue("entryIsPublished") ? "Published" : "Draft"}</p>
      ),
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Type ↑</div>;
          } else {
            return <div>Type ↓</div>;
          }
        }
        return "Type";
      },
      accessorKey: "typeOfChallenge",
      cell: ({ row }) => <p>{row.getValue("typeOfChallenge")}</p>,
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Token Id ↑</div>;
          } else {
            return <div>Token Id ↓</div>;
          }
        }
        return "Token Id";
      },
      accessorKey: "badgeId",
      cell: ({ row }) => (
        <p>{(row.getValue("badgeId") as string).split("-")[1]}</p>
      ),
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Tokens ↑</div>;
          } else {
            return <div>Tokens ↓</div>;
          }
        }
        return "Tokens";
      },
      accessorKey: "tokens",
      cell: ({ row }) => <p>{row.getValue("tokens")}</p>,
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Start ↓</div>;
          } else {
            return <div>Start ↑</div>;
          }
        }
        return "Start";
      },
      accessorKey: "startDate",
      cell: ({ row }) => {
        const value = row.getValue("startDate") as number;
        const date = new Date(value);

        return <p>{formatDate(date)}</p>;
      },
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>End ↓</div>;
          } else {
            return <div>End ↑</div>;
          }
        }
        return "End";
      },
      accessorKey: "endDate",
      cell: ({ row }) => {
        const value = row.getValue("endDate") as number | undefined;
        if (!value) {
          return;
        }

        const date = new Date(value);

        return <p>{formatDate(date)}</p>;
      },
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Status ↓</div>;
          } else {
            return <div>Status ↑</div>;
          }
        }
        return "Status";
      },
      id: "status",
      accessorKey: "status",
      cell: ({ row }) => {
        const value = row.getValue("status") as CHALLENGE_STATUS;
        const startDate = row.getValue("startDate") as number;
        const endDate = row.getValue("endDate") as number | undefined;
        const dateNow = Date.now();

        if (value === CHALLENGE_STATUS.ACTIVE)
          return (
            <div style={{ display: "flex", gap: "1rem" }}>
              {endDate && endDate < dateNow && (
                <Tag variant="danger" icon="expired">
                  Expired
                </Tag>
              )}
              {dateNow < startDate && (
                <Tag variant="info" icon="upcoming">
                  Upcoming
                </Tag>
              )}
              <Tag variant="success" icon="active">
                Active
              </Tag>
            </div>
          );
        if (value === CHALLENGE_STATUS.DRAFT)
          return (
            <div style={{ display: "flex", gap: "1rem" }}>
              {endDate && endDate < dateNow && (
                <Tag variant="danger" icon="expired">
                  Expired
                </Tag>
              )}
              {dateNow < startDate && (
                <Tag variant="info" icon="upcoming">
                  Upcoming
                </Tag>
              )}
              <Tag variant="warning" icon="paused">
                Paused
              </Tag>
            </div>
          );
        return null;
      },
      sortingFn: (dataA, dataB) => {
        const a = dataA.getValue("status") as CHALLENGE_STATUS;
        const b = dataB.getValue("status") as CHALLENGE_STATUS;

        const aRow = a === CHALLENGE_STATUS.ACTIVE ? 1 : 0;
        const bRow = b === CHALLENGE_STATUS.ACTIVE ? 1 : 0;
        return aRow > bRow ? -1 : 1;
      },
    },
    {
      header: () => null,
      id: "expander",
      cell: ({ row }) => (
        <span {...row.getToggleExpandedHandler()} style={{ cursor: "pointer" }}>
          {!row.getIsExpanded() ? (
            <img src={theme === "dark" ? expandIcon : expandIconBlack} />
          ) : (
            <img src={theme === "dark" ? collapseIcon : collapseIconBlack} />
          )}
        </span>
      ),
    },
    {
      // We don't need the column(it's hidden) - this way we attach
      // the entryUrl field from fetched data to row
      header: () => null,
      cell: () => null,
      id: "entryUrl",
      accessorKey: "entryUrl",
    },
    {
      // We don't need the column(it's hidden) - this way we attach
      // the entryUrl field from fetched data to row
      header: () => null,
      cell: () => null,
      id: "id",
      accessorKey: "id",
    },
  ];
}
