import c from "classnames";
import { Icon, IconName } from "components/ui/Icon/Icon";
import s from "./Tag.module.scss";

interface ITagProps {
  children: string | number;
  className?: string;
  variant?: "default" | "info" | "warning" | "success" | "danger";
  icon?: IconName;
  upperCase?: boolean;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

export function Tag({
  children,
  className,
  upperCase = true,
  variant = "default",
  icon,
  onClick,
}: ITagProps) {
  return (
    <span
      onClick={onClick}
      className={c(s.tag, className)}
      data-variant={variant}>
      {icon && <Icon name={icon} inverted />}
      {upperCase ? children.toString().toUpperCase() : children}
    </span>
  );
}
