import { useState } from "react";

interface useFormDataResponse<T> {
  formData: T;
  handleChange: React.ChangeEventHandler<HTMLElement>;
  handleTierChange: any;
  handleBoolChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCheckbox: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  checkForBlankFields: Function;
  setFormData: React.Dispatch<React.SetStateAction<T>>;
  handleDateChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export function useFormData<T>(defaultState: T): useFormDataResponse<T> {
  const [formData, setFormData] = useState<T>(defaultState);

  // Updates data based on fields name
  function handleChange(
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) {
    const value = e.target.value;
    const field = e.target.name;
    setFormData({ ...formData, [field]: value });
  }

  function handleDateChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const date = new Date(e.target.value);
    const value = date.getTime();
    const field = e.target.name;
    setFormData({ ...formData, [field]: value });
  }

  function handleBoolChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    let value;
    e.target.value === "false" ? (value = false) : (value = true);
    const field = e.target.name;
    setFormData({ ...formData, [field]: value });
  }

  function handleCheckbox(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const field = e.target.name;
    setFormData({ ...formData, [field]: !formData[field] });
  }

  // Updates data based on fields name
  function handleTierChange(e) {
    const value = e.nativeEvent.detail.selectValue;
    const field = e.target.name;
    setFormData({ ...formData, [field]: value });
  }

  function checkForBlankFields() {
    for (const field in formData) {
      if (Array.isArray(formData[field])) {
        const arr = formData[field] as any;
        // If field is missing add the message to hasBlankField
        if (!arr.length && field !== "tiers")
          return `Field:${field} is missing`;
      }
      if (!formData[field]) {
        // If field is missing add the message to hasBlankField
        return `Field:${field} is missing`;
      }
    }
    return "";
  }

  return {
    formData,
    handleChange,
    handleTierChange,
    handleBoolChange,
    checkForBlankFields,
    handleCheckbox,
    setFormData,
    handleDateChange,
  };
}
