import c from "classnames";
import Button from "components/ui/Button/Button";
import { Icon } from "components/ui/Icon/Icon";
import s from "./WizardControls.module.scss";

interface WizardControlsProps {
  currentStepPosition: number;
  onSubmit: React.MouseEventHandler<HTMLElement>;
  onNext: () => void;
  onBack: () => void;
  numberOfSteps: number;
  isFormStateValid: boolean;
  isSubmitting: boolean;
  submitLabel: string;
  className?: string;
}

export function WizardControls({
  currentStepPosition,
  onSubmit,
  onNext,
  onBack,
  isFormStateValid,
  isSubmitting,
  submitLabel,
  numberOfSteps,
  className,
}: WizardControlsProps) {
  const isLastStep = currentStepPosition === numberOfSteps;
  return (
    <div className={c(s.root, className)}>
      {currentStepPosition !== 1 && (
        <Button variant="ghost" className={s["back-btn"]} onClick={onBack}>
          <Icon name="arrowLeft" />
          <span>Back</span>
        </Button>
      )}
      <Button
        type={isLastStep ? "submit" : "button"}
        loading={isSubmitting}
        disabled={isSubmitting || !isFormStateValid}
        className={s.btn}
        onClick={isLastStep ? onSubmit : onNext}>
        {isLastStep ? submitLabel : "Next Step"}
      </Button>
    </div>
  );
}
