import { SortingState } from "@tanstack/react-table";
import { InfiniteTable } from "components/tables/ReactTable/InfiniteTable";
import { UserCols } from "components/tables/ReactTable/UserCols";
import { Card } from "components/ui/Card/Card";
import { DashboardMainHeader } from "components/ui/DashboardMainHeader/DashboardMainHeader";
import { useSWRFetchAPIWithSchemaValidation } from "hooks/useSWRFetchAPI";
import { adminRoutesMetadata } from "routes/adminRoutesMetadata";
import { AdminUserController_getAllUsers, UserAdminDto } from "types/schemas";
import { z } from "zod";
import { useCallback, useEffect, useMemo, useState } from "react";
import s from "./UsersSection.module.scss";

const { title, description } = adminRoutesMetadata["user-management"];

const UsersSection = () => {
  const [lastKeyId, setLastKeyId] = useState<string | undefined>(undefined);
  const [isInitialized, setIsInitialized] = useState(false);
  const [users, setUsers] = useState<z.infer<typeof UserAdminDto>[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, isLoading, error, isValidating } =
    useSWRFetchAPIWithSchemaValidation(AdminUserController_getAllUsers, {
      parameters: {
        itemsPerPage: "30",
        limitKeyId: lastKeyId,
      },
      auth: true,
    });

  // Table data
  const tableCols = useMemo(() => UserCols, []);

  const handleSort = useCallback(() => {
    const sortUsers = [...users];

    return [
      ...sortUsers.sort((userA, userB) => {
        if (typeof userA[sorting[0].id] === "undefined") {
          return 1;
        }

        if (typeof userB[sorting[0].id] === "undefined") {
          return -1;
        }

        if (!sorting[0].desc) {
          if (typeof userA[sorting[0].id] === "string") {
            return (userA[sorting[0].id] as string).localeCompare(
              userB[sorting[0].id] as string
            );
          }

          return (userA[sorting[0].id] as number) >
            (userB[sorting[0].id] as number)
            ? 1
            : -1;
        }
        if (typeof userA[sorting[0].id] === "string") {
          return (userB[sorting[0].id] as string).localeCompare(
            userA[sorting[0].id] as string
          );
        }

        return (userB[sorting[0].id] as number) >
          (userA[sorting[0].id] as number)
          ? 1
          : -1;
      }),
    ];
  }, [sorting, users]);

  useEffect(() => {
    if (data && !isInitialized && !isValidating) {
      setUsers([...users, ...data.users]);
      setLastKeyId(data?.lastKey?.id as string);
      setIsInitialized(true);
    }
  }, [data, isInitialized, isValidating, users]);

  if (error) return <h2>Error Occured</h2>;

  return (
    <>
      <DashboardMainHeader title={title} description={description} />
      <Card as="section" className={s["section-wrapper"]}>
        <InfiniteTable
          initialState={{
            columnVisibility: {
              id: false,
            },
          }}
          hasLastKey={!!lastKeyId}
          columns={tableCols()}
          data={sorting.length <= 0 ? [...users] : handleSort()}
          isFetching={isLoading}
          fetchNextPage={() => {
            setIsInitialized(false);
          }}
          sorting={sorting}
          setSorting={setSorting}
          manualSorting
        />
      </Card>
    </>
  );
};

export default UsersSection;
