import c from "classnames";
import { AdminProductUpdateForm } from "components/forms/ProductForm/AdminProductUpdateForm";
import { DeleteFormModal } from "components/modals/ConfirmDeletePopup/ConfirmDeletePopup";
import { ModalWrapper } from "components/modals/ModalWrapper/ModalWrapper";
import Button from "components/ui/Button/Button";
import * as Dialog from "components/ui/Dialog/Dialog";
import { Icon } from "components/ui/Icon/Icon";
import { themeContext } from "context/themeContext";
import { toastContext } from "context/toastContext";
import { useFetchApi } from "hooks/useFetchApi";
import { KeyedMutator } from "swr";
import {
  AdminProductConfig,
  MissionControlConfig,
  PRODUCT_STATUS,
} from "types/product.types";
import {
  AdminProductController_changeStatus,
  AdminProductController_delete,
} from "types/schemas";
import { HttpError } from "utils/requests";
import React, { useContext, useState } from "react";
import ReactTooltip from "react-tooltip";
import s from "./ExpandedProductRow.module.scss";
import { StatusDropDown } from "./StatusDropDown";

const blankImageSvg = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.0013 0.666016H2.0013C1.64768 0.666016 1.30854 0.806491 1.05849 1.05654C0.808444 1.30659 0.667969 1.64573 0.667969 1.99935V25.9993C0.667969 26.353 0.808444 26.6921 1.05849 26.9422C1.30854 27.1922 1.64768 27.3327 2.0013 27.3327H26.0013C26.3549 27.3327 26.6941 27.1922 26.9441 26.9422C27.1942 26.6921 27.3346 26.353 27.3346 25.9993V1.99935C27.3346 1.64573 27.1942 1.30659 26.9441 1.05654C26.6941 0.806491 26.3549 0.666016 26.0013 0.666016ZM24.668 3.33268V14.786L22.948 13.0527C22.824 12.9277 22.6765 12.8285 22.5141 12.7608C22.3516 12.6931 22.1773 12.6583 22.0013 12.6583C21.8253 12.6583 21.651 12.6931 21.4885 12.7608C21.3261 12.8285 21.1786 12.9277 21.0546 13.0527L16.8413 17.2793L11.0813 9.27935C10.9751 9.11094 10.8326 8.96843 10.6642 8.8623C10.4958 8.75617 10.3057 8.6891 10.108 8.66602C9.91522 8.65434 9.72214 8.68266 9.54087 8.74921C9.3596 8.81575 9.19405 8.91907 9.05464 9.05268L3.33464 14.786V3.33268H24.668ZM3.33464 24.666V18.546L9.82797 12.0527L15.588 20.0527C15.6997 20.2112 15.8447 20.3434 16.0128 20.4401C16.1809 20.5369 16.3681 20.5957 16.5613 20.6127H16.668C16.8434 20.6137 17.0174 20.5801 17.1798 20.5137C17.3423 20.4474 17.49 20.3496 17.6146 20.226L22.0013 15.8793L24.668 18.546V24.666H3.33464Z"
      fill="#F6F6F6"
    />
  </svg>
);

interface IExpandedProductRowProps {
  id: string;
  name: string;
  description: string;
  status: string;
  entryUrl: string;
  children?: React.ReactNode;
  mutate: KeyedMutator<any>;
  imageUrl?: string;
  adminProductConfig: AdminProductConfig[];
  missionControl: MissionControlConfig[];
  closeExpanded: () => void;
}

// Displays the products info inside the Admin products section
export function ExpandedProductRow({
  mutate,
  id,
  name,
  description,
  entryUrl,
  status,
  imageUrl,
  adminProductConfig,
  missionControl,
  closeExpanded,
}: IExpandedProductRowProps) {
  const [showStatusDropdown, setShowStatusDropdown] = useState<boolean>(false);
  const { theme } = useContext(themeContext);
  const { fetchFromApiWithSchemaValidation } = useFetchApi();
  const { setToastErrorMessage, setToastSuccessMessage } =
    useContext(toastContext);

  function showStatusMenu() {
    setShowStatusDropdown(!showStatusDropdown);
  }

  return (
    <>
      <div className={s["offer-wrapper"]}>
        <div className={s["img-container"]}>
          <div className={s["img-main"]}>
            {!imageUrl ? blankImageSvg : <img src={imageUrl} />}
          </div>
        </div>
        <div className={s["info-container"]}>
          <>
            <div className={s["desc-div"]}>
              <p className={s.name}>{name}</p>
              <p className={s.desc}>{description}</p>
            </div>
          </>
          <div className={s["nav-div"]}>
            <div className={s["button-wrapper"]}>
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <Button
                    variant="ghost"
                    disabled={status === PRODUCT_STATUS.ACTIVE}
                    className={s.button}
                    data-tip={
                      status === PRODUCT_STATUS.ACTIVE
                        ? "Editing products is not permitted when they are in 'active' state. Please change the status to 'paused' first."
                        : undefined
                    }
                    id={id}>
                    Edit Product
                  </Button>
                </Dialog.Trigger>
                <ModalWrapper
                  title="Edit Product"
                  closeBtn
                  className={s["modal-screen"]}>
                  <AdminProductUpdateForm
                    adminProductConfig={adminProductConfig}
                    keyedMutator={mutate}
                    id={id}
                    missionControl={missionControl}
                  />
                </ModalWrapper>
              </Dialog.Root>
              {status === PRODUCT_STATUS.ACTIVE && (
                <ReactTooltip
                  className={s.tooltip}
                  arrowColor={theme === "dark" ? "white" : "black"}
                  place="right"
                />
              )}
            </div>

            <Button asChild variant="ghost">
              <a href={entryUrl} target="_blank" rel="noreferrer">
                <Icon
                  name="contentful"
                  style={{ height: "1em", marginInlineEnd: "0.2em" }}
                />
                Edit Entry in Contentful
              </a>
            </Button>
            <Button
              variant="ghost"
              onClick={showStatusMenu}
              className={s["status-label"]}>
              <span>Change Status</span>
              <Icon
                name="expand"
                className={c(
                  s["expand-icon"],
                  showStatusDropdown && s.expanded
                )}
              />
              {showStatusDropdown && (
                <StatusDropDown
                  handleSubmit={async (statusToSet) => {
                    try {
                      if (statusToSet === status)
                        return setToastErrorMessage(
                          `Already ${
                            status === PRODUCT_STATUS.ACTIVE
                              ? "Active"
                              : "Inactive"
                          }`
                        );

                      await fetchFromApiWithSchemaValidation(
                        AdminProductController_changeStatus,
                        {
                          auth: true,
                          parameters: {
                            id: id,
                            status: statusToSet,
                          },
                        }
                      );
                      setShowStatusDropdown(false);
                      mutate?.();
                    } catch (e: unknown) {
                      if (e instanceof HttpError) {
                        if (e.code === 500) {
                          setToastErrorMessage(
                            "Some of our systems seem to be unreachable, please try again later"
                          );
                        } else {
                          setToastErrorMessage(e.message);
                        }

                        return;
                      }
                      setToastErrorMessage(
                        "The entered data seems to be malformed"
                      );
                    }
                  }}
                />
              )}
            </Button>

            <Dialog.Root>
              <Dialog.Trigger asChild>
                <Button color="danger" variant="ghost" className={s.delete}>
                  Delete
                </Button>
              </Dialog.Trigger>
              <DeleteFormModal
                label="Delete Product?"
                desc="Deleting the product will remove it from the Admin Panel and the webshop. It will not be available for purchase anymore."
                handleSubmit={async (event) => {
                  event.preventDefault();

                  try {
                    await fetchFromApiWithSchemaValidation(
                      AdminProductController_delete,
                      {
                        parameters: {
                          id: id,
                        },
                        auth: true,
                      }
                    );
                    setToastSuccessMessage("Delete successful");
                    closeExpanded();
                    mutate();
                  } catch (e: unknown) {
                    if (e instanceof HttpError) {
                      if (e.code === 500) {
                        setToastErrorMessage(
                          "Some of our systems seem to be unreachable, please try again later"
                        );
                      } else {
                        setToastErrorMessage(e.message);
                      }

                      return;
                    }
                    setToastErrorMessage(
                      "The entered data seems to be malformed"
                    );
                  }
                }}
              />
            </Dialog.Root>
          </div>
        </div>
      </div>
    </>
  );
}
