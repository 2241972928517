import { Row } from "@tanstack/react-table";
import { AdminProductForm } from "components/forms/ProductForm/AdminProductForm";
import { ModalWrapper } from "components/modals/ModalWrapper/ModalWrapper";
import DataTable from "components/tables/ReactTable/DataTable";
import {
  ProductCols,
  ProductTableData,
} from "components/tables/ReactTable/ProductColumn";
import Button from "components/ui/Button/Button";
import { Card } from "components/ui/Card/Card";
import { DashboardMainHeader } from "components/ui/DashboardMainHeader/DashboardMainHeader";
import * as Dialog from "components/ui/Dialog/Dialog";
import { Icon } from "components/ui/Icon/Icon";
import { InputField } from "components/ui/Input/Input";
import { themeContext } from "context/themeContext";
import {
  useSWRFetchAPI,
  useSWRFetchAPIWithSchemaValidation,
} from "hooks/useSWRFetchAPI";
import { adminRoutesMetadata } from "routes/adminRoutesMetadata";
import {
  CDAResponse,
  CONTENTFUL_CONTENT_TYPES,
  ContentfulProductDataResponse,
} from "types/contentful.types";
import {
  AdminProductConfig,
  MissionControlConfig,
  PRODUCT_STATUS,
} from "types/product.types";
import { AdminProductController_getAll, ProductDto } from "types/schemas";
import { extractProductThumbnail } from "utils/extractProductThumbnail";
import { z } from "zod";
import { ChangeEvent, useCallback, useContext, useMemo, useState } from "react";
import { ExpandedProductRow } from "./ExpandedProductRow";
import s from "./ProductsSection.module.scss";

const { title, description } = adminRoutesMetadata.products;

export function AdminProductsSection() {
  const { data, isLoading, error, mutate } = useSWRFetchAPIWithSchemaValidation(
    AdminProductController_getAll,
    {
      auth: true,
    }
  );
  const {
    data: contentfulData,
    isLoading: isContentfulDataLoading,
    error: isContentfulDataError,
  } = useSWRFetchAPI<
    CDAResponse<{
      pdpConfig: MissionControlConfig[];
      adminProductConfig: AdminProductConfig[];
    }>
  >(
    `${process.env.REACT_APP_CDA_URL}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}/entries?access_token=${process.env.REACT_APP_CONTENTFUL_TOKEN}&content_type=${CONTENTFUL_CONTENT_TYPES.shopConfig}&limit=1`,
    {
      method: "GET",
    },
    undefined,
    false
  );

  const { data: contentfulProductsData } =
    useSWRFetchAPI<ContentfulProductDataResponse>(
      `${process.env.REACT_APP_CDA_URL}/spaces/${
        process.env.REACT_APP_CONTENTFUL_SPACE_ID
      }/environments/${
        process.env.REACT_APP_CONTENTFUL_ENVIRONMENT
      }/entries?access_token=${
        process.env.REACT_APP_CONTENTFUL_TOKEN
      }&content_type=${CONTENTFUL_CONTENT_TYPES.product}&fields.slug[in]=${data
        ?.map((products) => products.slug)
        .join(
          ","
        )}&include=2&select=fields.variants,fields.slug,fields.name,fields.description`,
      {
        method: "GET",
      },
      undefined,
      false
    );

  const tableData = useMemo<ProductTableData[]>(
    () =>
      data?.map((product) => {
        const thumbnail = extractProductThumbnail(
          product,
          product.variants[0].contentfulRef,
          contentfulProductsData
        );
        return {
          id: product.id,
          cid: product.cid,
          name: product.name,
          slug: product.slug,
          entryIsPublished: product.entryIsPublished,
          type: product.type,
          startDate: product.startDate,
          endDate: product.endDate,
          status: product.status,
          thumbnail,
          variants: product.variants,
        };
      }),
    [data, contentfulProductsData]
  );

  const [searchValue, setSearchValue] = useState<string>();
  const { theme } = useContext(themeContext);
  // Shows the dropdown info section for an item
  const renderExpandedInfo = useCallback(
    ({ row }: { row: Row<z.infer<typeof ProductDto>> }) => {
      const apiProduct = data?.find(
        (product) => product.id === (row.getValue("id") as string)
      );

      const contentfulProduct = contentfulProductsData?.items.find(
        (product) => product.fields.slug === apiProduct?.slug
      );

      const imageUrl = apiProduct
        ? extractProductThumbnail(
            apiProduct,
            apiProduct.variants[0].contentfulRef,
            contentfulProductsData
          )
        : undefined;

      const cmsDescription = contentfulProduct?.fields.description ?? "";
      const cmsTitle = contentfulProduct?.fields.name ?? "";

      return (
        <ExpandedProductRow
          imageUrl={imageUrl}
          id={row.getValue("id") as string}
          name={cmsTitle}
          description={cmsDescription}
          entryUrl={apiProduct?.entryUrl as string}
          status={row.getValue("status") as PRODUCT_STATUS}
          mutate={mutate}
          adminProductConfig={contentfulData.items[0].fields.adminProductConfig}
          missionControl={contentfulData.items[0].fields.pdpConfig}
          closeExpanded={() => row.toggleExpanded(false)}
        />
      );
    },
    [data, contentfulProductsData, mutate, contentfulData]
  );

  function handleSearchInput(e: ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
  }

  if (isLoading || isContentfulDataLoading) return <h6>Loading...</h6>;
  if (error || isContentfulDataError) return <h2>Error Occured</h2>;
  if (!contentfulData) return <></>;

  return (
    <>
      <DashboardMainHeader title={title} description={description} />
      <Card as="section" className={s["section-wrapper"]}>
        <div className={s["section-nav"]}>
          <div className={s["search-wrapper"]}>
            <InputField
              onChange={handleSearchInput}
              label="Search across all attributes"
              className={s["search-field"]}
              type="search"
            />
          </div>

          <Dialog.Root>
            <Dialog.Trigger asChild>
              <Button size="large">
                <Icon name="add" style={{ height: "1em" }} />
                <span>Create Product</span>
              </Button>
            </Dialog.Trigger>
            <ModalWrapper
              title="Create Product"
              className={s["modal-screen"]}
              closeBtn>
              <AdminProductForm
                missionControl={contentfulData.items[0].fields.pdpConfig}
                adminProductConfig={
                  contentfulData.items[0].fields.adminProductConfig
                }
                mutate={mutate}
              />
            </ModalWrapper>
          </Dialog.Root>
        </div>

        <DataTable
          columns={ProductCols(theme === "dark" ? "dark" : "light")}
          // Drafted should always be on top
          data={
            tableData.length
              ? tableData.sort((a, b) => {
                  const creationA = (a as any).creation ?? -1;
                  const creationB = (b as any).creation ?? -1;
                  return creationA + creationB;
                })
              : tableData
          }
          expandedComponent={renderExpandedInfo}
          tableClass="table-offers"
          filterValue={{ key: "name", value: searchValue }}
        />
      </Card>
    </>
  );
}
