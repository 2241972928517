import c from "classnames";
import { themeContext } from "context/themeContext";
import React, { useContext } from "react";
import s from "./FormWrapper.module.scss";

interface IFormProps {
  label?: string;
  onSubmit?: any;
  children?: React.ReactNode;
  className?: string;
}

export function FormWrapper({
  onSubmit,
  className,
  label,
  children,
}: IFormProps) {
  const { theme } = useContext(themeContext);
  return (
    <form
      onSubmit={onSubmit}
      className={c(s.form, className)}
      data-theme={theme}>
      {label && (
        <div
          className={c(
            s["form-label"],
            {
              [s.dark]: theme === "dark",
            },
            className
          )}>
          {label}
        </div>
      )}
      {children}
    </form>
  );
}
