import Button from "components/ui/Button/Button";
import { InputField } from "components/ui/Input/Input";
import { SelectField } from "components/ui/SelectField/SelectField";
import { themeContext } from "context/themeContext";
import { toastContext } from "context/toastContext";
import { useFormData } from "hooks/useFormData";
import {
  AdminProductConfig,
  AttributeTypeToLocalizedString,
  MissionControlConfig,
  PRODUCT_TYPE,
  VariantsDefaultState,
} from "types/product.types";
import { getDescriptionBasedOnAttribute } from "utils/getDescriptionBasedOnAttribute";
import { useContext, useState } from "react";
import { FormWrapper } from "../FormWrapper/FormWrapper";
import s from "./VariantsForm.module.scss";

interface VariantsFormProps {
  missionControl?: MissionControlConfig[];
  setShowForm: (value: boolean) => void;
  handleSaveVariant: (variant: VariantsDefaultState) => void;
  variant: VariantsDefaultState;
  productType: PRODUCT_TYPE;
  subType?: string;
  adminProductConfig: AdminProductConfig[];
}

export function VariantsForm({
  missionControl,
  setShowForm,
  variant,
  handleSaveVariant,
  productType,
  subType,
}: VariantsFormProps) {
  const { formData, handleChange, handleCheckbox } = useFormData({
    name: variant.name ?? "",
    price: variant.price ?? 0,
    quantity: variant.quantity ?? 0,
    isMaster: variant.isMaster ?? false,
    attribute: variant.attribute ?? undefined,
  });
  const { theme } = useContext(themeContext);
  const [cvsData, setCvsData] = useState("");
  const [fileData, setFileData] = useState<File | undefined>();
  const { setToastErrorMessage } = useContext(toastContext);

  async function handleCvsUpload(event: React.FormEvent<HTMLInputElement>) {
    const files = event.currentTarget.files;
    if (!files) {
      return;
    }

    if (files.length > 1) {
      setToastErrorMessage("Max number of files is 1");
      return;
    }

    setFileData(files[0]);

    const reader = new FileReader();
    reader.onload = (progressEvent: ProgressEvent<FileReader>) => {
      setCvsData(progressEvent.target?.result as string);
    };
    await reader.readAsDataURL(files[0]);
  }

  let variantAttribute = <></>;

  if (missionControl) {
    const control = missionControl.find(
      (product) => product.productTypeKey === subType
    );

    variantAttribute =
      control?.attribute && control.attribute.orderedList?.length > 0 ? (
        <SelectField
          title={`${
            AttributeTypeToLocalizedString[
              missionControl.find(
                (product) => product.productTypeKey === subType
              )?.attribute
                .attributeName as keyof typeof AttributeTypeToLocalizedString
            ]
          }`}
          required
          description={getDescriptionBasedOnAttribute(
            missionControl.find((product) => product.productTypeKey === subType)
              ?.attribute.attributeName
          )}
          name="attribute"
          placeholder="Select a value..."
          value={formData.attribute ?? ""}
          onChange={(value) =>
            handleChange({
              target: { value, name: "attribute" },
            } as React.ChangeEvent<HTMLSelectElement>)
          }
          options={
            missionControl
              .find((product) => product.productTypeKey === subType)
              ?.attribute.orderedList.map(
                (orderList) =>
                  ({
                    label: orderList,
                    value: orderList,
                  }) ?? []
              ) ?? []
          }
        />
      ) : (
        <InputField
          title={`${
            AttributeTypeToLocalizedString[
              missionControl.find(
                (product) => product.productTypeKey === subType
              )?.attribute
                .attributeName as keyof typeof AttributeTypeToLocalizedString
            ]
          }`}
          description={getDescriptionBasedOnAttribute(
            missionControl.find((product) => product.productTypeKey === subType)
              ?.attribute.attributeName
          )}
          required
          name="attribute"
          value={formData.attribute ?? ""}
          type="name"
          onChange={handleChange}
          className={s.input}
          variant={theme === "dark" ? "light" : "dark"}
        />
      );
  }

  return (
    <FormWrapper className={s["form-wrapper"]}>
      <InputField
        title="Product Variant Name"
        name="name"
        description="Internal name to help you identify the variant."
        required
        value={formData.name ?? ""}
        onChange={handleChange}
        className={s.input}
        variant={theme === "dark" ? "light" : "dark"}
      />
      {variantAttribute}
      {productType === PRODUCT_TYPE.ITEM && (
        <InputField
          title="Stock Quantity"
          name="quantity"
          required
          errorState={+formData.quantity < 0}
          errorMessage="Quantity should be above 0"
          type="number"
          value={formData.quantity ?? ""}
          onChange={handleChange}
          className={s.input}
          variant={theme === "dark" ? "light" : "dark"}
        />
      )}
      <InputField
        title="Price in PT"
        name="price"
        icon="porscheToken"
        required
        value={formData.price ?? ""}
        type="number"
        onChange={(e) => {
          if (/^\d+$/.test(e.target.value) || e.target.value === "") {
            handleChange(e);
          }
        }}
        className={s.input}
        variant={theme === "dark" ? "light" : "dark"}
      />

      {productType === PRODUCT_TYPE.VOUCHER && (
        <div className={s["images-wrapper"]}>
          <div className={s["label-wrapper"]}>
            <label>Add CSV File *</label>
            <p className={s.description}>
              Add the CSV File that contains the voucher codes.
            </p>
          </div>
          <div className={s["images-container"]}>
            <div className={s["add-box"]}>
              <InputField
                type="file"
                onChange={handleCvsUpload}
                className={s["files-input"]}
                required
                accept=".csv"
              />
            </div>
            {fileData && (
              <p className={s["file-name-label"]}>
                Added CSV File: {fileData.name}
              </p>
            )}
          </div>
        </div>
      )}
      <InputField
        title="Select as master variant (variant will be preselected on the product page)"
        name="isMaster"
        type="checkbox"
        value={formData.isMaster}
        onChange={handleCheckbox}
      />
      <div className={s.footer}>
        <Button
          type="button"
          disabled={Number(formData.quantity) < 0}
          onClick={() => {
            if (productType === PRODUCT_TYPE.VOUCHER && !cvsData) {
              setToastErrorMessage("Please upload a CSV file");
              return;
            }

            handleSaveVariant({
              ...formData,
              id: variant.id ?? undefined,
              price: +formData.price, // TODO: the types need to be checked
              quantity: +formData.quantity,
              discountCodesCsvBase64:
                productType === PRODUCT_TYPE.VOUCHER
                  ? cvsData.split(";")[1].replace("base64,", "")
                  : undefined,
            });
            setShowForm(false);
          }}>
          Done
        </Button>
      </div>
    </FormWrapper>
  );
}
