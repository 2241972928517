/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApiRequest } from "types/schemas";
import { z } from "zod";

export const constructApiRequest = <ZodResponseSchema extends z.ZodTypeAny>(
  request: ApiRequest<ZodResponseSchema>,
  auth: boolean,
  parameters?: { [key: string]: any }
): [
  string,
  {
    method: "GET" | "POST" | "PUT" | "DELETE";
    auth: boolean;
    data: { [key: string]: unknown };
  },
] => {
  let requestPath = request.path;
  const parametersToUse = parameters ?? {}; // prevents failure if only optional parameters and non given

  request.parameters.forEach((requestParameter) => {
    let parseResult;
    if (requestParameter.type !== "Body") {
      parseResult = requestParameter.schema.safeParse(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parametersToUse![requestParameter.name]
      );
    } else {
      parseResult = requestParameter.schema.safeParse(parametersToUse);
    }

    if (!parseResult.success) {
      // eslint-disable-next-line no-console
      console.error(
        `❌ Invalid parameter ${requestParameter.name} set\n`,
        parseResult.error.format()
      );

      const { issues } = parseResult.error;

      if (issues.length) {
        const currentIssue = issues[0];

        const { path, message } = currentIssue;
        const pathString = path.join(".");

        throw new Error(`Invalid parameter: ${pathString}: ${message}`);
      }

      throw new Error("Invalid parameter");
    }
  });

  const pathParameters = request.parameters.filter(
    (parameter) => parameter.type === "Path"
  );

  pathParameters.forEach((pathParameter) => {
    requestPath = requestPath.replace(
      `:${pathParameter.name}`,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parametersToUse![pathParameter.name]
    );
  });

  const queryParameters = request.parameters.filter(
    (parameter) => parameter.type === "Query"
  );

  if (queryParameters.length > 0) {
    const searchParams = new URLSearchParams();
    queryParameters.forEach((queryParameter) => {
      if (Array.isArray(parametersToUse![queryParameter.name])) {
        parametersToUse![queryParameter.name].forEach((value) =>
          searchParams.append(queryParameter.name, value)
        );
      } else if (parametersToUse![queryParameter.name]) {
        searchParams.append(
          queryParameter.name,
          parametersToUse![queryParameter.name]
        );
      }
    });
    requestPath = `${requestPath}?${searchParams.toString()}`;
  }

  let data;

  const bodyParameters = request.parameters.filter(
    (parameter) => parameter.type === "Body"
  );

  if (bodyParameters.length > 0) {
    data = bodyParameters[0].schema.parse(parametersToUse);
  }

  return [
    requestPath,
    {
      method: request.method.toUpperCase() as "GET" | "POST" | "PUT" | "DELETE",
      auth,
      data,
    },
  ];
};
