import { FormWrapper } from "components/forms/FormWrapper/FormWrapper";
import Button from "components/ui/Button/Button";
import { Icon, IconName } from "components/ui/Icon/Icon";
import { ModalWrapper } from "../ModalWrapper/ModalWrapper";
import s from "./ConfirmDeletePopup.module.scss";

interface DeleteFormProps {
  label: string;
  desc: string;
  buttonText?: string;
  iconName?: IconName;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

export function DeleteFormModal({
  label,
  desc,
  buttonText,
  iconName,
  handleSubmit,
}: DeleteFormProps) {
  return (
    <ModalWrapper variant="flat" closeBtn className={s["modal-screen"]}>
      <FormWrapper onSubmit={handleSubmit}>
        <div className={s["delete-wrapper"]}>
          <Icon name={!iconName ? "delete" : iconName} className={s.icon} />
          <p className={s["modal-label"]}>{label}</p>
          <p className={s["modal-desc"]}>{desc}</p>
        </div>
        <Button color="danger" type="submit" className={s.btn}>
          {!buttonText ? "Delete" : buttonText}
        </Button>
      </FormWrapper>
    </ModalWrapper>
  );
}
