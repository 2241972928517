import Button, { IButtonProps } from "components/ui/Button/Button";

const ButtonLink = ({
  children,
  href,
  ...props
}: IButtonProps & {
  href: string;
}) => (
  <Button asChild {...props}>
    <a href={href}>{children}</a>
  </Button>
);

export default ButtonLink;
