import * as TabsPrimitive from "@radix-ui/react-tabs";
import c from "classnames";
import * as React from "react";
import s from "./Tabs.module.scss";
import type {
  TabsContentElement,
  TabsContentProps,
  TabsListElement,
  TabsListProps,
  TabsRootElement,
  TabsRootProps,
  TabsTriggerElement,
  TabsTriggerProps,
} from "./types";

const TabsRoot = React.forwardRef<TabsRootElement, TabsRootProps>(
  ({ className, ...rootProps }, forwardedRef) => (
    <TabsPrimitive.Root
      {...rootProps}
      ref={forwardedRef}
      className={c(s["TabsRoot"], className)}
    />
  )
);
TabsRoot.displayName = "Tabs.Root";

const TabsList = React.forwardRef<TabsListElement, TabsListProps>(
  ({ className, underline, ...listProps }, forwardedRef) => (
    <TabsPrimitive.List
      {...listProps}
      asChild={false}
      ref={forwardedRef}
      className={c(
        s["base-tab-list"],
        s["TabsList"],
        underline && s.underline,
        className
      )}
    />
  )
);
TabsList.displayName = "Tabs.List";

const TabsTrigger = React.forwardRef<TabsTriggerElement, TabsTriggerProps>(
  (props, forwardedRef) => {
    const { className, children, ...triggerProps } = props;
    return (
      <TabsPrimitive.Trigger
        {...triggerProps}
        asChild={false}
        ref={forwardedRef}
        className={c(
          s["reset"],
          s["base-tab-list-trigger"],
          s["TabsTrigger"],
          className
        )}>
        <span
          className={c(
            s["base-tab-list-trigger-inner"],
            s["TabsTriggerInner"]
          )}>
          {children}
        </span>
        <span
          className={c(
            s["base-tab-list-trigger-inner-hidden"],
            s["TabsTriggerInnerHidden"]
          )}>
          {children}
        </span>
      </TabsPrimitive.Trigger>
    );
  }
);
TabsTrigger.displayName = "Tabs.Trigger";

const TabsContent = React.forwardRef<TabsContentElement, TabsContentProps>(
  ({ className, ...contentProps }, forwardedRef) => (
    <TabsPrimitive.Content
      {...contentProps}
      ref={forwardedRef}
      className={c(s["TabsContent"], className)}
    />
  )
);

TabsContent.displayName = "Tabs.Content";

export {
  TabsRoot as Root,
  TabsList as List,
  TabsTrigger as Trigger,
  TabsContent as Content,
};
export type {
  TabsRootProps as RootProps,
  TabsListProps as ListProps,
  TabsTriggerProps as TriggerProps,
  TabsContentProps as ContentProps,
};
