import { ContentfulProductDataResponse } from "types/contentful.types";
import { ProductDto } from "types/schemas";
import { z } from "zod";

export const extractProductThumbnail = (
  apiProduct: z.infer<typeof ProductDto>,
  contentfulRef: string,
  contentfulProduct: ContentfulProductDataResponse
) => {
  const cmsVariant = contentfulProduct?.includes?.Entry.find(
    (entry) => entry.fields.dbRef === contentfulRef
  );

  const variantImageId = contentfulProduct?.includes?.Entry.find(
    (entry) => entry.sys.id === cmsVariant?.sys.id
  )?.fields?.images?.[0]?.sys?.id;

  const imageUrl = contentfulProduct?.includes?.Asset.find(
    (asset) => asset.sys.id === variantImageId
  )?.fields?.file?.url;

  return imageUrl ? `https:${imageUrl}` : undefined;
};
