import { VariantsForm } from "components/forms/VariantsForm/VariantsForm";
import Button from "components/ui/Button/Button";
import { Icon } from "components/ui/Icon/Icon";
import { Tag } from "components/ui/Tag/Tag";
import {
  AdminProductConfig,
  MissionControlConfig,
  PRODUCT_TYPE,
  VariantsDefaultState,
} from "types/product.types";
import { Dispatch, SetStateAction, useState } from "react";
import s from "./VariantBox.module.scss";

interface VariantBoxProps {
  missionControl?: MissionControlConfig[];
  handleSaveVariant: (variant: VariantsDefaultState) => void;
  handleRemoveVariant: () => void;
  variant: VariantsDefaultState;
  setIsVariantFormOpened?: Dispatch<SetStateAction<boolean>>;
  productType: PRODUCT_TYPE;
  adminProductConfig: AdminProductConfig[];
  subType?: string;
  isCreate?: boolean;
}

const VariantBox = ({
  adminProductConfig,
  missionControl,
  variant,
  handleSaveVariant,
  setIsVariantFormOpened,
  handleRemoveVariant,
  productType,
  subType,
  isCreate = true,
}: VariantBoxProps) => {
  const [openVariantsForm, setOpenVariantsForm] = useState(isCreate);

  return (
    <>
      {!openVariantsForm && (
        <div className={s["box-wrapper"]}>
          <header className={s.header}>
            <span className={s["header-left-content"]}>
              <h6>{variant.name}</h6>
              {variant.isMaster && <Tag>✦ Master Variant</Tag>}
            </span>
            <div className={s["buttons-wrapper"]}>
              <Button
                className={s["action-btn"]}
                variant="ghost"
                size="small"
                onClick={() => {
                  setOpenVariantsForm(true);
                  setIsVariantFormOpened?.(true);
                }}>
                <Icon name="edit" className={s["btn-icon"]} />
                <span>Edit</span>
              </Button>
              <Button
                className={s["action-btn"]}
                variant="ghost"
                size="small"
                onClick={() => {
                  handleRemoveVariant?.();
                }}>
                <Icon name="delete" className={s["btn-icon"]} />
                <span>Delete</span>
              </Button>
            </div>
          </header>
          <p>
            <b>Price:</b> {variant.price}PT
          </p>

          {variant.quantity ? (
            <p>
              <b>Quantity:</b> {variant.quantity}
            </p>
          ) : (
            <></>
          )}

          {variant.remainingQuantity !== undefined ? (
            <p>
              <b>Remaining Quantity:</b> {variant.remainingQuantity}
            </p>
          ) : (
            <></>
          )}
        </div>
      )}
      {openVariantsForm && (
        <VariantsForm
          adminProductConfig={adminProductConfig}
          missionControl={missionControl}
          variant={variant}
          productType={productType}
          subType={subType}
          handleSaveVariant={handleSaveVariant}
          setShowForm={(value: boolean) => {
            setOpenVariantsForm(value);
            setIsVariantFormOpened?.(value);
          }}
        />
      )}
    </>
  );
};
export default VariantBox;
