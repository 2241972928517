import {
  BenefitFormDataState,
  defaultState,
} from "components/forms/BenefitForm/BenefitForm";
import Button from "components/ui/Button/Button";
import * as Dialog from "components/ui/Dialog/Dialog";
import { Icon } from "components/ui/Icon/Icon";
import { InputField } from "components/ui/Input/Input";
import { toastContext } from "context/toastContext";
import { useFetchApi } from "hooks/useFetchApi";
import { useFormData } from "hooks/useFormData";
import { useSWRFetchAPIWithSchemaValidation } from "hooks/useSWRFetchAPI";
import { KeyedMutator } from "swr";
import {
  AdminBenefitController_update,
  BenefitController_getBenefit,
} from "types/schemas";
import { HttpError } from "utils/requests";
import React, { useContext, useEffect, useState } from "react";
import { S_TO_MS } from "../../../constants";
import { FormWrapper } from "../FormWrapper/FormWrapper";
import s from "./BenefitUpdateForm.module.scss";

interface BenefitUpdateFormProps {
  keyedMutator: KeyedMutator<unknown>;
  id?: string;
}

export const updateFormFields = {
  name: true,
  contentfulRef: true,
  status: true,
  rules: true,
  timeActive: true,
};

export function BenefitUpdateForm({
  keyedMutator,
  id,
}: BenefitUpdateFormProps) {
  const {
    data: SWRdata,
    isLoading,
    mutate,
  } = useSWRFetchAPIWithSchemaValidation(BenefitController_getBenefit, {
    parameters: {
      benefitId: id,
    },
    auth: true,
  });
  const { formData, handleChange, setFormData, handleDateChange } =
    useFormData(defaultState);
  const { setToastErrorMessage, setToastSuccessMessage } =
    useContext(toastContext);
  const { fetching, fetchFromApiWithSchemaValidation } = useFetchApi();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<string>("");

  useEffect(() => {
    if (!isInitialized && SWRdata) {
      // Not part of the Form
      for (const field in SWRdata) {
        if (!updateFormFields[field]) delete SWRdata[field];
      }

      const copyData: Partial<any> = {
        ...SWRdata,
        timeActive: SWRdata.timeActive / S_TO_MS,
      };

      setFormData(copyData as BenefitFormDataState);
      setIsInitialized(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, isInitialized, SWRdata]);
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const newData = {
      ...formData,
      contentfulRef: SWRdata.contentfulRef,
    };

    try {
      const response = await fetchFromApiWithSchemaValidation(
        AdminBenefitController_update,
        {
          parameters: {
            benefitId: id,
            ...newData,
            useExistingContentfulEntry: true,
            timeActive: +newData.timeActive * S_TO_MS,
          },
          auth: true,
        }
      );
      setToastSuccessMessage("Benefit Edited");
      mutate();
      setShowSuccess(response.id);
      keyedMutator();
    } catch (e: unknown) {
      if (e instanceof HttpError) {
        if (e.code === 500) {
          setToastErrorMessage(
            "Some of our systems seem to be unreachable, please try again later"
          );
        } else {
          setToastErrorMessage(e.message);
        }

        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setToastErrorMessage((e as any).message);
    }
  }

  if (showSuccess)
    return (
      <div className={s["success-screen"]}>
        <Icon name="success" className={s.check} inverted />
        <p className={s.label}>Success!</p>
        <p className={s.msg}>The Benefit was successfully updated</p>
        <Dialog.Close>
          <Button type="submit" className={s.btn}>
            Back to Dashboard
          </Button>
        </Dialog.Close>
      </div>
    );

  return (
    <>
      {SWRdata && isInitialized && (
        <FormWrapper onSubmit={handleSubmit} className={s["form"]}>
          <div className={s.scrollable}>
            <InputField
              title="Name"
              name="name"
              value={formData.name ?? ""}
              onChange={handleChange}
              className={s.input}
              showInfo="This is a tooltip"
            />
            <InputField
              title="Time Active (in seconds)"
              name="timeActive"
              type="number"
              value={formData.timeActive}
              onChange={handleChange}
              className={s.input}
              showInfo="The time should be in seconds"
            />
          </div>

          <Button
            type="submit"
            loading={fetching}
            disabled={fetching}
            className={s.btn}>
            Update
          </Button>
        </FormWrapper>
      )}
      {(!SWRdata || isLoading) && <p>Loading</p>}
    </>
  );
}
