const OAUTH_SCOPES = [
  "openid",
  "profile",
  "email",
  "offline_access",
  "pid:user_profile.dealers:read",
  "pid:user_profile.emails:read",
  "pid:user_profile.name:read",
  "pid:user_profile.birthdate:read",
  "pid:user_profile.identity_verification:read",
  "pid:user_profile.addresses:read",
  "pid:user_profile.locale:read",
  "pid:user_profile.phones:read",
  "pid:user_profile.vehicles:read",
  "pid:user_profile.terminated_vehicles:read",
  "pid:user_profile.legal:read",
];

export const getOAuthUri = () =>
  `${process.env.REACT_APP_PORSCHE_ID_HOST}/authorize?client_id=${
    process.env.REACT_APP_PORSCHE_ID_CLIENT_ID
  }&scope=${encodeURIComponent(
    OAUTH_SCOPES.join(" ")
  )}&response_type=code&redirect_uri=${
    process.env.REACT_APP_PORSCHE_ID_REDIRECT_URI
  }&audience=${
    process.env.REACT_APP_PORSCHE_ID_HOST_AUDIENCE
  }&ui_locales=en-GB`;
