import c from "classnames";
import { Tag } from "components/ui/Tag/Tag";
import { PURCHASE_STATUS } from "types/order.types";
import s from "./OrdersView.module.scss";

interface OrderViewDropdowmProps {
  handleSubmit: (status: PURCHASE_STATUS) => Promise<void>;
  className?: string;
}

export function OrderViewDropdown({
  handleSubmit,
  className,
}: OrderViewDropdowmProps) {
  return (
    <div className={c(s["status-menu"], className)}>
      <Tag
        icon="paused"
        variant="default"
        onClick={() => handleSubmit(PURCHASE_STATUS.INITIAL)}>
        INITIAL
      </Tag>
      <Tag
        icon="upcoming"
        variant="info"
        onClick={() => handleSubmit(PURCHASE_STATUS.IN_PROGRESS)}>
        IN PROGRESS
      </Tag>
      <Tag
        icon="active"
        variant="success"
        className={s.success}
        onClick={() => handleSubmit(PURCHASE_STATUS.FULFILLED)}>
        FULFILLED
      </Tag>
    </div>
  );
}
