export const apiEndpoint = process.env.REACT_APP_API_URL;

export const goerliChainId = 5;
export const mainnetChainId = 1;

// to milliseconds
export const S_TO_MS = 1000;
export const M_TO_MS = 60 * S_TO_MS;
export const H_TO_MS = 60 * M_TO_MS;
export const D_TO_MS = 24 * H_TO_MS;
