import c from "classnames";
import s from "./ToastMessage.module.scss";

interface ToastMessageProps {
  msg: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  type: "success" | "error";
}

// Pointer Events is added in CSS to prevent click events which break the popup modals
export function ToastMessage({ msg, onClick, type }: ToastMessageProps) {
  return (
    <div
      className={c(s["toast-wrapper"], {
        [s["success"]]: type === "success",
      })}
      onClick={onClick}>
      <div className={s.title}>
        {type === "error" ? "Something went wrong" : msg}
      </div>
      <div className={s.msg}>{msg}</div>
    </div>
  );
}
