import { Slot } from "@radix-ui/react-slot";
import c from "classnames";
import Loader from "components/ui/Loader/Loader";
import { forwardRef } from "react";
import s from "./Button.module.scss";

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  variant?: "primary" | "ghost";
  loading?: boolean;
  size?: "small" | "medium" | "large";
  color?: "primary" | "danger";
}

const Button = forwardRef<React.ElementRef<"button">, IButtonProps>(
  (
    {
      className,
      variant = "primary",
      children,
      disabled,
      asChild,
      loading,
      color = "primary",
      size = "large",
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={c(s.button, className)}
        data-variant={variant}
        data-size={size}
        disabled={disabled}
        data-accent-color={color}
        data-disabled={disabled || undefined}
        ref={ref}
        {...props}>
        {loading ? <Loader size={20} /> : children}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export default Button;
