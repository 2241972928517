import { Row } from "@tanstack/react-table";
import { InfiniteTable } from "components/tables/ReactTable/InfiniteTable";
import { OrdersCols } from "components/tables/ReactTable/OrdersColumn";
import { Card } from "components/ui/Card/Card";
import { DashboardMainHeader } from "components/ui/DashboardMainHeader/DashboardMainHeader";
import FilterDropdown from "components/ui/FilterDropdown/FilterDropdown";
import { OrdersView } from "components/views/protected/OrdersSection/OrdersView";
import { themeContext } from "context/themeContext";
import {
  useSWRFetchAPI,
  useSWRFetchAPIWithSchemaValidation,
} from "hooks/useSWRFetchAPI";
import { adminRoutesMetadata } from "routes/adminRoutesMetadata";
import {
  CDAResponse,
  CONTENTFUL_CONTENT_TYPES,
  ContentfulProductAsset,
  ContentfulProductEntry,
  ContentfulProductFields,
} from "types/contentful.types";
import { PURCHASE_STATUS } from "types/order.types";
import {
  AddressDto,
  AdminProductController_getAll,
  AdminUserController_getAllUserPurchases,
  AdminUserController_getUsersByIds,
  UserAdminDto,
  UserPurchaseDto,
} from "types/schemas";
import { extractProductThumbnail } from "utils/extractProductThumbnail";
import { z } from "zod";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import s from "./OrderSection.module.scss";

const { title, description } = adminRoutesMetadata.orders;
type ContentfulProductDataResponse = CDAResponse<
  ContentfulProductFields,
  ContentfulProductEntry,
  ContentfulProductAsset
>;

const filterOptions = [
  {
    id: PURCHASE_STATUS.INITIAL,
    name: "Initial",
  },
  {
    id: PURCHASE_STATUS.IN_PROGRESS,
    name: "In progress",
  },
  {
    id: PURCHASE_STATUS.FULFILLED,
    name: "Fulfilled",
  },
];

export const OrdersSection = () => {
  const [activeFilter, setActiveFilter] = useState<PURCHASE_STATUS>(
    PURCHASE_STATUS.INITIAL
  );

  const [lastKeyId, setLastKeyId] = useState<string | undefined>(undefined);
  const [lastKeyUserId, setLastKeyUserId] = useState<string | undefined>(
    undefined
  );
  const [isInitialized, setIsInitialized] = useState(false);
  const [orders, setOrders] = useState<z.infer<typeof UserPurchaseDto>[]>([]);
  const [users, setUsers] = useState<z.infer<typeof UserAdminDto>[]>([]);
  const [userIds, setUserIds] = useState<string[] | undefined>(undefined);

  const {
    data: ordersData,
    isLoading,
    error: isError,
    mutate,
    isValidating,
  } = useSWRFetchAPIWithSchemaValidation(
    AdminUserController_getAllUserPurchases,
    {
      parameters: {
        status: activeFilter,
        itemsPerPage: "30",
        limitKeyId: lastKeyId,
        limitKeyUserId: lastKeyUserId,
      },
      auth: true,
    }
  );
  const { isLoading: isUsersDataLoading } = useSWRFetchAPIWithSchemaValidation(
    AdminUserController_getUsersByIds,
    {
      parameters: {
        userIds: userIds,
      },
      auth: true,
      shouldFetch: !!userIds,
    },
    (fetchedUsers: z.infer<typeof UserAdminDto>[]) => {
      const uniqueUsers = fetchedUsers.filter(
        (fetchedUser) => !users?.some((user) => user.id === fetchedUser.id)
      );
      setUsers([...users, ...uniqueUsers]);
      setUserIds(undefined);
    }
  );

  const { data: productsData, isLoading: isProductDataLoading } =
    useSWRFetchAPIWithSchemaValidation(AdminProductController_getAll, {
      parameters: {},
      auth: true,
    });

  const { data: contentfulProductsData } =
    useSWRFetchAPI<ContentfulProductDataResponse>(
      `${process.env.REACT_APP_CDA_URL}/spaces/${
        process.env.REACT_APP_CONTENTFUL_SPACE_ID
      }/environments/${
        process.env.REACT_APP_CONTENTFUL_ENVIRONMENT
      }/entries?access_token=${
        process.env.REACT_APP_CONTENTFUL_TOKEN
      }&content_type=${CONTENTFUL_CONTENT_TYPES.product}&fields.slug[in]=${productsData
        ?.map((products) => products.slug)
        .join(
          ","
        )}&include=2&select=fields.variants,fields.slug,fields.name,fields.description`,
      {
        method: "GET",
      },
      undefined,
      false
    );

  const { theme } = useContext(themeContext);

  // Table data
  const tableCols = useMemo(() => OrdersCols, []);

  const resetState = useCallback(async () => {
    setLastKeyId(undefined);
    setLastKeyUserId(undefined);
    setOrders([]);
    await mutate();
    setIsInitialized(false);
  }, [mutate]);
  useEffect(() => {
    if (ordersData && !isInitialized && !isValidating) {
      setOrders([...orders, ...ordersData.orders]);
      setLastKeyId(ordersData?.lastKey?.id as string);
      setLastKeyUserId(ordersData?.lastKey?.userId as string);
      setUserIds([...new Set(ordersData.orders.map((order) => order.userId))]);
      setIsInitialized(true);
    }
  }, [activeFilter, isInitialized, isValidating, orders, ordersData]);

  // Shows the dropdown info section for an item
  const renderExpandedInfo = useCallback(
    ({ row }: { row: Row<z.infer<typeof UserPurchaseDto>> }) => {
      const id = row.getValue("id") as string;

      const userPurchase = orders?.find((purchase) => purchase.id === id);

      if (!userPurchase?.userId) {
        return <></>;
      }

      const deliveryAddress = userPurchase.deliveryAddress
        ? (JSON.parse(userPurchase.deliveryAddress) as z.infer<
            typeof AddressDto
          >)
        : undefined;

      const product = productsData?.find(
        (productData) => productData.id === userPurchase.product.productId
      );

      const variant = product?.variants.find(
        (productVariant) => productVariant.id === userPurchase.product.variantId
      );

      const imageUrl =
        product && variant
          ? extractProductThumbnail(
              product,
              variant.contentfulRef,
              contentfulProductsData
            )
          : undefined;

      return (
        <OrdersView
          totalPrice={row.getValue("totalPrice")}
          id={id}
          note={userPurchase?.adminNote}
          variantName={variant?.name}
          address={deliveryAddress}
          updateAdminNote={(note) => {
            setOrders(
              orders.map((order) => ({
                ...order,
                adminNote: order.id === id ? note : order.adminNote,
              }))
            );
          }}
          resetState={resetState}
          toggleExpandedView={row.toggleExpanded}
          status={row.getValue("status") as PURCHASE_STATUS}
          userId={userPurchase.userId}
          imageUrl={imageUrl}
        />
      );
    },
    [contentfulProductsData, orders, productsData, resetState]
  );

  if (isProductDataLoading) return <h6>Loading...</h6>;

  if (isError) return <h2>Error Occured</h2>;

  return (
    <>
      <DashboardMainHeader title={title} description={description} />
      <Card as="section" className={s["section-wrapper"]}>
        <div className={s["section-nav"]}>
          <FilterDropdown
            changeFilter={(id: PURCHASE_STATUS) => {
              setActiveFilter(id);
              resetState();
            }}
            options={filterOptions}
            currentFilterNames={
              filterOptions.find(({ id }) => id === activeFilter)?.name
            }
          />
        </div>
        <InfiniteTable
          hasLastKey={!!(lastKeyId && lastKeyUserId)}
          columns={tableCols(theme === "dark" ? "dark" : "light")}
          data={orders.map((orderData) => {
            const user = users?.find(
              (userData) => userData.id === orderData.userId
            );

            const product = productsData?.find(
              (productData) => productData.id === orderData.product.productId
            );

            return {
              ...orderData,
              name: `${user?.firstName} ${user?.lastName}`,
              email: user?.email,
              productType: product?.type,
            };
          })}
          isFetching={isLoading || (!!userIds && isUsersDataLoading)}
          fetchNextPage={() => {
            setIsInitialized(false);
          }}
          expandedComponent={renderExpandedInfo}
        />
      </Card>
    </>
  );
};
