import { SupportedAttributeType } from "types/product.types";

export const getDescriptionBasedOnAttribute = (
  attributeName?: SupportedAttributeType
) => {
  switch (attributeName) {
    case SupportedAttributeType.Color:
      return "Enter the color of the product in 6-digit or 3-digit hex code format, e.g. black_#000 for black or red_#5F1D18 for a red color tone.";
    case SupportedAttributeType.Size:
      return "Select the size of the product variant.";
    case SupportedAttributeType.Discount:
      return "Designation for the discount that is displayed as an attribute in the shop.";
    case SupportedAttributeType.TicketCategory:
      return "Designation for the ticket that is displayed as an attribute the shop.";
    default:
      return "";
  }
};
