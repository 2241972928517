import c from "classnames";
import {
  ChallengeFormDataState,
  defaultState,
} from "components/forms/ChallengeForm/ChallengeForm";
import { RulesForm } from "components/forms/RulesForm/RulesForm";
import Button from "components/ui/Button/Button";
import * as Dialog from "components/ui/Dialog/Dialog";
import { Icon } from "components/ui/Icon/Icon";
import { InputField } from "components/ui/Input/Input";
import { SelectField } from "components/ui/SelectField/SelectField";
import { toastContext } from "context/toastContext";
import { useFetchApi } from "hooks/useFetchApi";
import { useFormData } from "hooks/useFormData";
import { useSWRFetchAPIWithSchemaValidation } from "hooks/useSWRFetchAPI";
import { KeyedMutator } from "swr";
import { CHALLENGE_TYPE, RULE_CONFIG, RULE_TYPE } from "types/challenge.types";
import {
  AdminChallengeController_getChallengeById,
  AdminChallengeController_updateChallenge,
} from "types/schemas";
import { isChallengeWithRules } from "utils/isChallengeWithRules";
import { HttpError } from "utils/requests";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { FormWrapper } from "../FormWrapper/FormWrapper";
import s from "./ChallengeUpdateForm.module.scss";

interface UpdateChallengeProps {
  badgesData: {
    name: string;
    id: string;
  }[];
  keyedMutator: KeyedMutator<unknown>;
  id?: string;
}

export const updateFormFields = {
  name: true,
  slug: true,
  status: true,
  typeOfChallenge: true,
  badgeId: true,
  rules: true,
  tokens: true,
  startDate: true,
  endDate: true,
};

export function ChallengeUpdateForm({
  badgesData,
  keyedMutator,
  id,
}: UpdateChallengeProps) {
  const {
    data: SWRdata,
    isLoading,
    mutate,
  } = useSWRFetchAPIWithSchemaValidation(
    AdminChallengeController_getChallengeById,
    {
      parameters: {
        challengeId: id,
      },
      auth: true,
    }
  );

  const [rules, setRules] = useState<{ key: string; value: string }[]>([]);
  const { formData, handleChange, setFormData, handleDateChange } =
    useFormData(defaultState);
  const [initialSlug, setInitialSlug] = useState("");
  const { setToastErrorMessage, setToastSuccessMessage } =
    useContext(toastContext);
  const { fetching, fetchFromApiWithSchemaValidation } = useFetchApi();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<string>("");

  useEffect(() => {
    if (!isInitialized && SWRdata) {
      // Not part of the Form
      for (const field in SWRdata) {
        if (!updateFormFields[field]) delete SWRdata[field];
      }
      if (SWRdata?.rules) {
        const parsedRules = Object.keys(SWRdata?.rules).map((key) => ({
          key,
          value: SWRdata.rules[key] as string,
        }));

        setRules(parsedRules);
      }

      const copyData: Partial<any> = SWRdata;

      setFormData(copyData as ChallengeFormDataState);
      setInitialSlug(SWRdata.slug);
      setIsInitialized(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, isInitialized, SWRdata]);
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const newData = {
      ...formData,
      slug: initialSlug,
    };
    let parsedRules;
    if (rules?.length > 0 && isChallengeWithRules(newData.typeOfChallenge)) {
      parsedRules = rules.reduce((obj, item) => {
        if (item.key)
          obj[item.key] =
            RULE_CONFIG[item.key]?.type === "number" ? +item.value : item.value;
        return obj;
      }, {});
    }
    try {
      const response = await fetchFromApiWithSchemaValidation(
        AdminChallengeController_updateChallenge,
        {
          parameters: {
            challengeId: id,
            ...newData,
            tokens: newData.tokens ? +newData.tokens : undefined,
            useExistingContentfulEntry: true,
            rules: parsedRules,
          },
          auth: true,
        }
      );
      setToastSuccessMessage("Challenge Edited");
      mutate();
      setShowSuccess(response.id);
      keyedMutator();
    } catch (e: unknown) {
      if (e instanceof HttpError) {
        if (e.code === 500) {
          setToastErrorMessage(
            "Some of our systems seem to be unreachable, please try again later"
          );
        } else {
          setToastErrorMessage(e.message);
        }

        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setToastErrorMessage((e as any).message);
    }
  }

  if (showSuccess)
    return (
      <div className={s["success-screen"]}>
        <Icon name="success" className={s.check} inverted />
        <p className={s.label}>Success!</p>
        <p className={s.msg}>The Challenge was successfully updated</p>
        <Dialog.Close>
          <Button type="submit" className={s.btn}>
            Back to Dashboard
          </Button>
        </Dialog.Close>
      </div>
    );

  return (
    <>
      {SWRdata && isInitialized && (
        <FormWrapper onSubmit={handleSubmit} className={s.form}>
          <div className={s.scrollable}>
            <InputField
              title="Challenge Name"
              name="name"
              value={formData.name ?? ""}
              onChange={handleChange}
              className={s.input}
            />
            <SelectField
              title="Challenge Type"
              name="typeOfChallenge"
              placeholder="Select a challenge type..."
              value={formData.typeOfChallenge}
              onChange={(value) =>
                handleChange({
                  target: { value, name: "typeOfChallenge" },
                } as React.ChangeEvent<HTMLSelectElement>)
              }
              options={[
                { label: "Location", value: CHALLENGE_TYPE.LOCATION },
                { label: "App", value: CHALLENGE_TYPE.APP },
                { label: "Car", value: CHALLENGE_TYPE.CAR },
                { label: "Purchase", value: CHALLENGE_TYPE.PURCHASE },
              ]}
            />
            <SelectField
              title="Badge"
              name="badgeId"
              placeholder="Select a badge..."
              value={formData.badgeId}
              onChange={(value) =>
                handleChange({
                  target: { value, name: "badgeId" },
                } as React.ChangeEvent<HTMLSelectElement>)
              }
              options={badgesData.map((badge) => ({
                label: badge.name,
                value: badge.id,
              }))}
            />
            <InputField
              title="(Earned) Tokens"
              name="tokens"
              icon="porscheToken"
              value={formData.tokens ? formData.tokens : ""}
              onChange={handleChange}
              className={s.input}
            />
            {formData.startDate && (
              <InputField
                type="date"
                title="Start Date"
                name="startDate"
                description="Determines the time at which the challenge is launched and gets visible in the app."
                value={new Date(formData.startDate)}
                onChange={(
                  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  if (e.target.value) {
                    handleDateChange(e);
                  }
                }}
                className={c(s.input, {
                  [s["input-error"]]:
                    formData.endDate && formData.startDate >= formData.endDate,
                })}
                showInfo="This is a tooltip"
              />
            )}
            {formData.startDate &&
            formData.endDate &&
            formData.startDate >= formData.endDate ? (
              <p className={s["err-msg"]}>
                End date has to be after the start date.
              </p>
            ) : (
              <></>
            )}
            <InputField
              type="date"
              title="End Date"
              description="Determines the time at which the challenge ends and users can no longer fulfill it."
              name="endDate"
              value={formData.endDate ? new Date(formData.endDate) : undefined}
              withClear
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                if (e.target.value) {
                  handleDateChange(e);
                } else {
                  setFormData({ ...formData, endDate: undefined });
                }
              }}
              className={c(s.input, {
                [s["input-error"]]:
                  formData.endDate && formData.startDate >= formData.endDate,
              })}
              showInfo="This is a tooltip"
            />
            {isChallengeWithRules(formData.typeOfChallenge) ? (
              <RulesForm
                options={[
                  { label: "Car type/model", value: RULE_TYPE.CAR_OWNERSHIP },
                  {
                    label: "Amount of cars",
                    value: RULE_TYPE.CAR_MIN_OWNERSHIP_COUNT,
                  },
                  {
                    label: "Ownership in last X years",
                    value: RULE_TYPE.CAR_OWNERSHIP_IN_LAST_X_YEARS,
                  },
                  {
                    label: "Car purchase status (used/new)",
                    value: RULE_TYPE.CAR_PURCHASE_STATUS,
                  },
                  {
                    label: "Car ownership birthday",
                    value: RULE_TYPE.CAR_XTH_OWNERSHIP_BIRTHDAY,
                  },
                  {
                    label: "Accessory purchase",
                    value: RULE_TYPE.ACCESSORY_PURCHASE,
                  },
                ]}
                rules={rules}
                setRules={setRules}
              />
            ) : (
              <p className={s["no-rules-message"]}>
                No rules for this type of challenge
              </p>
            )}
          </div>

          <Button
            type="submit"
            loading={fetching}
            disabled={
              fetching ||
              ((rules.length === 0 ||
                (rules.some(
                  (rule) => rule.key === RULE_TYPE.ACCESSORY_PURCHASE
                ) &&
                  rules.length > 1) || // accessory purchase rule type can not be combined with other rules
                new Set(rules.flatMap((r) => r.key)).size !== rules.length || // rule types can only be included once per challenge
                (rules.length > 0 &&
                  rules.some((rule) => {
                    if (!rule.key) {
                      return true;
                    }
                    if (RULE_CONFIG[rule.key]?.specificStringValues) {
                      return !RULE_CONFIG[
                        rule.key
                      ].specificStringValues.includes(rule.value);
                    }
                    if (
                      RULE_CONFIG[rule.key]?.type === "string" &&
                      RULE_CONFIG[rule.key]?.maxLength
                    ) {
                      return (
                        rule.value.length <= 0 ||
                        rule.value.length > RULE_CONFIG[rule.key].maxLength
                      );
                    }
                    return rule.value === "";
                  }))) &&
                isChallengeWithRules(formData.typeOfChallenge)) ||
              (formData.endDate !== undefined &&
                formData.startDate >= formData.endDate)
            }
            className={s.btn}>
            Update
          </Button>
        </FormWrapper>
      )}
      {(!SWRdata || isLoading) && <p>Loading</p>}
    </>
  );
}
