import { ModalWrapper } from "components/modals/ModalWrapper/ModalWrapper";
import Button from "components/ui/Button/Button";
import { InputField } from "components/ui/Input/Input";
import { QRCodeSVG } from "qrcode.react";
import { useCallback, useState } from "react";
import s from "./QRCodeModal.module.scss";

interface QRCodeModalProps {
  id?: string;
  challengeName?: string;
}

export function QRCodeModal({ id, challengeName }: QRCodeModalProps) {
  const [width, setWidth] = useState("128");
  const [height, setHeight] = useState("128");

  const handleDownload = useCallback(() => {
    const qrCodeRef = document.getElementById("qr-code-svg");
    const svgData = new XMLSerializer().serializeToString(qrCodeRef as Node);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = +width;
      canvas.height = +height;
      ctx?.drawImage(img, 0, 0, +width, +height);
      const pngFile = canvas.toDataURL("image/png", 1);
      const downloadLink = document.createElement("a");
      downloadLink.download = challengeName ?? "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  }, [challengeName, height, width]);

  return (
    <ModalWrapper
      variant="flat"
      closeBtn
      className={s["modal-screen"]}
      title={`QR Code for Challenge "${challengeName}"`}>
      <div className={s["input-wrapper"]}>
        <InputField
          title="Image Width"
          type="number"
          value={width}
          onChange={(e) => setWidth(e.target.value)}
        />
        <InputField
          title="Image Height"
          type="number"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
        />
      </div>
      <div className={s.body}>
        <QRCodeSVG
          className={s.qr}
          id="qr-code-svg"
          value={`${process.env.REACT_APP_QR_CODE_SCHEMA}${id}`}
        />
        <Button onClick={handleDownload}>Download</Button>
      </div>
    </ModalWrapper>
  );
}
