import { AuthenticateResponseData, MeResponseData } from "apiDtos";
import { toastContext } from "context/toastContext";
import { useFetchApi } from "hooks/useFetchApi";
import jwtDecode from "jwt-decode";
import useLocalStorage from "use-local-storage";
import { isTokenExpired as _isTokenExpired, HttpError } from "utils/requests";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export interface UserData {
  email: string;
  name: string;
  id?: string;
}

interface IUserCtx {
  userData?: UserData | null;
  isAuthenticated?: boolean;
  setUser: (user: UserData) => void;
  clearUser: () => void;
  login: (authCode: string) => Promise<void>;
  logout: () => void;
}

export interface DecodedToken {
  exp: number;
  isAdmin: boolean;
  [key: string]: string | number | boolean;
}

export const userContext = createContext<IUserCtx>({} as IUserCtx);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [userData, setUserData] = useLocalStorage<
    IUserCtx["userData"] | undefined | null
  >("userData", undefined);
  const [token, setToken] = useLocalStorage<string | undefined | null>(
    "token",
    undefined
  );
  const [isAdmin, setIsAdmin] = useLocalStorage<boolean | undefined | null>(
    "isAdmin",
    undefined
  );
  const { setToastErrorMessage } = useContext(toastContext);
  const { fetchFromApi } = useFetchApi();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const tokenDecoded = useMemo(() => {
    if (token) {
      return jwtDecode<DecodedToken>(token);
    }
  }, [token]);

  const isTokenExpired = useMemo(
    () => _isTokenExpired(tokenDecoded),
    [tokenDecoded]
  );

  const isAuthenticated = useMemo(
    () => !!isAdmin && !isTokenExpired && !!userData,
    [isTokenExpired, userData, isAdmin]
  );

  const clearUser = useCallback(() => {
    setUserData(null);
    setIsAdmin(null);
  }, [setUserData]);

  const clearToken = useCallback(() => {
    setToken(null);
  }, [setToken]);

  const logout = useCallback(() => {
    clearUser();
    clearToken();
  }, [clearUser, clearToken]);

  const login = useCallback(
    async (authCode: string) => {
      if (!isAuthenticated) {
        try {
          const { jwt: tokenFromApi, isAdmin: isAdminFromApi } =
            await fetchFromApi<AuthenticateResponseData>("/auth/authenticate", {
              method: "POST",
              data: {
                code: authCode,
              },
            });

          setIsAdmin(isAdminFromApi);
          setToken(tokenFromApi);
        } catch (e) {
          if (e instanceof HttpError) {
            if (e.code === 403) {
              setToastErrorMessage("The User is not authorized to login");
            } else {
              setToastErrorMessage(e.message);
            }
          } else {
            setToastErrorMessage((e as Error).message);
          }
        } finally {
          searchParams.delete("code");
          setSearchParams(searchParams);
        }
      }
    },
    [
      isAuthenticated,
      fetchFromApi,
      setToken,
      setToastErrorMessage,
      searchParams,
      setSearchParams,
    ]
  );

  const requestUserData = useCallback(async () => {
    const respMe = await fetchFromApi<MeResponseData>("/admin/user/me", {
      method: "GET",
      auth: true,
    });
    setUserData({
      email: respMe.email,
      name: `${respMe.firstName} ${respMe.lastName}`,
      id: respMe.userId,
    });
    navigate("/admin");
  }, [fetchFromApi, navigate, setUserData]);

  useEffect(() => {
    // request user data if token is present (state update finished) and
    // valid but user data is not yet present i.e. user login is not yet
    // complete
    if (tokenDecoded && isAdmin === false) {
      setToastErrorMessage(
        "The User is not an admin. This site is only intended for admins."
      );
      clearToken();
    }
    if (isTokenExpired) {
      clearToken();
    }
    if (tokenDecoded && !isAuthenticated) {
      requestUserData();
    }
  }, [
    clearToken,
    isTokenExpired,
    isAuthenticated,
    requestUserData,
    setToastErrorMessage,
    isAdmin,
    tokenDecoded,
  ]);

  return (
    <userContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        userData,
        setUser: setUserData,
        clearUser,
      }}>
      {children}
    </userContext.Provider>
  );
}
