import crossIconBlack from "assets/icons/cross-icon-small-black.svg";
import crossIcon from "assets/icons/cross-icon-small.svg";
import { ModalWrapper } from "components/modals/ModalWrapper/ModalWrapper";
import Button from "components/ui/Button/Button";
import * as Dialog from "components/ui/Dialog/Dialog";
import { Icon } from "components/ui/Icon/Icon";
import { InputField } from "components/ui/Input/Input";
import { themeContext } from "context/themeContext";
import { toastContext } from "context/toastContext";
import { useFetchApi } from "hooks/useFetchApi";
import { useFormData } from "hooks/useFormData";
import { KeyedMutator } from "swr";
import { AdminBadgeController_create } from "types/schemas";
import { imagesToBase64 } from "utils/processing";
import { HttpError } from "utils/requests";
import React, { useContext, useEffect, useState } from "react";
import { FormWrapper } from "../FormWrapper/FormWrapper";
import s from "./AdminBadgesForm.module.scss";

interface AdminBadgeProps {
  mutate?: KeyedMutator<any>;
}

interface BadgeFormDataState {
  name: string;
  image: string;
  description: string;
}

const defaultState: BadgeFormDataState = {
  name: "",
  image: "",
  description: "",
};

export function AdminBadgesForm(props: AdminBadgeProps) {
  const { formData, handleChange, setFormData } = useFormData(defaultState);
  const { setToastErrorMessage, setToastSuccessMessage } =
    useContext(toastContext);
  const { fetching, fetchFromApiWithSchemaValidation } = useFetchApi();
  const [imagesData, setImagesData] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const { theme } = useContext(themeContext);

  function removeImage() {
    setImagesData("");
    setFormData((prev) => ({ ...prev, image: "" }));
  }

  async function handleImagesUpload(event: React.FormEvent<HTMLInputElement>) {
    const files: File[] = [];
    for (const image of event.currentTarget.files ?? []) {
      files.push(image);
    }

    // Reset Image Data
    setImagesData("");

    function onLoadHandler(e: ProgressEvent<FileReader>) {
      setImagesData(() => e.target?.result as string);
    }

    try {
      // Handles images in batch - if one fails, all fail
      await imagesToBase64(files, onLoadHandler);
    } catch (e) {
      setToastSuccessMessage("");
      setToastErrorMessage("Image upload failed");
    }
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      await fetchFromApiWithSchemaValidation(AdminBadgeController_create, {
        parameters: {
          name: formData.name,
          description: formData.description,
          fileBase64: imagesData,
        },
        auth: true,
      });

      setShowSuccess(true);
      setToastSuccessMessage("");
      props.mutate?.();
    } catch (e: unknown) {
      if (e instanceof HttpError) {
        if (e.code === 500) {
          setToastErrorMessage(
            "Some of our systems seem to be unreachable, please try again later"
          );
        } else {
          setToastErrorMessage(e.message);
        }

        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setToastErrorMessage((e as any).message);
    }
  }
  useEffect(() => {
    // Add images to form data
    setFormData((prev) => ({ ...prev, ["image"]: imagesData }));
  }, [imagesData, setFormData]);

  useEffect(() => {
    if (formData.name && formData.image && formData.description) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formData]);

  if (showSuccess)
    return (
      <ModalWrapper closeBtn>
        <div className={s["success-screen"]}>
          <Icon name="success" className={s.check} inverted />
          <p className={s.label}>Success!</p>
          <p className={s.msg}>The Badge was successfully created</p>
          <Dialog.Close>
            <Button type="submit" className={s.btn}>
              Back to Dashboard
            </Button>
          </Dialog.Close>
        </div>
      </ModalWrapper>
    );

  return (
    <ModalWrapper title="Create Badge" className={s["modal-screen"]} closeBtn>
      <FormWrapper onSubmit={handleSubmit} className={s.form1}>
        <div className={s.container}>
          <InputField
            title="Name"
            name="name"
            required
            value={formData.name ?? ""}
            onChange={handleChange}
            variant={theme === "dark" ? "light" : "dark"}
          />
          <InputField
            title="Description"
            name="description"
            description="Add a description for the badge."
            required
            value={formData.description ?? ""}
            onChange={handleChange}
            variant={theme === "dark" ? "light" : "dark"}
          />
          <div className={s["images-wrapper"]}>
            <div className={s["label-wrapper"]}>
              <label>Add Images</label>
              <p className={s.description}>Upload the image for the badge.</p>
            </div>
            <div className={s["images-container"]}>
              <div className={s["add-box"]}>
                <InputField
                  type="file"
                  onChange={handleImagesUpload}
                  className={s["files-input"]}
                  accept="image/png;image/jpeg;image/webp;image/jpg"
                />
              </div>

              {formData.image ? (
                <div className={s["image-box"]}>
                  <img src={imagesData} />
                  <img
                    className={s["close-icon"]}
                    src={theme === "dark" ? crossIcon : crossIconBlack}
                    onClick={removeImage}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Button
          type="submit"
          loading={fetching}
          disabled={fetching || isDisabled}
          className={s.btn}>
          Create Badge
        </Button>
      </FormWrapper>
    </ModalWrapper>
  );
}
