import infoIcon from "assets/icons/info-icon.svg";
import { IconName, icons } from "components/ui/Icon/Icon";
import { themeContext } from "context/themeContext";
import { useContext } from "react";
import ReactTooltip, { Place } from "react-tooltip";
import s from "./Tooltip.module.scss";

type ITooltipProps = {
  children?: React.ReactNode;
  id?: string;
  text: string;
  withIcon?: boolean;
  position?: Place;
  iconName?: IconName;
};

const Tooltip = ({
  text,
  withIcon,
  children,
  id,
  position,
  iconName,
}: ITooltipProps) => {
  const { theme } = useContext(themeContext);

  return (
    <div className={s["tooltip-wrapper"]}>
      <div data-tip={`${text}`} data-for={id} className={s["children"]}>
        {!withIcon ? (
          children
        ) : (
          <img
            src={iconName ? icons[iconName] : infoIcon}
            width={16}
            height={16}
            className={iconName ? s.icon : undefined}
          />
        )}
      </div>
      <ReactTooltip
        id={id}
        className={s.tooltip}
        arrowColor={theme === "dark" ? "white" : "black"}
        place={position ?? "right"}
      />
    </div>
  );
};
Tooltip.defaultProps = {
  text: "This is a tooltip",
  withIcon: true,
};
export default Tooltip;
