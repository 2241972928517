import { DashboardLayout } from "components/layouts/DashboardLayout/DashboardLayout";
import { LandingPage } from "components/views/LandingPage/LandingPage";
import { BadgesSection } from "components/views/protected/BadgesSection/BadgesSection";
import { BenefitsSection } from "components/views/protected/BenefitsSection/BenefitsSection";
import { ChallengesSection } from "components/views/protected/ChallengesSection/ChallengesSection";
import { OrdersSection } from "components/views/protected/OrdersSection/OrdersSection";
import { AdminProductsSection } from "components/views/protected/ProductsSection/ProductsSection";
import UsersSection from "components/views/protected/UsersSection/UsersSection";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { Protected, Unprotected } from "./Guards";

const routes: RouteObject[] = [
  {
    element: <Unprotected />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
    ],
  },
  {
    element: <Protected />,
    children: [
      {
        path: "admin",
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="products" replace />,
          },
          // {
          //   path: "stats",
          //   element: <StatsSection />,
          // },
          {
            path: "products",
            element: <AdminProductsSection />,
          },
          {
            path: "user-management",
            element: <UsersSection />,
          },
          {
            path: "badges",
            element: <BadgesSection />,
          },
          {
            path: "challenges",
            element: <ChallengesSection />,
          },
          {
            path: "benefits",
            element: <BenefitsSection />,
          },
          {
            path: "orders",
            element: <OrdersSection />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

export function Router() {
  const routing = useRoutes(routes);

  return routing;
}
