import { MeResponseData } from "apiDtos";
import ButtonLink from "components/ui/ButtonLink";
import { userContext } from "context/userContext";
import { useSWRFetchAPI } from "hooks/useSWRFetchAPI";
import { getOAuthUri } from "utils/getOAuthLink";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import s from "./LandingPage.module.scss";

export function LandingPage() {
  const { isAuthenticated } = useContext(userContext);
  useSWRFetchAPI<MeResponseData>("/admin/user/me", {
    method: "GET",
    auth: true,
  });

  if (isAuthenticated) {
    return <Navigate to="/admin" replace />;
  }

  return (
    <div className={s.welcome}>
      <div className={s.title}>Porsche Privilege Admin Area</div>
      <ButtonLink className={s["login-button"]} href={getOAuthUri()}>
        LOGIN
      </ButtonLink>
    </div>
  );
}
