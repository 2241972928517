import { userContext } from "context/userContext";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useOAuthRedirectListener = () => {
  // Parse the URL query string for code using React Router
  const [searchParams] = useSearchParams();
  const [authCode, setAuthCode] = useState(searchParams.get("code"));
  const { login } = useContext(userContext);

  // Update the state variable when the query parameter changes
  useEffect(() => {
    const newSearchParam = searchParams.get("code");
    if (newSearchParam && newSearchParam !== authCode) {
      setAuthCode(newSearchParam);
    }
  }, [searchParams, authCode]);

  useEffect(() => {
    const performRequest = async () => {
      if (authCode && authCode !== "") {
        await login(authCode);
      }
    };
    performRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode]);
};
