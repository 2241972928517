import useSWR from "swr";
import { KeyedMutator, PublicConfiguration } from "swr/dist/types";
import { ApiRequest } from "types/schemas";
import { constructApiRequest } from "utils/constructApiRequest";
import { fetchApi, FetchApiOptions, HttpError } from "utils/requests";
import { z } from "zod";

const internalFetcher = (...args: [string, FetchApiOptions]) =>
  fetchApi(...args).then((res) => res);

const fetcher = async (...args: [string, FetchApiOptions]) => {
  const response = await fetch(...args);

  if (!response.ok) {
    throw new HttpError(response.status, "fetch response with error status");
  }
  const result = await response.json();
  return result;
};

interface ApiResponse<T> {
  data: T;
  isLoading: boolean;
  error: any;
  mutate: KeyedMutator<any>;
  userInfo?: any;
  isValidating: boolean;
}

export function useSWRFetchAPI<T>(
  endpoint: string,
  args: FetchApiOptions,
  opts?: PublicConfiguration,
  isInternalCall = true
): ApiResponse<T> {
  const { data, error, mutate, isValidating } = useSWR(
    [endpoint, args],
    isInternalCall ? internalFetcher : fetcher,
    opts
  );

  return {
    data: data as T,
    isLoading: !error && !data,
    error,
    mutate,
    isValidating,
  };
}
interface Opts {
  parameters?: {
    [key: string]: any;
  };
  auth: boolean;
  shouldFetch?: boolean;
}

export function useSWRFetchAPIWithSchemaValidation<
  ZodResponseSchema extends z.ZodTypeAny,
>(
  request: ApiRequest<ZodResponseSchema>,
  { auth = false, shouldFetch = true, parameters }: Opts,
  onSuccess?: (data: any) => unknown
): ApiResponse<z.infer<ZodResponseSchema>> {
  let constructedApiRequest;

  if (shouldFetch) {
    constructedApiRequest = constructApiRequest<z.infer<ZodResponseSchema>>(
      request,
      auth,
      parameters
    );
  }

  const { data, error, mutate, isValidating } = useSWR(
    constructedApiRequest,
    internalFetcher,
    onSuccess
      ? {
          onSuccess,
        }
      : undefined
  );

  return {
    data: data as z.infer<ZodResponseSchema>,
    isLoading: !error && !data,
    error,
    mutate,
    isValidating,
  };
}
