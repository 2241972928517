import Button from "components/ui/Button/Button";
import { Icon } from "components/ui/Icon/Icon";
import { InputField } from "components/ui/Input/Input";
import { SelectField } from "components/ui/SelectField/SelectField";
import { Dispatch, Fragment } from "react";
import { RULE_CONFIG } from "../../../types/challenge.types";
import s from "./RulesForm.module.scss";

interface RulesFormProps {
  rules: { key: string; value: string }[];
  setRules: Dispatch<
    React.SetStateAction<
      {
        key: string;
        value: string;
      }[]
    >
  >;
  options: { label: string; value: string }[];
}

export function RulesForm({ rules, options, setRules }: RulesFormProps) {
  const handleAdd = () => {
    if (rules.length >= 1) {
      setRules([...rules, { key: "", value: "" }]);
    } else {
      setRules([{ key: "", value: "" }]);
    }
  };

  const handleRemove = (index: number) => {
    const list = [...rules];
    list.splice(index, 1);
    setRules(list);
  };

  const handleChange = (value: string, name: string, index: number) => {
    const list = [...rules];
    list[index][name] = value;
    if (name === "key") {
      list[index].value = "";
    }
    setRules(list);
  };

  return (
    <>
      <div className={s["list-container"]}>
        {rules.map((item, index) => (
          <Fragment key={index}>
            <SelectField
              name="key"
              required
              title={index === 0 ? "Rule Name" : undefined}
              showInfo={
                index === 0
                  ? "The category of the rule that determines if a benefit is applicable to a user"
                  : undefined
              }
              value={item.key}
              onChange={(value) => handleChange(value, "key", index)}
              options={options}
            />
            <InputField
              name="value"
              required
              title={index === 0 ? "Rule Value" : undefined}
              showInfo={
                index === 0
                  ? "The actual rule value that determines if a benefit is applicable to a user"
                  : undefined
              }
              value={item.value}
              type={
                RULE_CONFIG[item.key]?.type === "number" ? "number" : "name"
              }
              onChange={(e) => handleChange(e.target.value, "value", index)}
            />
            {rules.length >= 1 && (
              <Button
                className={s["remove-button"]}
                type="button"
                onClick={() => handleRemove(index)}>
                –
              </Button>
            )}
          </Fragment>
        ))}
      </div>
      <Button type="button" onClick={handleAdd} className={s["add-btn"]}>
        <Icon name="add" style={{ height: "1em" }} />
        <span>Add Rule</span>
      </Button>
    </>
  );
}
