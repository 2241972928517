import c from "classnames";
import s from "./Card.module.scss";

interface ICardsProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
  as?: React.ElementType;
}

export function Card({
  children,
  as = "div",
  className,
  ...props
}: ICardsProps) {
  const Tag = as;
  return (
    <Tag className={c(s.card, className)} {...props}>
      {children}
    </Tag>
  );
}
