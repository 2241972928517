import c from "classnames";
import { BenefitUpdateForm } from "components/forms/BenefitUpdateForm/BenefitUpateForm";
import { DeleteFormModal } from "components/modals/ConfirmDeletePopup/ConfirmDeletePopup";
import { ModalWrapper } from "components/modals/ModalWrapper/ModalWrapper";
import Button from "components/ui/Button/Button";
import * as Dialog from "components/ui/Dialog/Dialog";
import { Icon } from "components/ui/Icon/Icon";
import { StatusDropDown } from "components/views/protected/ProductsSection/StatusDropDown";
import { themeContext } from "context/themeContext";
import { toastContext } from "context/toastContext";
import { useFetchApi } from "hooks/useFetchApi";
import { KeyedMutator } from "swr";
import { BENEFIT_STATUS } from "types/benefits.types";
import {
  AdminBenefitController_deleteBenefit,
  AdminBenefitController_updateStatus,
} from "types/schemas";
import { HttpError } from "utils/requests";
import { useContext, useState } from "react";
import ReactTooltip from "react-tooltip";
import s from "./ExpandedBenefitRow.module.scss";

interface IExpandedBenefitRowProps {
  id: string;
  name: string;
  status: BENEFIT_STATUS;
  entryUrl: string;
  mutate: KeyedMutator<any>;
  closeExpanded: () => void;
}

// Displays the products info inside the Admin products section
export function ExpandedBenefitRow({
  id,
  name,
  status,
  entryUrl,
  mutate,
  closeExpanded,
}: IExpandedBenefitRowProps) {
  const [showStatusDropdown, setShowStatusDropdown] = useState<boolean>(false);
  const { theme } = useContext(themeContext);
  const { setToastErrorMessage, setToastSuccessMessage } =
    useContext(toastContext);
  const { fetchFromApiWithSchemaValidation } = useFetchApi();
  function showStatusMenu() {
    setShowStatusDropdown(!showStatusDropdown);
  }

  return (
    <>
      <div className={s["offer-wrapper"]}>
        <div className={s["info-container"]}>
          <>
            <div className={s["desc-div"]}>
              <p className={s.name}>{name}</p>
            </div>
          </>
          <div className={s["nav-div"]}>
            <div className={s["button-wrapper"]}>
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <Button
                    variant="ghost"
                    disabled={status === BENEFIT_STATUS.ACTIVE}
                    className={s.button}
                    data-tip={
                      status === BENEFIT_STATUS.ACTIVE
                        ? "Editing challenges is not permitted when they are in 'active' state. Please change the status to 'paused' first."
                        : undefined
                    }
                    id={id}>
                    Edit Benefit
                  </Button>
                </Dialog.Trigger>
                <ModalWrapper
                  title="Edit Benefit"
                  closeBtn
                  className={s["modal-screen"]}>
                  <BenefitUpdateForm keyedMutator={mutate} id={id} />
                </ModalWrapper>
              </Dialog.Root>
              {status === BENEFIT_STATUS.ACTIVE && (
                <ReactTooltip
                  className={s.tooltip}
                  arrowColor={theme === "dark" ? "white" : "black"}
                  place="right"
                />
              )}
            </div>

            <Button asChild variant="ghost">
              <a href={entryUrl} target="_blank" rel="noreferrer">
                <Icon
                  name="contentful"
                  style={{ height: "1em", marginInlineEnd: "0.2em" }}
                />
                Edit Entry in Contentful
              </a>
            </Button>
            <Button
              variant="ghost"
              onClick={showStatusMenu}
              className={s["status-label"]}>
              <span>Change Status</span>
              <Icon
                name="expand"
                className={c(
                  s["expand-icon"],
                  showStatusDropdown && s.expanded
                )}
              />
              {showStatusDropdown && (
                <StatusDropDown
                  handleSubmit={async (statusToSet) => {
                    try {
                      if (statusToSet === status)
                        return setToastErrorMessage(
                          `Already ${
                            status === BENEFIT_STATUS.ACTIVE
                              ? "Active"
                              : "Inactive"
                          }`
                        );

                      await fetchFromApiWithSchemaValidation(
                        AdminBenefitController_updateStatus,
                        {
                          auth: true,
                          parameters: {
                            benefitId: id,
                            status: statusToSet,
                          },
                        }
                      );

                      setShowStatusDropdown(false);
                      mutate?.();
                    } catch (e: unknown) {
                      if (e instanceof HttpError) {
                        if (e.code === 500) {
                          setToastErrorMessage(
                            "Some of our systems seem to be unreachable, please try again later"
                          );
                        } else {
                          setToastErrorMessage(e.message);
                        }

                        return;
                      }
                      setToastErrorMessage(
                        "The entered data seems to be malformed"
                      );
                    }
                  }}
                />
              )}
            </Button>

            <Dialog.Root>
              <Dialog.Trigger asChild>
                <Button color="danger" variant="ghost">
                  Delete
                </Button>
              </Dialog.Trigger>
              <DeleteFormModal
                label="Delete Benefit?"
                desc="Deleting the benefit will remove it from the Admin Panel and the App. It would not be available for claiming anymore."
                handleSubmit={async (event) => {
                  event.preventDefault();

                  try {
                    await fetchFromApiWithSchemaValidation(
                      AdminBenefitController_deleteBenefit,
                      {
                        parameters: {
                          benefitId: id,
                        },
                        auth: true,
                      }
                    );
                    setToastSuccessMessage("Delete successful");
                    closeExpanded();
                    mutate();
                  } catch (e: unknown) {
                    if (e instanceof HttpError) {
                      if (e.code === 500) {
                        setToastErrorMessage(
                          "Some of our systems seem to be unreachable, please try again later"
                        );
                      } else {
                        setToastErrorMessage(e.message);
                      }

                      return;
                    }
                    setToastErrorMessage(
                      "The entered data seems to be malformed"
                    );
                  }
                }}
              />
            </Dialog.Root>
          </div>
        </div>
      </div>
    </>
  );
}
