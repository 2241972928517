import { Icon } from "components/ui/Icon/Icon";
import * as Tabs from "components/ui/Tabs/Tabs";
import { themeContext } from "context/themeContext";
import { AdminRoute, adminRoutesMetadata } from "routes/adminRoutesMetadata";
import { useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import s from "./DashboardLayout.module.scss";

export function DashboardLayout() {
  const [tabActive, setTabActive] = useState<AdminRoute>("products");
  const { theme } = useContext(themeContext);
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/admin/products":
        setTabActive("products");
        break;
      // case "/admin/stats":
      //   setTabActive("stats");
      //   break;
      case "/admin/user-management":
        setTabActive("user-management");
        break;
      case "/admin/badges":
        setTabActive("badges");
        break;
      case "/admin/challenges":
        setTabActive("challenges");
        break;
      case "/admin/benefits":
        setTabActive("benefits");
        break;
      case "/admin/orders":
        setTabActive("orders");
        break;
      default:
        setTabActive("products");
        break;
    }
  }, [location]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    // clean up event listener
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <Tabs.Root defaultValue="account" value={tabActive} className={s.layout}>
      <Tabs.List className={s["nav-container"]}>
        {Object.entries(adminRoutesMetadata).map(([to, { icon, navTitle }]) => (
          <Tabs.Trigger key={to} value={to}>
            <Link to={to} className={s["link-button"]}>
              <Icon className={s["tab-icon"]} name={icon} />
              <span>{navTitle}</span>
            </Link>
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      <div className={s["admin-wrapper"]} data-theme={theme}>
        <div className={s["dashboard-container"]}>
          <Tabs.Content value={tabActive} className={s["body-container"]}>
            <Outlet />
          </Tabs.Content>
        </div>
      </div>
    </Tabs.Root>
  );
}
