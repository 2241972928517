import c from "classnames";
import { themeContext } from "context/themeContext";
import { useContext } from "react";
import { Link } from "react-router-dom";
import s from "./HeaderNavLink.module.scss";

interface INavLinkProps {
  to?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  children: React.ReactNode;
  className?: string;
  inactive?: boolean;
}

export function HeaderNavLink(props: INavLinkProps) {
  const { theme } = useContext(themeContext);
  const contentEl = (
    <div className={c(s["nav-label"], props.className)} data-theme={theme}>
      <div className={s["nav-text"]}>{props.children}</div>
    </div>
  );

  return props.to ? (
    <Link to={props.to}>{contentEl}</Link>
  ) : (
    <nav
      className={c(s["nav-label"], props.className)}
      data-theme={theme}
      onClick={props.onClick}>
      {contentEl}
    </nav>
  );
}
