import s from "./DashboardMainHeader.module.scss";

interface IDashboardMainHeaderProps {
  title: React.ReactNode;
  description?: string;
  className?: string;
}

export function DashboardMainHeader({
  title,
  description,
  className,
}: IDashboardMainHeaderProps) {
  return (
    <div className={className}>
      <h2 className={s.title}>{title}</h2>
      <p className={s.description}>{description}</p>
    </div>
  );
}
