import { ApiRequest } from "types/schemas";
import { constructApiRequest } from "utils/constructApiRequest";
import { fetchApi, FetchApiOptions } from "utils/requests";
import { z } from "zod";
import { useCallback, useState } from "react";

interface IReturn {
  fetching: boolean;
  fetchFromApi: <ResponseType = any>(
    endpoint: string,
    opts: FetchApiOptions
  ) => Promise<ResponseType>;
  fetchFromApiWithSchemaValidation: <ZodResponseSchema extends z.ZodTypeAny>(
    request: ApiRequest<ZodResponseSchema>,
    opts: {
      parameters: {
        [key: string]: any;
      };
      auth: boolean;
    }
  ) => Promise<z.infer<ZodResponseSchema>>;
}

export function useFetchApi(): IReturn {
  const [fetching, setFetching] = useState<boolean>(false);

  const fetchFromApi = useCallback(
    async <ResponseType>(endpoint: string, opts: FetchApiOptions) => {
      setFetching(true);
      try {
        const result = await fetchApi<ResponseType>(endpoint, opts);
        setFetching(false);
        return result;
      } catch (e) {
        setFetching(false);
        throw e;
      }
    },
    []
  );

  const fetchFromApiWithSchemaValidation = useCallback(
    async <ZodResponseSchema extends z.ZodTypeAny>(
      request: ApiRequest<ZodResponseSchema>,
      opts: {
        parameters: {
          [key: string]: unknown;
        };
        auth: boolean;
      }
    ) => {
      setFetching(true);
      try {
        const constructedApiRequest = constructApiRequest(
          request,
          opts.auth,
          opts.parameters
        );
        const result = await fetchApi<z.infer<ZodResponseSchema>>(
          ...constructedApiRequest
        );
        setFetching(false);
        return result;
      } catch (e) {
        setFetching(false);
        throw e;
      }
    },
    []
  );

  return { fetching, fetchFromApi, fetchFromApiWithSchemaValidation };
}
