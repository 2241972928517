import { Row } from "@tanstack/react-table";
import { BenefitForm } from "components/forms/BenefitForm/BenefitForm";
import { ModalWrapper } from "components/modals/ModalWrapper/ModalWrapper";
import { BenefitsCols } from "components/tables/ReactTable/BenefitsColumn";
import DataTable from "components/tables/ReactTable/DataTable";
import Button from "components/ui/Button/Button";
import { Card } from "components/ui/Card/Card";
import { DashboardMainHeader } from "components/ui/DashboardMainHeader/DashboardMainHeader";
import * as Dialog from "components/ui/Dialog/Dialog";
import { Icon } from "components/ui/Icon/Icon";
import { InputField } from "components/ui/Input/Input";
import { ExpandedBenefitRow } from "components/views/protected/BenefitsSection/ExpandedBenefitRow";
import { themeContext } from "context/themeContext";
import { useSWRFetchAPIWithSchemaValidation } from "hooks/useSWRFetchAPI";
import { adminRoutesMetadata } from "routes/adminRoutesMetadata";
import { BENEFIT_STATUS } from "types/benefits.types";
import {
  AdminBenefitController_getAllNonDeletedBenefitsWithCmsData,
  BenefitDto,
} from "types/schemas";
import { z } from "zod";
import { ChangeEvent, useCallback, useContext, useMemo, useState } from "react";
import s from "./BenefitsSection.module.scss";

const { title, description } = adminRoutesMetadata.benefits;

export function BenefitsSection() {
  const { data, isLoading, error, mutate } = useSWRFetchAPIWithSchemaValidation(
    AdminBenefitController_getAllNonDeletedBenefitsWithCmsData,
    {
      parameters: {}, // TODO: add admin filters
      auth: true,
    }
  );

  const [searchValue, setSearchValue] = useState<string>();
  const { theme } = useContext(themeContext);

  // Table data
  const tableCols = useMemo(() => BenefitsCols, []);
  // Shows the dropdown info section for an item
  const renderExpandedInfo = useCallback(
    ({ row }: { row: Row<z.infer<typeof BenefitDto>> }) => {
      const rowValues = {
        id: row.getValue("id") as string,
        name: row.getValue("name") as string,
        status: row.getValue("status") as BENEFIT_STATUS,
        entryUrl: row.getValue("entryUrl") as string,
      };

      return (
        <ExpandedBenefitRow
          id={rowValues.id}
          name={rowValues.name}
          status={rowValues.status}
          entryUrl={rowValues.entryUrl}
          mutate={mutate}
          closeExpanded={() => row.toggleExpanded(false)}
        />
      );
    },
    [mutate]
  );

  function handleSearchInput(e: ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
  }

  if (isLoading) return <h6>Loading...</h6>;
  if (error) return <h2>Error Occured</h2>;

  return (
    <>
      <DashboardMainHeader title={title} description={description} />
      <Card as="section" className={s["section-wrapper"]}>
        <div className={s["section-nav"]}>
          <div className={s["search-wrapper"]}>
            <InputField
              onChange={handleSearchInput}
              label="Search across all attributes"
              className={s["search-field"]}
              type="search"
            />
          </div>
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <Button size="large">
                <Icon style={{ height: "1em" }} name="add" />
                <span>Create Benefit</span>
              </Button>
            </Dialog.Trigger>
            <ModalWrapper
              title="Create Benefit"
              className={s["modal-screen"]}
              closeBtn>
              <BenefitForm mutate={mutate} />
            </ModalWrapper>
          </Dialog.Root>
        </div>
        <DataTable
          columns={tableCols(theme === "dark" ? "dark" : "light")}
          data={data}
          expandedComponent={renderExpandedInfo}
          tableClass="table-offers"
          filterValue={{ key: "name", value: searchValue }}
        />
      </Card>
    </>
  );
}
