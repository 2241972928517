import c from "classnames";
import s from "./StepIndicator.module.scss";

export interface StepIndicatorProps {
  steps: string[];
  currentStep: number;
  className?: string;
}

export function StepIndicator({
  steps,
  currentStep,
  className,
}: StepIndicatorProps) {
  return (
    <ol role="list" className={c(s.root, className)}>
      {steps.map((step, index) => (
        <li
          key={step}
          className={c(s.step, {
            [s.active]: index === currentStep,
            [s.done]: index < currentStep,
          })}>
          <span className={s["step-number"]}>Step {index + 1}</span>
          <span className={s["step-label"]}>{step}</span>
        </li>
      ))}
    </ol>
  );
}
