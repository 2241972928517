import * as DialogPrimitive from "@radix-ui/react-dialog";
import c from "classnames";
import { forwardRef } from "react";
import s from "./Dialog.module.scss";

type DialogContentElement = React.ElementRef<typeof DialogPrimitive.Content>;

type DialogContentProps = React.ComponentPropsWithoutRef<
  typeof DialogPrimitive.Content
>;

const DialogContent = forwardRef<DialogContentElement, DialogContentProps>(
  ({ className, ...rootProps }, forwardedRef) => (
    <DialogPrimitive.Content
      {...rootProps}
      ref={forwardedRef}
      className={c(s.content, className)}
    />
  )
);
DialogContent.displayName = "Dialog.Content";

const DialogTitle = forwardRef<DialogContentElement, DialogContentProps>(
  ({ className, ...rootProps }, forwardedRef) => (
    <DialogPrimitive.Title
      {...rootProps}
      ref={forwardedRef}
      className={c(s.title, className)}
    />
  )
);
DialogTitle.displayName = "Dialog.Title";

export { DialogContent as Content, DialogTitle as Title };
export const { Root, Trigger, Portal, Close } = DialogPrimitive;
export type { DialogTriggerProps, PortalProps } from "@radix-ui/react-dialog";
