//3 TanStack Libraries!!!
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  InitialTableState,
  OnChangeFn,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import Button from "components/ui/Button/Button";
import React from "react";
import s from "./InfiniteTable.module.scss";

interface InfiniteTableProps {
  columns: ColumnDef<any>[];
  data: any[];
  expandedComponent?: Function;
  isFetching: boolean;
  hasLastKey?: boolean;
  initialState?: InitialTableState;
  fetchNextPage: () => void;
  sorting?: SortingState;
  setSorting?: React.Dispatch<React.SetStateAction<SortingState>>;
  manualSorting?: boolean;
}

export const InfiniteTable = ({
  columns,
  data,
  hasLastKey,
  fetchNextPage,
  initialState,
  expandedComponent,
  isFetching,
  sorting,
  setSorting,
  manualSorting,
}: InfiniteTableProps) => {
  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const table = useReactTable({
    columns: columns,
    data,
    initialState,
    state: manualSorting
      ? {
          sorting,
        }
      : undefined,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting,
  });

  if (manualSorting) {
    const handleSortingChange: OnChangeFn<SortingState> = (updater) => {
      setSorting?.(updater);
    };
    table.setOptions((prev) => ({
      ...prev,
      onSortingChange: handleSortingChange,
    }));
  }

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  return (
    <>
      <div
        className={s.container}
        ref={tableContainerRef}
        style={{
          overflow: "auto", //our scrollable table container
          position: "relative", //needed for sticky header
          height: "600px", //should be a fixed height
        }}>
        {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup, index) => (
              <tr key={index}>
                {headerGroup.headers.map((header, index) => {
                  const isExpander = header.id === "expander";
                  let className = "";
                  if (header.column.getIsSorted()) {
                    className =
                      header.column.getIsSorted() === "desc"
                        ? "sort-desc"
                        : "sort-asc";
                  }

                  return (
                    <th
                      key={index}
                      onClick={header.column.getToggleSortingHandler()}
                      className={
                        !isExpander ? className : s["header-expander"]
                      }>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            style={{
              overflow: "scroll",
            }}>
            {table.getRowModel().rows.map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <>
                  <tr
                    data-index={virtualRow.index} //needed for dynamic row height measurement
                    ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                    key={row.id}
                    onClick={() => {
                      if (expandedComponent)
                        row.toggleExpanded(!row.getIsExpanded());
                    }}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && expandedComponent && (
                    <tr
                      style={{
                        zIndex: 100,
                      }}
                      className={s.expanded}>
                      <td colSpan={row.getVisibleCells().length}>
                        {expandedComponent?.({ row })}
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
        <Button disabled={!hasLastKey} onClick={() => fetchNextPage()}>
          {!hasLastKey ? "No more records" : "Fetch More"}
        </Button>
      </div>

      {isFetching && <div>Fetching More...</div>}
    </>
  );
};
