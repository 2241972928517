import { DeleteFormModal } from "components/modals/ConfirmDeletePopup/ConfirmDeletePopup";
import { ModalWrapper } from "components/modals/ModalWrapper/ModalWrapper";
import Button from "components/ui/Button/Button";
import * as Dialog from "components/ui/Dialog/Dialog";
import { toastContext } from "context/toastContext";
import { useFetchApi } from "hooks/useFetchApi";
import { useSWRFetchAPIWithSchemaValidation } from "hooks/useSWRFetchAPI";
import {
  SettingsController_getCronJobs,
  SettingsController_getOperationalStatus,
  SettingsController_resumeOperation,
  SettingsController_suspendOperation,
} from "types/schemas";
import { HttpError } from "utils/requests";
import { useContext, useState } from "react";
import s from "./HeaderAppSettings.module.scss";

export const HeaderAppSettings = () => {
  const { data: isOperational, mutate } = useSWRFetchAPIWithSchemaValidation(
    SettingsController_getOperationalStatus,
    {
      auth: true,
    }
  );

  const { data: cronJobs } = useSWRFetchAPIWithSchemaValidation(
    SettingsController_getCronJobs,
    {
      auth: true,
    }
  );

  const { fetchFromApiWithSchemaValidation } = useFetchApi();

  const [isOperationsPopupOpen, setIsOperationsPopupOpen] = useState(false);
  const { setToastSuccessMessage, setToastErrorMessage } =
    useContext(toastContext);

  return (
    <>
      <p className={s["operational-status"]}>
        Operational Status:{" "}
        <span className={isOperational ? s.operational : s["not-operational"]}>
          {isOperational ? "Operational" : "Not Operational"}
        </span>
      </p>
      <div className={s["separator"]} />
      <Button
        color="danger"
        className={s.delete}
        onClick={() => setIsOperationsPopupOpen(true)}>
        {isOperational ? "Stop Operations" : "Start Operations"}
      </Button>
      <Dialog.Root
        open={isOperationsPopupOpen}
        onOpenChange={() => setIsOperationsPopupOpen(!isOperationsPopupOpen)}>
        <DeleteFormModal
          label={isOperational ? "Stop Operations" : "Start Operations"}
          iconName={isOperational ? "expired" : "active"}
          desc="If you are not sure regarding this functionality, don't execute!"
          buttonText={isOperational ? "Stop Operations" : "Start Operations"}
          handleSubmit={async (event) => {
            event.preventDefault();
            try {
              if (isOperational) {
                await fetchFromApiWithSchemaValidation(
                  SettingsController_suspendOperation,
                  {
                    auth: true,
                    parameters: {},
                  }
                );
              } else {
                await fetchFromApiWithSchemaValidation(
                  SettingsController_resumeOperation,
                  {
                    auth: true,
                    parameters: {},
                  }
                );
              }

              setToastSuccessMessage(
                isOperational ? "Suspended Operation" : "Resumed Operation"
              );
              mutate();
              setIsOperationsPopupOpen(false);
            } catch (e: unknown) {
              if (e instanceof HttpError) {
                setToastErrorMessage(e.message);
                return;
              }
              setToastErrorMessage("Something went wrong");
            }
          }}
        />
      </Dialog.Root>
      <div className={s["separator"]} />
      <Dialog.Root>
        <Dialog.Trigger>
          <Button className={s.delete}>Cron Jobs Status</Button>
        </Dialog.Trigger>
        <ModalWrapper closeBtn>
          {cronJobs?.map((cron) => (
            <div key={cron.name}>
              <p>Name: {cron.name}</p>
              <p>Cron: {cron.cron}</p>
              <p>Next: {cron.next}</p>
            </div>
          ))}
        </ModalWrapper>
      </Dialog.Root>
      <div className={s["separator"]} />
    </>
  );
};
