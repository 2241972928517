import { AdminBadgesForm } from "components/forms/AchievementsForm/AdminBadgesForm";
import { BadgesCols } from "components/tables/ReactTable/BadgesColumn";
import DataTable from "components/tables/ReactTable/DataTable";
import Button from "components/ui/Button/Button";
import { Card } from "components/ui/Card/Card";
import { DashboardMainHeader } from "components/ui/DashboardMainHeader/DashboardMainHeader";
import * as Dialog from "components/ui/Dialog/Dialog";
import { Icon } from "components/ui/Icon/Icon";
import { InputField } from "components/ui/Input/Input";
import { useSWRFetchAPIWithSchemaValidation } from "hooks/useSWRFetchAPI";
import { adminRoutesMetadata } from "routes/adminRoutesMetadata";
import { AdminBadgeController_getAll } from "types/schemas";
import { ChangeEvent, useState } from "react";
import s from "./BadgesSection.module.scss";

const { title, description } = adminRoutesMetadata.badges;

export function BadgesSection() {
  const { data, isLoading, error, mutate } = useSWRFetchAPIWithSchemaValidation(
    AdminBadgeController_getAll,
    {
      parameters: {
        contractAddress: process.env
          .REACT_APP_ACHIEVEMENT_TOKEN_CONTRACT_ADDRESS as string,
      },
      auth: true,
    }
  );
  useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>();

  function handleSearchInput(e: ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
  }

  if (isLoading) return <h6>Loading...</h6>;
  if (error) return <h2>Error Occured</h2>;

  return (
    <>
      <DashboardMainHeader title={title} description={description} />
      <Card as="section" className={s["section-wrapper"]}>
        <div className={s["section-nav"]}>
          <div className={s["search-wrapper"]}>
            <InputField
              onChange={handleSearchInput}
              label="Search across all attributes"
              className={s["search-field"]}
              type="search"
            />
          </div>
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <Button size="large">
                <Icon style={{ height: "1em" }} name="add" />
                <span>Create Badge</span>
              </Button>
            </Dialog.Trigger>

            <AdminBadgesForm mutate={mutate} />
          </Dialog.Root>
        </div>
        <DataTable
          columns={BadgesCols()}
          data={data}
          tableClass="table-soulbound"
          filterValue={{ key: "title", value: searchValue }}
        />
      </Card>
    </>
  );
}
