import { IconName } from "components/ui/Icon/Icon";

export interface AdminRouteMetadata {
  title: string;
  navTitle: string;
  icon: IconName;
  description: string;
}

export type AdminRoute =
  | "products"
  // | "stats"
  | "user-management"
  | "badges"
  | "challenges"
  | "benefits"
  | "orders";

export const adminRoutesMetadata: Record<AdminRoute, AdminRouteMetadata> = {
  // stats: {
  //   title: "Analytics",
  //   navTitle: "Analytics",
  //   icon: "analytics",
  //   description:
  //     "View analytics for the program including user activity, engagement, and more.",
  // },
  products: {
    title: "Products",
    navTitle: "Products",
    icon: "product",
    description:
      "Manage events, physical items and vouchers that users can redeem points for.",
  },
  "user-management": {
    title: "Users",
    navTitle: "Users",
    icon: "user",
    description:
      "Manage users in the program and view their enrollment status incl. the date they joined and accepted the Terms and Conditions.",
  },
  badges: {
    title: "Badges",
    navTitle: "Badges",
    icon: "badge",
    description:
      "Manage badges, which are awarded to users for completing challenges.",
  },
  challenges: {
    title: "Challenges",
    navTitle: "Challenges",
    icon: "challenge",
    description:
      "Manage tasks which users can complete to earn Porsche tokens.",
  },
  benefits: {
    title: "Benefits",
    navTitle: "Benefits",
    icon: "benefit",
    description: "Manage program benefits like discounts and special offerings",
  },
  orders: {
    title: "Orders",
    navTitle: "Orders",
    icon: "orders",
    description: "Manage program orders",
  },
};
