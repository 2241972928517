export interface VariantValues {
  value: string;
  quantity: number;
}

export interface VariantsDefaultState {
  name: string;
  price: number;
  quantity?: number;
  isMaster: boolean;
  id?: string;
  toDelete?: boolean;
  remainingQuantity?: number;
  discountCodesCsvBase64?: string;
  attribute?: string;
}

export const defaultState: VariantsDefaultState = {
  name: "",
  price: 0,
  quantity: undefined,
  isMaster: false,
  id: undefined,
  remainingQuantity: undefined,
  discountCodesCsvBase64: undefined,
};

export enum PRODUCT_TYPE {
  VOUCHER = "VOUCHER",
  ITEM = "ITEM",
  EVENT = "EVENT",
}

export enum PRODUCT_STATUS {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

export enum SupportedAttributeType {
  Color = "color",
  Size = "size",
  TicketCategory = "ticket_category",
  DescriptionLong = "description_long",
  Discount = "discount",
}

export interface PDPConfig {
  missionControl: MissionControlConfig[];
}

export type MissionControlAttributeDisplayType =
  | "color"
  | "radio"
  | "dropdown"
  | "segmented_control";

export interface MissionControlAttributeConfig {
  attributeName: SupportedAttributeType;
  type: MissionControlAttributeDisplayType;
  sort: boolean;
  orderedList: string[];
}

export interface MissionControlConfig {
  productTypeKey: string;
  // On the OneShop, this is a list of attributes. We only support one attribute for now.
  attribute: MissionControlAttributeConfig;
}

export interface AdminProductConfig {
  label: string;
  value: string;
  options: {
    value: string;
    label: string;
  }[];
}

export enum AttributeTypeToLocalizedString {
  color = "Color",
  size = "Size",
  ticket_category = "Ticket Category",
  description_long = "Description Long",
  discount = "Discount",
}
