import * as SelectPrimitive from "@radix-ui/react-select";
import c from "classnames";
import { Icon } from "components/ui/Icon/Icon";
import Tooltip from "components/ui/Tooltip/Tooltip";
import s from "./SelectField.module.scss";

interface ISelectProps {
  value?: string;
  title?: string;
  name?: string;
  onChange?: (value: string) => void;
  className?: string;
  placeholder?: string;
  showInfo?: string;
  position?: React.ComponentProps<typeof SelectPrimitive.Content>["position"];
  options?: { label: string; value: string }[];
  groupOptions?: {
    label: string;
    value: string;
    options: {
      value: string;
      label: string;
    }[];
  }[];
  disabled?: boolean;
  required?: boolean;
  description?: string;
}

export const SelectField = ({
  value,
  title,
  showInfo,
  options,
  name,
  groupOptions,
  position = "popper",
  onChange,
  className,
  placeholder,
  required,
  description,
}: ISelectProps) => (
  <div className={c(s["field-wrapper"], className)}>
    {title && (
      <div
        className={c(s.label, {
          description,
        })}>
        {title && (
          <label>
            {title}
            {required ? "*" : " "}
          </label>
        )}

        {showInfo && <Tooltip text={showInfo} />}
        {description && <p className={s.description}>{description}</p>}
      </div>
    )}
    <SelectPrimitive.Root name={name} value={value} onValueChange={onChange}>
      <SelectPrimitive.Trigger className={s.trigger}>
        <SelectPrimitive.Value placeholder={placeholder} />
        <SelectPrimitive.Icon />
      </SelectPrimitive.Trigger>

      <SelectPrimitive.Portal>
        <SelectPrimitive.Content className={s.content} position={position}>
          {/* <SelectPrimitive.ScrollUpButton /> */}
          <SelectPrimitive.Viewport>
            {groupOptions &&
              groupOptions.map((group, index) => (
                <SelectPrimitive.Group key={group.label + index}>
                  <SelectPrimitive.Label className={s["item-label"]}>
                    {group.label}
                  </SelectPrimitive.Label>
                  {group.options.map((option, i) => (
                    <>
                      <SelectPrimitive.Item
                        className={s.item}
                        key={`${group.value}|${option.value}`}
                        value={`${group.value}|${option.value}`}>
                        <SelectPrimitive.ItemText>
                          {option.label}
                        </SelectPrimitive.ItemText>
                        <SelectPrimitive.ItemIndicator
                          className={s["item-indicator"]}>
                          <Icon name="check" />
                        </SelectPrimitive.ItemIndicator>
                      </SelectPrimitive.Item>
                      {i === group.options.length - 1 && (
                        <SelectPrimitive.Separator className={s.separator} />
                      )}
                    </>
                  ))}
                </SelectPrimitive.Group>
              ))}
            {options?.map((option, index) => (
              <SelectPrimitive.Item
                className={s.item}
                key={option.value + index}
                value={option.value}>
                <SelectPrimitive.ItemText>
                  {option.label}
                </SelectPrimitive.ItemText>
                <SelectPrimitive.ItemIndicator className={s["item-indicator"]}>
                  <Icon name="check" />
                </SelectPrimitive.ItemIndicator>
              </SelectPrimitive.Item>
            ))}
          </SelectPrimitive.Viewport>
          {/* <SelectPrimitive.ScrollDownButton /> */}
          <SelectPrimitive.Arrow />
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  </div>
);
