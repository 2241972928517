import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import pagePrevIconBlack from "assets/icons/admin/paginate-left-black.svg";
import pagePrevIconGrey from "assets/icons/admin/paginate-left-grey.svg";
import pagePrevIconWhite from "assets/icons/admin/paginate-left-white.svg";
import pagePrevIcon from "assets/icons/admin/paginate-left.svg";
import pageNextIconBlack from "assets/icons/admin/paginate-right-black.svg";
import pageNextIconGrey from "assets/icons/admin/paginate-right-grey.svg";
import pageNextIconWhite from "assets/icons/admin/paginate-right-white.svg";
import pageNextIcon from "assets/icons/admin/paginate-right.svg";
import c from "classnames";
import { themeContext } from "context/themeContext";
import React, { useContext, useEffect, useState } from "react";
import uuid from "react-uuid";
import s from "./Table.module.scss";

interface IDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  expandedComponent?: Function;
  perPage?: number;
  tableClass?: string;
  filterValue?: {
    key: string;
    value: any;
  };
}

export default function DataTable<TData, TValue>(
  props: IDataTableProps<TData, TValue>
) {
  // Track currently active page
  const [activePage, setActivePage] = useState(0);
  const { theme } = useContext(themeContext);
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getPageCount,
    getCanPreviousPage,
    getState,
    getCanNextPage,
    previousPage,
    nextPage,
    getHeaderGroups,
    getRowModel,
    getAllColumns,
    setGlobalFilter,
    setPageIndex,
    toggleAllRowsExpanded,
  } = useReactTable({
    columns: props.columns,
    data: props.data,
    initialState: {
      columnVisibility: {
        special_price: false,
        id: false,
        images: false,
        started: false,
        image: false,
        username: false,
        token_id: false,
      },
      pagination: {
        pageSize: props.perPage || 10,
        pageIndex: 0,
      },
    },
    state: {
      pagination: {
        pageSize: props.perPage || 10,
        pageIndex: activePage,
      },
    },
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const numberOfItems = props.data?.length;
  const { pageIndex, pageSize } = getState().pagination;
  const isPaginated = numberOfItems > pageSize;
  let pageInfo;
  if (isPaginated) {
    pageInfo = (
      <>
        Showing{" "}
        <b>
          {pageIndex * pageSize}-{(pageSize ?? 0) * (pageIndex + 1)}
        </b>{" "}
        of <b>{numberOfItems}</b> items.
      </>
    );
  } else if (numberOfItems !== 0) {
    pageInfo = (
      <>
        Showing all <b>{numberOfItems}</b> items
      </>
    );
  } else {
    pageInfo = "No items to show";
  }

  useEffect(() => {
    setPageIndex(activePage);
  }, [props.data, activePage, setPageIndex]);

  useEffect(() => {
    if (props.filterValue?.value) {
      setGlobalFilter(props.filterValue.value);
      setActivePage(0);
    }
  }, [getAllColumns, props.filterValue, setGlobalFilter]);

  function getPages() {
    const pageCount = getPageCount();
    const canPreviousPage = getCanPreviousPage();

    const pages: JSX.Element[] = [];

    if (pageCount < 3) {
      for (let i = 0; i < pageCount; i++) {
        pages.push(
          <div
            key={uuid()}
            className={c(
              s["page-box"],
              activePage === i ? s.active : s.inactive
            )}
            onClick={() => setActivePage(i)}>
            {i + 1}
          </div>
        );
      }
    } else {
      for (
        let i = canPreviousPage ? pageIndex - 1 : 0;
        i < pageIndex + 2 && i < pageCount - 1;
        i++
      ) {
        pages.push(
          <div
            key={uuid()}
            className={c(
              s["page-box"],
              activePage === i ? s.active : s.inactive
            )}
            onClick={() => setActivePage(i)}>
            {i + 1}
          </div>
        );
      }
      if (pageIndex < pageCount - 2) {
        pages.push(
          <div className={s.inactive} key={uuid()}>
            ...
          </div>
        );
      }
      pages.push(
        <div
          key={uuid()}
          className={c(
            s["page-box"],
            activePage === pageCount - 1 ? s.active : s.inactive
          )}
          onClick={() => setActivePage(pageCount - 1)}>
          {pageCount}
        </div>
      );
    }
    return pages;
  }

  function getPrevPage() {
    const canPreviousPage = getCanPreviousPage();
    if (!canPreviousPage) return;
    setActivePage(activePage - 1);
    previousPage();
  }

  function getNextPage() {
    const canNextPage = getCanNextPage();
    if (!canNextPage) return;
    setActivePage(activePage + 1);
    nextPage();
  }

  /*
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div
      className={c(s["table-style"], props.tableClass && s[props.tableClass])}>
      <table>
        <thead>
          {getHeaderGroups().map((headerGroup, index) => (
            <tr key={index}>
              {headerGroup.headers.map((header, index) => {
                const isExpander = header.id === "expander";
                let className = "";
                if (header.column.getIsSorted()) {
                  className =
                    header.column.getIsSorted() === "desc"
                      ? "sort-desc"
                      : "sort-asc";
                }

                return (
                  <th
                    key={index}
                    onClick={header.column.getToggleSortingHandler()}
                    className={!isExpander ? className : s["header-expander"]}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {getRowModel().rows.map((row) => (
            <React.Fragment key={row.id}>
              <tr
                className={`${row.getIsExpanded() && s.expanded}`}
                onClick={() => {
                  if (props.expandedComponent)
                    row.toggleExpanded(!row.getIsExpanded());
                }}>
                {row.getVisibleCells().map((cell, index) => (
                  <td key={index}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
              {/* EXPANDED COLUMNS */}
              {row.getIsExpanded() && props.expandedComponent && (
                <tr className={s.expanded}>
                  <td colSpan={row.getVisibleCells().length}>
                    {props.expandedComponent({ row })}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {/* PAGINATION */}
      {!getCanNextPage() && !getCanPreviousPage() ? (
        ""
      ) : (
        <div className={s["pagination-wrapper"]}>
          <div onClick={getPrevPage} className={s.arrow}>
            <img
              src={
                !getCanPreviousPage()
                  ? theme === "dark"
                    ? pagePrevIconGrey
                    : pagePrevIcon
                  : theme === "dark"
                    ? pagePrevIconWhite
                    : pagePrevIconBlack
              }
            />
          </div>

          {getPages()}

          <div onClick={getNextPage} className={s["arrow"]}>
            <img
              src={
                !getCanNextPage()
                  ? theme === "dark"
                    ? pageNextIconGrey
                    : pageNextIcon
                  : theme === "dark"
                    ? pageNextIconWhite
                    : pageNextIconBlack
              }
            />
          </div>
        </div>
      )}
      <p className={s["items-length-text"]}>{pageInfo}</p>
    </div>
  );
}
