import activeIcon from "assets/icons/admin/active-status.svg";
import addIcon from "assets/icons/admin/add.svg";
import analyticsIcon from "assets/icons/admin/analytics.svg";
import arrowLeftIcon from "assets/icons/admin/arrow-back.svg";
import badgeIcon from "assets/icons/admin/badge.svg";
import benefitIcon from "assets/icons/admin/benefit.svg";
import cancelIcon from "assets/icons/admin/cancel.svg";
import challengeIcon from "assets/icons/admin/challenge.svg";
import checkIcon from "assets/icons/admin/check.svg";
import contentfulIcon from "assets/icons/admin/contentful.svg";
import crestIcon from "assets/icons/admin/crest.svg";
import porscheTokenIcon from "assets/icons/admin/crest.svg";
import deleteIcon from "assets/icons/admin/delete-trash.svg";
import editIcon from "assets/icons/admin/edit.svg";
import expandIcon from "assets/icons/admin/expand-row.svg";
import expiredIcon from "assets/icons/admin/expired-status.svg";
import ordersIcon from "assets/icons/admin/orders.svg";
import pausedIcon from "assets/icons/admin/paused-status.svg";
import pencilIcon from "assets/icons/admin/pencil-draft.svg";
import productIcon from "assets/icons/admin/product.svg";
import qrCodeIcon from "assets/icons/admin/qr-code.svg";
import upcomingIcon from "assets/icons/admin/upcoming-status.svg";
import userIcon from "assets/icons/admin/user.svg";
import successIcon from "assets/icons/check-icon-green.svg";
import downloadIcon from "assets/icons/download.svg";
import c from "classnames";
import { Theme, themeContext } from "context/themeContext";
import React, { useContext } from "react";
import s from "./Icon.module.scss";

export type IconName =
  | "active"
  | "add"
  | "analytics"
  | "arrowLeft"
  | "badge"
  | "benefit"
  | "cancel"
  | "challenge"
  | "check"
  | "contentful"
  | "crest"
  | "delete"
  | "edit"
  | "expand"
  | "expired"
  | "paused"
  | "pencil"
  | "porscheToken"
  | "product"
  | "qr"
  | "upcoming"
  | "user"
  | "success"
  | "orders"
  | "download";

export const icons: Record<string, string> = {
  active: activeIcon,
  add: addIcon,
  analytics: analyticsIcon,
  arrowLeft: arrowLeftIcon,
  badge: badgeIcon,
  benefit: benefitIcon,
  cancel: cancelIcon,
  challenge: challengeIcon,
  check: checkIcon,
  contentful: contentfulIcon,
  crest: crestIcon,
  delete: deleteIcon,
  edit: editIcon,
  expand: expandIcon,
  expired: expiredIcon,
  paused: pausedIcon,
  pencil: pencilIcon,
  porscheToken: porscheTokenIcon,
  product: productIcon,
  qr: qrCodeIcon,
  upcoming: upcomingIcon,
  user: userIcon,
  success: successIcon,
  orders: ordersIcon,
  download: downloadIcon,
};

interface IIconProps {
  name: IconName;
  className?: string;
  style?: React.CSSProperties;
  theme?: Theme;
  inverted?: boolean;
}

export function Icon({ name, className, theme, inverted, style }: IIconProps) {
  const { theme: currentTheme } = useContext(themeContext);

  return (
    <img
      style={style}
      className={c(s.icon, inverted && s.inverted, className)}
      src={icons[name]}
      data-theme={theme ?? currentTheme}
    />
  );
}
