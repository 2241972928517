import { ColumnDef } from "@tanstack/react-table";
import { Icon } from "components/ui/Icon/Icon";
import { UserDetailsDto } from "types/schemas";
import { formatDate } from "utils/formatDate";
import { z } from "zod";

export function UserCols(): ColumnDef<z.infer<typeof UserDetailsDto>>[] {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    timeZoneName: "short",
  };

  return [
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>First Name ↑</div>;
          } else {
            return <div>First Name ↓</div>;
          }
        }
        return "First Name";
      },
      accessorKey: "firstName",
      cell: ({ row }) => row.getValue("firstName"),
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Last Name ↑</div>;
          } else {
            return <div>Last Name ↓</div>;
          }
        }
        return "Last Name";
      },
      accessorKey: "lastName",
      cell: ({ row }) => row.getValue("lastName"),
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>Email ↑</div>;
          } else {
            return <div>Email ↓</div>;
          }
        }
        return "Email";
      },
      accessorKey: "email",
      cell: ({ row }) => {
        const value = row.getValue("email") as string;
        return <a href={`mailto:${value}`}>{value}</a>;
      },
    },
    {
      header: (table) => {
        const col = table.column;

        if (col.getIsSorted()) {
          if (col.getIsSorted() === "desc") {
            return <div>ToS Acceptance Date ↑</div>;
          } else {
            return <div>ToS Acceptance Date ↓</div>;
          }
        }
        return "ToS Acceptance Date";
      },
      accessorKey: "tosAcceptanceDate",
      cell: ({ row }) => {
        const value = row.getValue("tosAcceptanceDate") as number | undefined;
        if (value) {
          const date = new Date(value);

          const dateTimeString = formatDate(date);

          return (
            <p style={{ display: "inline-flex" }}>
              <Icon
                name="check"
                style={{
                  height: "1em",
                  marginInlineEnd: "0.3em",
                  marginBlock: 2.75,
                }}
              />
              {dateTimeString}
            </p>
          );
        }
        return (
          <p>
            <i>ToS not accepted</i>
          </p>
        );
      },
    },
    {
      // We don't need the column(it's hidden) - this way we attach
      // the id field from fetched data to row
      header: () => null,
      id: "id",
      accessorKey: "id",
    },
  ];
}
